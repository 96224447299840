import { connect } from "react-redux";
import { actions as userActions } from "../../../../../redux/modules/usuarios/usuariosAdmin";
import FormUsers from "./FormUsers";

const ms2p = (state) => {
    return {
        ...state.usuariosAdmin,
        me: state.login.me,
    };
};

const md2p = {
    ...userActions,
};

export default connect(ms2p, md2p)(FormUsers);
