import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField, renderFilePicker } from "../../../Utils/renderField";
import { validate, validators } from "validate-redux-form";

class ExcelForm extends Component {
    render() {
        const { handleSubmit, create , cerrarModal} = this.props;
        return (
            <React.Fragment>
                <form
                    name="excelForm"
                    className="form-validate mb-lg "
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex justify-content-center flex-column flex-md-row pb-2">
                        <div className="d-flex flex-row align-items-center">
                            <label
                                className="font-weight-bold mr-5"
                                htmlFor="fileExcel"
                            >
                                Haz click en el botón o arrastra el archivo para cargar
                            </label>
                            <Field
                                name="fileExcel"
                                label="fileExcel"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                excel={true}
                                component={renderFilePicker}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                                type="button"
                                onClick={cerrarModal}
                            >
                                Cancelar
                            </button>
                        </div>
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="submit"
                                className="btn btn-dark w-100 font-weight-bold mb-4 mb-md-2"
                            >
                                {" "}
                                {create ? "Cargar Excel" : "Actualizar"}
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: "excelForm", // Identificador de formulario
    //validate: validate
    validate: (data) => {
        return validate(data, {
            fileExcel: validators.exists()("Este campo es requerido"),
        });
    },
})(ExcelForm);
