import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
// componentes
import TablaUsuarios from "./TablaUsuarios";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Header from "../../../Utils/Grid/Header";
import Asignacion from "../AsignarUsuarios";

class Usuarios extends Component {
    static propTypes = {
        users: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
        user: {},
    };

    state = {
        modal_open: false,
    };

    asignar = (user) => {
        this.setState({ user });
        this.toggleModal();
    };

    toggleModal = () => {
        if (this.state.modal_open) {
            this.props.refreshData()
        }
        this.setState({ modal_open: !this.state.modal_open });
    };

    componentDidMount() {
        const { listarUsuarios, page, roles, me } = this.props;
        // extrae la instituion a la que pertenece el usuario logueado
        if (me.profile)
            listarUsuarios(page, roles.TECHNICAL, me.profile.institution);
    }

    render() {
        const {
            users,
            page,
            loader,
            listar: onPageChange,
            onSortChange,
            deleteUser,
        } = this.props;
        
        return (
            <React.Fragment>
                <div className="mt-4">
                    <Header
                        to="/tecnicos/nuevo"
                        textBtn="Nuevo"
                        {...this.props}
                    />

                    <TablaUsuarios
                        deleteUser={deleteUser}
                        data={users}
                        loader={loader}
                        url="tecnicos"
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                        asignar={this.asignar}
                    />

                    <Modal
                        isOpen={this.state.modal_open}
                        className="mx-lg-auto mx-md-3 mx-2"
                    >
                        <ModalHeader className="card ">
                            Asignación de técnico
                        </ModalHeader>
                        <ModalBody className="car mt-3">
                            <Asignacion
                                onClose={this.toggleModal}
                                user={this.state.user}
                            />
                        </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: "search_usersAdmin", // a unique identifier for this form
})(Usuarios);
