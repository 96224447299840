import React, { Component, Fragment } from "react";
import Grid from "Utils/Grid";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";
import Header from "../../../Utils/Grid/Header";
import { Field, reduxForm } from "redux-form";
import {
    SelectField,
    renderDatePicker,
} from "../../../Utils/renderField/renderField";

import "./style.css";

const isExpandableRow = () => {
    return true;
};

const Filterbar = (props) => {
    return (
        <div className="row w-100 mx-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Descargar reporte:</label>
                <button className="btn btn-success w-100" onClick={() => {
                    props.descarga_excel()
                }}>
                    Descargar Excel
                </button>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Actividad:</label>
                <Field
                    name="actividad"
                    placeholder="Actividad"
                    options={props.filters.actividades}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => props.onPress(value, "ACTIVIDAD")}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Institución:</label>
                <Field
                    name="institucion"
                    placeholder="Institución"
                    options={props.filters.instituciones}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => props.onPress(value, "INSTITUCION")}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Técnico:</label>
                <Field
                    name="tecnico"
                    placeholder="Técnico"
                    options={props.filters.tecnicos}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => props.onPress(value, "TECNICO")}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Desde:</label>
                <Field
                    onChange={(e, value) => props.onPress(value, "DATESTART")}
                    name="fecha_inicio"
                    placeholder="desde"
                    component={renderDatePicker}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Hasta:</label>
                <Field
                    onChange={(e, value) => props.onPress(value, "DATEEND")}
                    name="fecha_fin"
                    placeholder="hasta"
                    component={renderDatePicker}
                />
            </div>
        </div>
    );
};

class Reporte extends Component {
    componentDidMount() {
        this.props.onDeleteFilters();
    }

    expandComponent = (row) => {
        let data = row && row.insumos ? row.insumos : [];
        let multiplo = _.cloneDeep(data);
        return (
            <div className="insidetable py-3 px-5">
                <BootstrapTable hover striped data={multiplo}>
                    <TableHeaderColumn
                        isKey={true}
                        dataField="id"
                        width="25%"
                        dataAlign="center"
                        dataSort
                    >
                        No.
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="name" width="75%" dataSort>
                        Insumo
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    };

    render() {
        const {
            loader,
            listar: onPageChange,
            onSortChange,
            page,
            data,
            filters,
            onApplyFilters,
            descarga_excel
        } = this.props;        
        return (
            <Fragment>
                <div className="mt-4">
                    <Header
                        filterbar={
                            <Filterbar
                                filters={filters}
                                onPress={onApplyFilters}
                                descarga_excel={descarga_excel}
                            />
                        }
                    />
                </div>
                <div className="card">
                    <Grid
                        className="table-striped2"
                        data={data}
                        expandableRow={isExpandableRow}
                        expandComponent={this.expandComponent}
                        loading={loader}
                        onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={standardActions({
                                ver: "/reporte/actividades",
                                expand: true,
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="actividad" dataSort>
                            actividad
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="username" dataSort>
                            Técnico
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="institution" dataSort>
                            Institución
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </Fragment>
        );
    }
}

// export default Reporte;

export default reduxForm({
    form: "form-listado-actividades",
})(Reporte);
