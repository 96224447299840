import React, { Component } from "react";
import { Field } from "redux-form";
import { SelectField,renderDatePicker,AsyncSelectField } from "Utils/renderField/renderField";
import { api } from "../../../../../utility/api";
import {
    DEPARTAMENTOS_SELECT,    
  } from "../../../Utils/constant";

const getProjects = () => {
    const projects = [{value:-1,label:'Ninguno'}];
    return api
        .get("/projects/raw")
        .then((response) => {
            if (response) {
                response.forEach((proyecto) => {
                    projects.push({
                        value: proyecto.id,
                        label: proyecto.name,
                    });
                });
                return projects;
            }
        })
        .catch((error) => {
            return [];
        });
};
const Proyectos = ({onChange }) => {    
    return (
        <Field
            name="project"
            placeholder="Proyecto"
            component={AsyncSelectField}
            loadOptions={getProjects}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Departamento = ({ onChange }) => {   
    return (
        <Field
            name="departament_insumo"
            placeholder="Departamento"
            options={DEPARTAMENTOS_SELECT}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};

const Municipio = ({ municipios, onChange }) => {    
    return (
        <Field
            name="municipality_insumo"
            placeholder="Municipio"
            options={municipios}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Comunidad = ({comunidades_filtradas, onChange }) => {
    return (
        <Field
            name="community_insumo"
            placeholder="Comunidad"
            options={comunidades_filtradas}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Genero = ({onChange }) => {
    const generos=[
        {value:'00', label:'Ninguno'},
        {value:'10', label:'Mujer'},
        {value:'20', label:'Hombre'},]
    return (
        <Field
            name="gender_insumo"
            placeholder="Genero:"
            options={generos}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Institucion = ({institucion, onChange }) => {    
    return (
        <Field
            name="institutions_insumo"
            placeholder="Institución"
            options={institucion}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Desde = ({setDatestart}) => {    
    return (
        <Field
            onChange={(e, value) => setDatestart(value)}
            name="date_start_insumo"
            placeholder="Desde"
            component={renderDatePicker}
        />
    );
};
const Hasta = ({setDateEnd }) => {   
    return (
        <Field
            onChange={(e, value) => setDateEnd(value)}
            name="date_end_insumo"
            placeholder="hasta"
            component={renderDatePicker}
        />
    );
};
class FiltrosInsumosProyectos extends Component {
    _departamento = (value) => {   
        //true  para que aparezaca la opcion de ninguno en los selects
        this.props.filtrarMunicipio(value, true);
        //30 pasa saber que se quiere filtrar los insumos
        this.props.filtro_departamento(value,50);       
    };
    _municipio = (value) => {   
        this.props.filtrarComunidades(value, true);
        this.props.filtro_municipio(value,50);
    };
    _comunidad = (value) => {
        this.props.filtro_comunidad(value,50);
    };
    _genero = (value) => {
        this.props.filtro_genero(value,50);
    };
    _institucion = (value) => {
        this.props.filtro_institucion(value,50);
    };
    _proyecto = (value) => {
        this.props.filtro_proyecto(value.value,50);
        //console.log("Proyecto----", value.value)
    };
    _desde = (value) => {
        this.props.filtro_desde(value.v,50);
    };
    _hasta = (value) => {
        this.props.filtro_hasta(value,50);
    };
    render(){
        const {
            municipios,            
            comunidades_filtradas,
            institucion, 
        }= this.props;
        return(
            <div className=" row pl-0 pr-0">
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 "  >
                    <label className="mb-0 pt-2">Departamento:</label>
                    <Departamento  onChange={this._departamento} />
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 ">
                    <label className="mb-0 pt-2">Municipio:</label>
                    <Municipio municipios={municipios} onChange={this._municipio} />
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 " >
                    <label className="mb-0 pt-2">Comunidad:</label>
                    <Comunidad  comunidades_filtradas={comunidades_filtradas} onChange={this._comunidad} />
                </div>              
                
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 ">
                    <label className="mb-0 pt-2">Sexo:</label>
                    <Genero onChange={this._genero} />
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 " >
                    <label className="mb-0 pt-2">Organización:</label>
                    <Institucion institucion={institucion} onChange={this._institucion} />
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 " >
                    <label className="mb-0 pt-2">Proyecto:</label>
                    <Proyectos onChange={this._proyecto} />
                </div>
                <div className="row col-12 pt-2 ">
                    <div className="col-12">
                        <h6 className="mb-0">Por fechas</h6>
                        <div className="dropdown-divider m-0" />
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 ">
                    <label className="mb-0 pt-2">Desde:</label>
                    <Desde  setDatestart ={this._desde} />
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-xl-4 col-md-6 " >
                    <label className="mb-0 pt-2">Hasta:</label>
                    <Hasta  setDateEnd={this._hasta} />
                </div>
                    
            </div>
        );
    }
}
export default FiltrosInsumosProyectos
