import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField } from "../../../Utils/renderField";

class NewInstitutionForm extends Component {
    render() {
        const { handleSubmit, create, disabled } = this.props;
        return (
            <React.Fragment>
                <form
                    name="newInstitution"
                    className="form-validate mb-lg "
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                            <div className="form-group has-feedback">
                                <label
                                    htmlFor="name"
                                    className="font-weight-bold"
                                >
                                    Institución:
                                </label>
                                <Field
                                    disabled={disabled}
                                    maxLength={75}
                                    name="name"
                                    label="name"
                                    component={renderField}
                                    className="form-control"
                                    placeholder="Nombre de la institución"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <Link
                                className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                                to="/institucion"
                            >
                                Cancelar
                            </Link>
                        </div>
                        {!disabled && (
                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2"
                                >
                                    {" "}
                                    {create ? "Guardar" : "Actualizar"}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = "Este campo es requerido";
    }
    if (values.name && values.name.length > 75) {
        errors.name = "la institucion debe tener menos de 75 letras";
    }

    return errors;
};

export default reduxForm({
    form: "newInstitution", // Identificador de formulario
    validate,
})(NewInstitutionForm);
