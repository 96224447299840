import React, { Component, Fragment } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
class DetalleBeneficiario extends Component {
    state = {
        disabled: false,
    };
    componentDidMount() {
        const {
            getBeneficiarioRepetido,
            beneficiario,
            /* nuevos,
            actualizados, */
        } = this.props;
        const data = { nombre: beneficiario.name, dpi: beneficiario.dpi };
        getBeneficiarioRepetido(data);
        //si el id del beneficiario ya esta agregado a la lista desabilitamos las acciones
       /*  nuevos.forEach((value, index) => {            
            if (value.id == beneficiario.id) {
                this.setState({ disabled: true });
            }
        });
        actualizados.forEach((value, index) => {            
            if (value.id == beneficiario.id) {
                this.setState({ disabled: true });
            }
        }); */
    }

    render() {
        const {
            onClose,
            beneficiario,
            item,
            loader,
            generarNuevo,
            sobreEscribir,
        } = this.props;

        return (
            <React.Fragment>
                <LoadMask loading={loader} dark blur>
                    <div className="p-4">
                        <div>
                            <h5>Actualemente</h5>
                            <div className="dropdown-divider"></div>
                            <div className="row justify-content-md-around mx-md-0 ">
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Institución:
                                    </label>
                                    <label className="d-block">
                                        {item.institution}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Nombre:
                                    </label>
                                    <label className="d-block">
                                        {item.name}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        DPI:
                                    </label>
                                    <label className="d-block">
                                        {item.dpi}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Fecha:
                                    </label>
                                    <label className="d-block">
                                        {item.birth_date}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Nuevo</h5>
                            <div className="dropdown-divider"></div>
                            {
                                beneficiario && beneficiario.name != undefined ?
                                    <div className="row justify-content-md-around mx-md-0 ">
                                    <div className="p-1">
                                        <label className="font-weight-bold d-block">
                                            Institución:
                                        </label>
                                        <label className="d-block">
                                            {beneficiario.institution.name}
                                        </label>
                                    </div>
                                    <div className="p-1">
                                        <label className="font-weight-bold d-block">
                                            Nombre:
                                        </label>
                                        <label className="d-block">
                                            {beneficiario.name}
                                        </label>
                                    </div>
                                    <div className="p-1">
                                        <label className="font-weight-bold d-block">
                                            DPI:
                                        </label>
                                        <label className="d-block">
                                            {beneficiario.dpi}
                                        </label>
                                    </div>
                                    <div className="p-1">
                                        <label className="font-weight-bold d-block">
                                            Fecha:
                                        </label>
                                        <label className="d-block">
                                            {beneficiario.birth_date}
                                        </label>
                                    </div>
                                </div>
                                :
                                <div></div>
                            }
                           
                            
                        </div>
                    </div>
                    <div className="row justify-content-md-center mx-3 mx-md-0 ">
                        <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                            <button
                                className="btn btn-outline-black w-100"
                                onClick={onClose}
                            >
                                Cancelar
                            </button>
                        </div>

                        <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                            <button
                                disabled={this.state.disabled}
                                className="btn btn-outline-primary w-100"
                                onClick={() => {
                                    //this.setState({ disabled:true})
                                    generarNuevo(beneficiario.id);
                                    //onClose();                                    
                                }}
                            >
                                Generar nuevo
                            </button>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                            <button
                                disabled={this.state.disabled}
                                className="btn btn-outline-primary w-100"
                                onClick={() => {
                                    sobreEscribir(beneficiario.id, item.id);
                                    //onClose();
                                }}
                            >
                                Sobre escribir
                            </button>
                        </div>
                    </div>
                </LoadMask>
            </React.Fragment>
        );
    }
}
export default DetalleBeneficiario;
