import React, { Component, Fragment } from "react";
import TablaAsignacion from "./TablaAsignacion";
import Header from "../../../Utils/Grid/Header";
import { Field, reduxForm } from "redux-form";
import {
    SelectField,
    renderDatePicker,
} from "../../../Utils/renderField/renderField";

const Filterbar = (props) => {
    return (
        <div className="row w-100 mx-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Departamento:</label>
                <Field
                    name="departamento"
                    placeholder="Departamento"
                    options={props.getDepartaments()}
                    component={SelectField}
                    className="form-control"
                    onChange={() => props.setDepartamento()}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Municipio:</label>
                <Field
                    name="municipio"
                    placeholder="Municipio"
                    options={props.filtrarMunicipio()}
                    component={SelectField}
                    className="form-control"
                    onChange={() => props.setMunicipio()}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Comunidad:</label>
                <Field
                    name="comunidad"
                    placeholder="Comunidad"
                    options={props.filtrarComunidades()}
                    component={SelectField}
                    className="form-control"
                    onChange={() => props.setComunidad()}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
            <label className="font-weight-bold mb-0">Tipo beneficiario:</label>
                <Field
                    name="tipo"
                    placeholder="Tipo beneficiario"
                    options={[
                        {value: '00', label: 'ninguno'},
                        {value: '10', label: 'mujer'},
                        {value: '20', label: 'hombre'},
                        {value: '30', label: 'niño'},
                        {value: '40', label: 'Joven beneficiario'},
                        {value: '50', label: 'Joven solidario'},
                    ]}
                    component={SelectField}
                    className="form-control"
                    onChange={() => props.setTypeBenfi()}
                />
            </div>
        </div>
    );
};

class Asignacion extends Component {
    state = {
        assigned: [],
        unassigned: [],
        assigned_all: false,
        assigned_num: 0,
    };

    componentWillMount() {
        const { listar_initial, user, data } = this.props;
        this.setState({ assigned_all: user.profile.have_assigned_all });
        listar_initial(user.id, user.profile.institution);

        // saca el numero de personas asignadas
        setTimeout(() => {
            const results = [...this.props.data.results];
            results.forEach((item) => {
                if (item.meta.is_assigned) {
                    this.setState({
                        assigned_num: this.state.assigned_num + 1,
                    });
                }
            });
        }, 100);
    }

    isAssignedAll = (ass_count, unass_count) => {
        setTimeout(() => {
            const results = [...this.props.data.results];
            let { assigned_num } = this.state;

            // suma los nuevos asignados y resta los nuevos desasignados para que cuadre con el numero de usuarios totales
            assigned_num += ass_count;
            assigned_num -= unass_count;

            if (assigned_num == results.length)
                this.setState({ assigned_all: true });
            else this.setState({ assigned_all: false });
            this.setState({ assigned_num });
        }, 10);
    };

    _asignar = (id) => {
        /**
         * busca el id entre el array de asignados
         * si no esta, lo agrega, y lo quita del de desasignados
         */
        let assigned = [...this.state.assigned];
        let unassigned = [...this.state.unassigned];

        // verifica y quita el usuarios de la lista de desasignados
        let person_index = unassigned
            .map(function (e) {
                return e.id;
            })
            .indexOf(id);
        if (person_index >= 0) unassigned.splice(person_index, 1);

        // agregamos a la persona a la lista de asignados
        assigned.push({ id });
        this.setState({ assigned, unassigned });
        this.isAssignedAll(1, 0);
    };

    _desAsignar = (id) => {
        /**
         * busca el id entre el array de asignados
         * si esta, lo quita! y lo agrega al de desasignados
         */
        let assigned = [...this.state.assigned];
        let unassigned = [...this.state.unassigned];

        // verifica y quita el usuarios de la lista de asignados
        let person_index = assigned
            .map(function (e) {
                return e.id;
            })
            .indexOf(id);
        if (person_index >= 0) assigned.splice(person_index, 1);

        // agregamos a la persona a la lista de desasignados
        unassigned.push({ id });

        this.setState({ assigned, unassigned });
        this.isAssignedAll(0, 1);
    };

    _AsignarTodos = (value) => {
        const { user, assign_all } = this.props;
        assign_all({
            tecnico: user.id,
            institution: user.profile.institution,
            value,
        });

        this.setState({ assigned_all: value });
    };

    onSave = () => {
        const { user, saveAssignments, onClose } = this.props;
        const { assigned, unassigned, assigned_all } = this.state;

        saveAssignments({
            assigned,
            unassigned,
            tecnico: user.id,
            assigned_all,
            institution: user.profile.institution,
        });
        onClose();
    };

    onPageChange = (e) => {
        const { listar: onPageChange, data } = this.props;
        onPageChange(e);
    };

    render() {
        const { onClose, user, data, loader, page, onSortChange } = this.props;

        return (
            <Fragment>
                <Header
                    {...this.props}
                    header={`Técnico / ${user.first_name}`}
                    filterbar={
                        <Filterbar
                            {...this.props}
                        />
                    }
                />

                <TablaAsignacion
                    data={data}
                    assigned_all={this.state.assigned_all}
                    loading={loader}
                    onPageChange={this.onPageChange}
                    onSortChange={onSortChange}
                    page={page}
                    onAsignar={this._asignar}
                    onDesAsignar={this._desAsignar}
                    onAssignAll={this._AsignarTodos}
                />

                <div className="row justify-content-md-center mx-3 mx-md-0">
                    <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                        <button
                            className="btn btn-light w-100"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                    </div>

                    <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                        <button
                            className="btn btn-primary w-100"
                            onClick={this.onSave}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default reduxForm({
    form: "asignacionTecnico",
})(Asignacion);
