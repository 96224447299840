import React, { Component, Fragment } from "react";
import { Field, FieldArray } from "redux-form";
import { DEPARTAMENTOS_SELECT, ETNIAS_SELECT, GENEROS} from "../../../Utils/constant";
import FormNino from "./FormNino";
import {
    renderField,
    renderDatePicker,
    renderNumber,
    renderFieldRadio,
    renderFilePicker,
    renderFieldCheck,
    SelectField,
} from "Utils/renderField/renderField";

const renderNinos = ({ fields, disabled, eliminarhijo }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 mb-4">
                    <h6>2-Niños</h6>
                    <div className="dropdown-divider" />
                </div>
            </div>
            {fields.map((child, index) => {
                const currentChild = fields.get(index);
                return (
                    <div className="mb-1" key={index}>
                        <FormNino disabled={disabled} child={child} />
                        {fields.length > 1 && (
                            <button
                                type="button"
                                className="btn btn-light font-weight-bolder btn-block"
                                onClick={() => {
                                    fields.remove(index);
                                    eliminarhijo(currentChild.id);
                                }}
                                disabled={disabled}
                            >
                                Eliminar niño
                            </button>
                        )}
                    </div>
                );
            })}
            {!disabled && (
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-outline-info font-weight-bolder btn-block "
                        onClick={() => {
                            fields.push({});
                        }}
                    >
                        Agregar niño
                    </button>
                </div>
            )}
        </Fragment>
    );
};

class FormMujerHombre extends Component {
   
    change = (value, label) => {
        this.props.filtrarMunicipio(label);
    };
    
    render() {
        let date = new Date();
        const { disabled, eliminarhijo, municipios, apadrinado,formSelect} = this.props;
        apadrinado(false);
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-row align-items-center">
                            <label
                                className="font-weight-bold mr-5"
                                htmlFor="photo"
                            >
                                Foto
                            </label>
                            <Field
                                disabled={disabled}
                                name="photo"
                                accept="image/*"
                                component={renderFilePicker}
                            />
                        </div>
                        <div className="form-group has-feedback mx-3 flex-1"></div>
                    </div>
                    <div className="col-md-6 col-12 center">
                        <label className="font-weight-bold" htmlFor="has_dpi">
                            ¿Tiene DPI?{" "}
                        </label>
                        <Field
                            disabled={disabled}
                            name="has_dpi"
                            checked={this.props.has_dpi}
                            component={renderFieldCheck}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="name">
                            Nombre{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="name"
                            placeholder="Nombre"
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="birth_date"
                        >
                            fecha nacimiento{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            name="birth_date"
                            disabled={disabled}
                            maxDate={date}
                            component={renderDatePicker}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="age">
                            Edad
                        </label>
                        <Field
                            disabled={disabled}
                            name="age"
                            placeholder="Edad"
                            component={renderNumber}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="phone">
                            Teléfono
                        </label>
                        <Field
                            disabled={disabled}
                            name="phone"
                            placeholder="Teléfono"
                            component={renderNumber}
                            className="form-control"
                            numberFormat="(502) #### - ####"
                        />
                    </div>
                </div>
                <div className="row">                   
                    {this.props.has_dpi && 
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="DPI">
                            DPI{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="dpi"
                            placeholder="DPI"
                            component={renderNumber}
                            className="form-control"
                            numberFormat="#### - ##### - ####"
                        />
                    </div>
                    }
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.departament"
                        >
                            Sexo:{" "}
                            <span className="text-primary font-weight-bold">*</span>
                        </label>
                        
                            <Field
                            disabled={formSelect&& formSelect<=20 ? true : disabled}
                            name="gender"
                            placeholder="Sexo"
                            /* defaultValue={{value: genero_value, label: genero_label}}  */
                            options={GENEROS}
                            component={SelectField}
                            className="form-control"
                        />           
                    </div>
                    

                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="etnia">
                            Etnia:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="etnia"
                            placeholder="Etnia del beneficiario"
                            options={ETNIAS_SELECT}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.departament"
                        >
                            Departamento:{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="departament"
                            placeholder="Departamento"
                            options={DEPARTAMENTOS_SELECT}
                            component={SelectField}
                            className="form-control"
                            onChange={this.change}
                        />
                    </div>
                    {/* direccion */}
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.municipaplity"
                        >
                            Municipio:{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="municipality"
                            placeholder="Municipio"
                            options={municipios}
                            component={SelectField}
                            className="form-control"
                            onChange={(v, l) => this.props.filtrarComunidades(l)}
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.comunity"
                        >
                            Comunidad o aldea:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="community"
                            placeholder="Comunidad"
                            options={this.props.comunidades}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                </div>
                {/* conyugue */}
                <div className="row">
                    <div className="col-12 mb-4">
                        <h6>1-Datos conyugue</h6>
                        <div className="dropdown-divider" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="spouse.name"
                        >
                            Nombre
                        </label>
                        <Field
                            disabled={disabled}
                            name="spouse.name"
                            placeholder="nombre del conyugue"
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="spouse.age"
                        >
                            Edad
                        </label>
                        <Field
                            disabled={disabled}
                            name="spouse.age"
                            placeholder="Edad del conyugue"
                            component={renderNumber}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="spouse.disability"
                        >
                            Discapacidad
                        </label>
                        <fieldset className="flex d-flex">
                            <Field
                                disabled={disabled}
                                type="radio"
                                name="spouse.disability"
                                label="Si"
                                value="si"
                                component={renderFieldRadio}
                                /* on_click={e=>{
                                    this.props.change("spouse.disability", null)
                                }} */
                            />
                            <div className="px-2" />
                            <Field
                                disabled={disabled}
                                type="radio"
                                name="spouse.disability"
                                label="No"
                                value="no"
                                component={renderFieldRadio}
                                /* on_click={e=>{
                                    this.props.change("spouse.disability", null)
                                }} */
                            />
                        </fieldset>
                    </div>
                </div>
                {/* hijos */}
                <div className="flex-1">
                    <FieldArray
                        name="hijos"
                        eliminarhijo={eliminarhijo}
                        component={renderNinos}
                        disabled={disabled}
                    />
                </div>
            </Fragment>
        );
    }
}

export default FormMujerHombre;
