import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/projects';
import ListProjects from  './ProjectsList';

const ms2p = (state)  => {
    return {
        ...state.projects,
        me: state.login.me.profile
    };
};

const md2p = { 
    ...actions 
};

export default connect(ms2p, md2p)(ListProjects)