import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from "moment";

const LOADER = "ACTIVITY_STAT_LOADER";
const DATA = "DATA_ACTIVITY_DATA";
const ITEM = "ITEM_ACTIVITY_DATA";
const PAGE = "PAGE_INSTITUTION";
const ORDERING = "ORDERING";
const SEARCH = "SEARCH";
const FILTERS = "FILTERS";
const ACTIVIDAD = "ACTIVIDAD";
const INSTITUCION = "INSTITUCION";
const TENICO = "TENICO";
const DATESTART = "DATESTART";
const DATEEND = "DATEEND";
// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setItem = (item) => ({
    type: ITEM,
    item,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});
const setFilters = (filters) => ({
    type: FILTERS,
    filters,
});
const setActividad = (actividad) => ({
    type: ACTIVIDAD,
    actividad,
});
const setInstitucion = (institucion) => ({
    type: INSTITUCION,
    institucion,
});
const setTecnico = (tecnico) => ({
    type: TENICO,
    tecnico,
});
const setDateStart = (date_start) => ({
    type: DATESTART,
    date_start,
});
const setDateEnd = (date_end) => ({
    type: DATEEND,
    date_end,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportesActividad;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;

    resource.actividad ? params.actividad = resource.actividad : null;
    resource.institucion ? params.institucion = resource.institucion : null;
    resource.tecnico ? params.tecnico = resource.tecnico : null;
    resource.date_start ? params.date_start = resource.date_start : null;
    resource.date_end ? params.date_end = resource.date_end : null;

    dispatch(setLoader(true));
    api.get("/activities/listreporte", params)
        .then((response) => {
            if (response) {
                dispatch(extractfilters());
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            console.log(error);
            let msj = "parece que ocurrion un error";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getReporte = (params) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get("/activities/detallereporte", params)
        .then((response) => {
            if (response) {
                dispatch(setItem(response));
            }
        })
        .catch((error) => {
            dispatch(setItem({}));
            let msj = "Este tecnico no realizo la actividad";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const extractfilters = (data) => (dispatch) => {
    api.get("/activities/filtros")
        .then((response) => {
            dispatch(setFilters(response))
        })  
        .catch((error) => {
            console.log(error);
        })
};

const searchChange = (search) => (dispatch) => {
    console.log("data desde el search: ", search);
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().reportesActividad.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const onApplyFilters = (data, type) => (dispatch, getStore) => {
    if(type === 'ACTIVIDAD') dispatch(setActividad(data))
    else if(type === 'INSTITUCION') dispatch(setInstitucion(data))
    else if(type === 'TECNICO') dispatch(setTecnico(data))
    else if(type === 'DATESTART') dispatch(setDateStart(data? moment(data).format('YYYY-MM-DD'):null))
    else if(type === 'DATEEND') dispatch(setDateEnd(data? moment(data).format('YYYY-MM-DD'):null))

    dispatch(listar())
};

const onDeleteFilters = () => (dispatch, getStore) => {
    dispatch(setActividad(null));
    dispatch(setInstitucion(null));
    dispatch(setTecnico(null));
    dispatch(setDateStart(null));
    dispatch(setDateEnd(null));
    dispatch(listar());
};

export const descarga_excel = () => (dispatch, getStore) => {
    // priemtro sacamos los paramtros para filtrar
    const resource = getStore().reportesActividad;
    const params = new Object(); 
    
    resource.actividad ? params.actividad = resource.actividad : null;
    resource.institucion ? params.institucion = resource.institucion : null;
    resource.tecnico ? params.tecnico = resource.tecnico : null;
    resource.date_start ? params.date_start = resource.date_start : null;
    resource.date_end ? params.date_end = resource.date_end : null;

    api.get("activities/download_excel", params)
        .then((response) => {
            if (response && response.id_descarga) {
                console.log(response, "DOWNLOAD");
                dispatch(verifyDownload(response.id_descarga));
            }
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                error.detail ||
                    "Ha ocurrido un error, por favor vuelva a intentar.",
                "ERROR",
                0
            );
        });
};
const verifyDownload = (id_descarga) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("activities/verify_download", { id_descarga })
        .then((response) => {
            const status = response.staus_download;
            console.log("Estado de descarga", status);
            if (status === "waiting") {
                setTimeout(() => {
                    dispatch(verifyDownload(id_descarga));
                }, 1000);
            } else if (status === "finished") {
                console.log("Este es el response", response);
                window.location.replace(response.file_excel.file_excel);
                dispatch(setLoader(false));
                //despues se elimina el archivo
                dispatch(deleteFileExcel(id_descarga));
            }
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                "Ocurrió al intentar descargar el archivo",
                "ERROR",
                0
            );
            dispatch(setLoader(false));
        });
};

const deleteFileExcel = (id) => () => {
    api.eliminar(`activities/${id}/delete_file_excel`)
        .then((response) => {
            console.log("Borro la descarga", response);
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                "Ocurrió un error eliminar el archivo de excel",
                "ERROR",
                0
            );
        });
};

export const actions = {
    listar,
    searchChange,
    onSortChange,
    getReporte,
    onApplyFilters,
    descarga_excel,
    onDeleteFilters
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTERS]: (state, { filters }) => {
        return {
            ...state,
            filters,
        };
    },
    [ACTIVIDAD]: (state, { actividad }) => {
        return {
            ...state,
            actividad,
        };
    },
    [INSTITUCION]: (state, { institucion }) => {
        return {
            ...state,
            institucion,
        };
    },
    [TENICO]: (state, { tecnico }) => {
        return {
            ...state,
            tecnico,
        };
    },
    [DATESTART]: (state, { date_start }) => {
        return {
            ...state,
            date_start,
        };
    },
    [DATEEND]: (state, { date_end }) => {
        return {
            ...state,
            date_end,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: "",
    search: "",
    actividad: "",
    institucion: "",
    tecnico: "",
    date_start: "",
    date_end: "",
    filters: {
        instituciones: [],
        actividades: [],
        tecnicos: []
    },
};

export default handleActions(reducers, initialState);
