import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ChangeForm from "./ChangeForm"
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ChangePass extends Component {
       

    render() {
        const { changePassword, loader } = this.props;
       
        return (
            <div className="bg-login">
                <div className="card-login-posision col-lg-4 col-md-4 col-12 px-4 ">
                    <div className="card card-login">
                        <div className="d-table ">
                            <img
                                id="main-logo-recuperar"
                                className="d-inline-block align-top mr-1"
                                src={require("assets/img/1-Contraseña@2x.png")}
                                alt="Logo"
                            />
                        </div>
                        <h5 className="pt-2">Primera vez</h5>
                        <p>
                            Si es tu primera vez ingresando al sistema, es necesario que cambies tu contraseña.
                        </p>
                        <LoadMask loading={loader} light>
                            <ChangeForm onSubmit={changePassword} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePass;
