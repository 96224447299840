import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {
    DEPARTAMENTOS_SELECT,
    MUNICIPIOS,
} from "../../../common/components/Utils/constant";
import { initialize as initializeForm } from "redux-form";

//onst SUBMIT = "SUBMIT";
const LOADER = "LOADER";
const DATA = "DATA_BENEFICIARY";
const PAGE = "PAGE_BENEFICIARY";
const ORDERING = "ORDERING_BENEFICIARY";
const SEARCH = "SEARCH_BENEFICIARY";
const TECNICO = "TECNICO";
const INSTITUTION = "INSTITUTION";
const DEPARTAMENTO = "DEPARTAMENTO";
const MUNICIPIO = "MUNICIPIO";
const COMUNIDAD = "COMUNIDAD";
const TYPE = "TYPE";

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data,
});
const setPage = (page) => ({
    type: PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});

const setTecnico = (tecnico) => ({
    type: TECNICO,
    tecnico,
});

const setInsitution = (institution) => ({
    type: INSTITUTION,
    institution,
});
const setDepa = (departamento) => ({
    type: DEPARTAMENTO,
    departamento,
});
const setMuni = (municipio) => ({
    type: MUNICIPIO,
    municipio,
});
const setComm = (comunidad) => ({
    type: COMUNIDAD,
    comunidad,
});
const setType = (type_beneficiary) => ({
    type: TYPE,
    type_beneficiary,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().asignaciones;
    const params = { page };

    params.ordering = resource.ordering;
    params.search = resource.search;
    params.institution = resource.institution;
    params.tecnico_id = resource.tecnico;

    if (resource.departamento) params.departamento = resource.departamento;
    if (resource.municipio) params.municipio = resource.municipio;
    if (resource.comunidad) params.comunidad = resource.comunidad;
    if (resource.type_beneficiary)
        params.type_beneficiary = resource.type_beneficiary;

    dispatch(setLoader(true));
    dispatch(cleanData());

    api.get("asignaciones", params)
        .then((response) => {
            if (response) {
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            console.log(error);
            // NotificationManager.error(error, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const assign_all = (data) => (dispatch) => {
    api.post("asignaciones/assignall", data)
        .then(() => {
            dispatch(listar());
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(e, "ERROR", 5000);
        });
};

const saveAssignments = (data) => (dispatch) => {
    api.post("asignaciones/asignar", data)
        .then(() => {
            NotificationManager.success(
                "Datos guardados con éxito",
                "Éxito",
                3000
            );
            dispatch(setMuni(null));
            dispatch(setComm(null));
            dispatch(setDepa(null));
            dispatch(setType(null));
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(e, "ERROR", 5000);
        });
};

export const listar_initial = (tecnico_id, institution) => (dispatch) => {
    dispatch(setTecnico(tecnico_id));
    dispatch(setInsitution(institution));
    dispatch(listar());
};

export const updateList = (newData) => (dispatch) => {
    /** esta funcion se usa con el boton de "asignar a todos"
     * limpia los datos y luego los actualiza con sus nuevos estados
     */
    dispatch(cleanData());
    // sin el timeout react no detecta los cambios !importante
    setTimeout(() => {
        dispatch(setData(newData));
    }, 0);
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(cleanData());
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().asignaciones.ordering;
    dispatch(cleanData());
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const cleanData = () => (dispatch) => {
    dispatch(
        setData({
            results: [],
            count: 0,
        })
    );
};

const getDepartaments = () => (dispatch, getStore) => {
    return DEPARTAMENTOS_SELECT;
};

const filtrarMunicipio = () => (dispatch, getStore) => {
    const depa = getStore().asignaciones.departamento;
    const municipios = [{ value: "00", label: "ninguno" }];
    MUNICIPIOS.map((item) => {
        if (item.departamento == depa) {
            municipios.push({ value: item.id, label: item.nombre });
        }
    });
    return municipios;
};

const filtrarComunidades = () => (dispatch, getStore) => {
    const muni = getStore().asignaciones.municipio;
    const COMUNIDADES = getStore().beneficiarios.comunidades;
    const comunidades = [{ value: "00", label: "ninguno" }];
    COMUNIDADES.map((item) => {
        if (item.municipality == muni) {
            comunidades.push({ value: item.id, label: item.name });
        }
    });
    return comunidades;
};

const setDepartamento = () => (dispatch, getStore) => {
    dispatch(
        initializeForm("asignacionTecnico", {
            municipio: "00",
            comunidad: "00",
        })
    );
    dispatch(setMuni(null));
    dispatch(setComm(null));
    setTimeout(() => {
        const { departamento } = getStore().form.asignacionTecnico.values;
        if (departamento !== "00") dispatch(setDepa(departamento));
        else dispatch(setDepa(null));
        dispatch(listar());
    }, 0);
};
const setMunicipio = () => (dispatch, getStore) => {
    dispatch(initializeForm("asignacionTecnico", { comunidad: "00" }));
    setTimeout(() => {
        const { municipio } = getStore().form.asignacionTecnico.values;
        if (municipio !== "00") dispatch(setMuni(municipio));
        else dispatch(setMuni(null));
        dispatch(listar());
    }, 0);
};

const setComunidad = () => (dispatch, getStore) => {
    setTimeout(() => {
        const { comunidad } = getStore().form.asignacionTecnico.values;
        if (comunidad !== "00") dispatch(setComm(comunidad));
        else dispatch(setComm(null));
        dispatch(listar());
    }, 0);
};

const setTypeBenfi = () => (dispatch, getStore) => {
    setTimeout(() => {
        const { tipo } = getStore().form.asignacionTecnico.values;
        if (tipo !== "00") dispatch(setType(tipo));
        else dispatch(setType(null));
        dispatch(listar());
    }, 0);
};

export const actions = {
    listar,
    listar_initial,
    searchChange,
    onSortChange,
    updateList,
    assign_all,
    saveAssignments,

    getDepartaments,
    filtrarMunicipio,
    filtrarComunidades,
    setDepartamento,
    setMunicipio,
    setComunidad,
    setTypeBenfi,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TECNICO]: (state, { tecnico }) => {
        return {
            ...state,
            tecnico,
        };
    },
    [INSTITUTION]: (state, { institution }) => {
        return {
            ...state,
            institution,
        };
    },
    [DEPARTAMENTO]: (state, { departamento }) => {
        return {
            ...state,
            departamento,
        };
    },
    [MUNICIPIO]: (state, { municipio }) => {
        return {
            ...state,
            municipio,
        };
    },
    [COMUNIDAD]: (state, { comunidad }) => {
        return {
            ...state,
            comunidad,
        };
    },
    [TYPE]: (state, { type_beneficiary }) => {
        return {
            ...state,
            type_beneficiary,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    ordering: "",
    search: "",
    tecnico: "",
    institution: "",
    departamento: null,
    municipio: null,
    comunidad: null,
    type_beneficiary: null,
};

export default handleActions(reducers, initialState);
