import React, { Component } from "react";
import Grid from "Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import {
    standardActions,
    customStandardActions,
} from "Utils/Grid/StandardActions";

class TablaActivities extends Component {
    comprobar = (id) => {
        const { data, me, deleteActivity } = this.props;
        let item = undefined;
        if (data && data.results) item = data.results.find((e) => e.id == id);

        if (me && me.role !== 10 && item.project) {
            return customStandardActions(id, {
                ver: "actividades",
            });
        } else {
            return customStandardActions(id, {
                editar: "actividades",
                eliminar: deleteActivity,
                ver: "actividades",
            });
        }
    };

    comprobar2 = (_data) => {
        const {id} = _data
        const { data, me, finalizar } = this.props;
        let item = undefined;
        if (data && data.results) item = data.results.find((e) => e.id == id);

        if (me && me.role !== 10 && item.project) {
            return "";
        } else {
            return customStandardActions(_data, {
                seleccionar2: (_id, value) => {
                    finalizar({id, value});
                },
            });
        }
    };

    render() {
        const {
            data,
            page,
            loader,
            onPageChange,
            onSortChange,
            deleteActivity,
        } = this.props;

        return (
            <div className="card">
                <React.Fragment>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width="15%"
                            isKey
                            dataFormat={this.comprobar}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="is_finished"
                            dataAlign="center"
                            dataFormat={this.comprobar2}
                            width="10%"
                        >
                            Finalizar
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="name" dataSort>
                            Actividad
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="project"
                            dataSort
                            dataFormat={(cell) => {
                                return cell && cell.name
                                    ? cell.name
                                    : "Sin proyecto";
                            }}
                        >
                            Proyecto
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="description" dataSort>
                            Observaciones
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            </div>
        );
    }
}

export default TablaActivities;
