import { connect } from "react-redux";
import { actions as usersActions } from "../../../../../redux/modules/usuarios/usuariosAdmin";
import Usuarios from "./Usuarios";

const ms2p = (state) => {
    return {
        ...state.usuariosAdmin,
        me: state.login.me,
    };
};

const md2p = {
    ...usersActions,
};

export default connect(ms2p, md2p)(Usuarios);
