import React, { Component } from "react";
import Grid from "Utils/Grid";
import { standardActions } from "Utils/Grid/StandardActions";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";
import FiltroInsumos from '../filtros/filtros_insumos';
import "../style.css";

const isExpandableRow = () => {
    return true;
};

const tipo_bene = tipo => {
    if (tipo == 10) {
        return "Mujer";
    } else if (tipo == 20) {
        return "Hombre";
    } else if (tipo == 30) {
        return "Niño apadrinado";
    } else if (tipo == 40) {
        return "Joven Beneficiario";
    } else if (tipo == 50) {
        return "Joven voluntario";
    }else if (tipo == 60) {
        return "Sin asignar";
    }
};
const genero = tipo => {
    if (tipo == 10) {
        return "Mujer";
    } else if (tipo == 20) {
        return "Hombre";
    }else {
        return "Sin asignar";
    }
};

class TablaInsumos extends Component {
    state = {       
        dropdownOpen: false,
    };
    expandComponent = (row) => {
        let data = row && row.insumos_entregados ? row.insumos_entregados : [];
        let multiplo = _.cloneDeep(data);
        return (
            <div className="insidetable py-3 px-5">
                <BootstrapTable hover striped data={multiplo}>                   
                    <TableHeaderColumn 
                        isKey 
                        dataField="name" 
                        dataSort
                        width="20%"
                    >
                        Insumo
                    </TableHeaderColumn>                    
                    <TableHeaderColumn 
                        dataField="creado"
                        dataSort
                        width="15%"
                        
                    >
                        Fecha
                    </TableHeaderColumn>
                    
                </BootstrapTable>
            </div>
        );
    };
    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    render() {
        const {
            insumos,
            page,
            loader,
            onPageChange,
            onSortChange,

            filtrarMunicipio,
            filtrarComunidades,
            municipios,
            comunidades_filtradas,
            filtro_departamento,
            filtro_municipio,
            filtro_comunidad,
            filtro_genero,
            filtro_institucion,
            institucion,
            filtro_desde,
            filtro_hasta,
        } = this.props;

        return (
            <div className="card">
                 <div className="container p-2">
                    <div className="align-items-start d-flex w-0">
                        <div className="justify-content-between align-items-center d-flex flex-1 flex-lg-row ">
                            <label>
                                <span className="font-weight-bold">
                                    {insumos.total_insumos} 
                                </span>
                                {" "}
                                total de insumos / 
                                {" "}
                                <span className="font-weight-bold">
                                    {insumos.total_beneficiarios } 
                                </span> 
                                {" "}
                                total de beneficiarios
                            </label>
                            <div
                                className="d-flex align-items-center btn btn-outline-black px-3 font-weight-bold "
                                onClick={this.toggle}
                            >
                                <span>Filtrar</span>
                                <div className="d-inline-block item-icon-wrapper">
                                    <i
                                        id="arrow_down"
                                        className="inline-block align-top ml-4"
                                    >
                                        Flecha abajo
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mr-6 ">
                    {this.state.dropdownOpen ? (
                        <div
                            className="card card-small mr-2 p-3 mt-1 border "
                            style={{
                                position: "absolute",
                                zIndex: 99999,
                                width: '96%'
                            }}
                        >
                            <FiltroInsumos
                                filtrarMunicipio={filtrarMunicipio}
                                filtrarComunidades={filtrarComunidades}
                                municipios={municipios}
                                comunidades_filtradas={comunidades_filtradas}
                                filtro_departamento={filtro_departamento}
                                filtro_municipio={filtro_municipio}
                                filtro_comunidad={filtro_municipio}
                                filtro_genero={filtro_genero}
                                filtro_institucion={filtro_institucion}
                                institucion={institucion}
                                filtro_desde={filtro_desde}
                                filtro_hasta={filtro_hasta}
                            />
                        </div>
                    ) : null}
                        
                    </div>
                </div>

                
                
                
                <Grid
                        className="table-striped2"
                        data={insumos.data ? insumos.data:[]}
                        expandableRow={isExpandableRow}
                        expandComponent={this.expandComponent}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width="11%"
                            dataFormat={standardActions({                               
                                expand: true,
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn  dataField="name" dataSort>
                            Beneficiario
                        </TableHeaderColumn>
                        <TableHeaderColumn 
                            dataField="gender"
                            dataSort
                            width="10%"
                            dataFormat={(cell) => {
                                return genero(cell); 
                            }}>
                            Sexo
                        </TableHeaderColumn>
                        <TableHeaderColumn  dataField="cantidad" dataSort>
                            Total de Insumos
                        </TableHeaderColumn>
                        <TableHeaderColumn  dataField="fecha" dataSort width="11%">
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn  dataField="community" dataSort>
                            Comunidad
                        </TableHeaderColumn>
                        <TableHeaderColumn  dataField="institution" dataSort>
                            Organización
                        </TableHeaderColumn>
                       
                    </Grid>
               
            </div>
        );
    }
}

export default TablaInsumos;
