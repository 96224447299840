import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import TablaActivities from "./TablaActivities";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Header from "../../../Utils/Grid/Header";

class ListActivities extends Component {
    static propTypes = {
        activities: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        this.props.onDeleteFilters();
    }

    onDeleteActivity = (id) => {
        this.props.deleteActividad(id);
        this.props.onDeleteFilters();
    };

    render() {
        const {
            activities,
            loader,
            page,
            listarActividades: onPageChange,
            onSortChange,
            me,
            finalizar
        } = this.props;
        
        return (
            <React.Fragment>            
                <div className="mt-4">
                    <Header
                        to="/actividades/nuevo"
                        textBtn="Nuevo"
                        {...this.props}
                    />
                    <TablaActivities
                        deleteActivity={this.onDeleteActivity}
                        data={activities}
                        loader={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                        me={me}
                        finalizar={finalizar}
                    />
                </div>
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: "search_usersAdmin", // a unique identifier for this form
})(ListActivities);
