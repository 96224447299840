import React, { Component, Fragment } from "react";
import Header from "../../Utils/Grid/Header";
import Grid from "Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";
import { Field, reduxForm } from "redux-form";
import { SelectField, renderDatePicker } from "Utils/renderField/renderField";
import moment from "moment";

const exampleOptions = [
    { label: "Todos", value: null },
    { label: "Superadmin", value: 10 },
    { label: "Administrador", value: 20 },
    { label: "Tecnico", value: 30 },
];

const Filtro1 = ({ onChange }) => {
    return (
        <div style={{ width: 200 }}>
            <label className="font-weight-bold mb-0">Tipo de usaurio:</label>
            <Field
                onChange={(e, value) => onChange(value)}
                placeholder="Usuario"
                name="tipos"
                options={exampleOptions}
                component={SelectField}
                clearable={true}
            />
        </div>
    );
};

const Filtro2 = ({ setDatestart, setDateEnd }) => {
    return (
        <div className="d-flex flex-col">
            <div className="w-50 mr-1">
                <label className="font-weight-bold mb-0">Desde:</label>
                <Field
                    onChange={(e, value) => setDatestart(value)}
                    name="fecha_inicio"
                    placeholder="desde"
                    options={exampleOptions}
                    component={renderDatePicker}
                />
            </div>
            <div className="w-50 ml-1">
                <label className="font-weight-bold mb-0">hasta:</label>
                <Field
                    onChange={(e, value) => setDateEnd(value)}
                    name="fecha_fin"
                    placeholder="hasta"
                    options={exampleOptions}
                    component={renderDatePicker}
                />
            </div>
        </div>
    );
};

class ListBitacoras extends Component {
    componentDidMount() {
        this.props.filter_by_type(null);
        this.props.set_from(null);
        this.props.set_to(null);
        this.props.listar();
    }

    _filtro1 = (value) => {
        this.props.filter_by_type(value);
    };

    setDatestart = (value) => {
        this.props.set_from(value);
    };

    setDateEnd = (value) => {
        this.props.set_to(value);
    };

    render() {
        const {
            data,
            loader,
            page,
            listar: onPageChange,
            onSortChange,
        } = this.props;
        return (
            <div className="mt-4">
                <Header
                    filtro1={<Filtro1 onChange={this._filtro1} />}
                    filtro2={
                        <Filtro2
                            setDateEnd={this.setDateEnd}
                            setDatestart={this.setDatestart}
                        />
                    }
                    {...this.props}
                />
                <div className="card">
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            isKey
                            width="10%"
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                ver: "bitacoras",
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="created"
                            dataSort
                            dataFormat={(cell) => moment(cell).format('YYYY-MM-DD')}
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="tipo" dataSort>
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="user_name" dataSort>
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="action" dataSort>
                            Movimiento
                        </TableHeaderColumn>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "bitacoras_form", // a unique identifier for this form
})(ListBitacoras);
