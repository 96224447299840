import { api } from "api";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";

export default class Header extends Component {
    render() {
        const { filtro1, filtro2 } = this.props;
        const {
            search,
            to,
            to2,
            header,
            textBtn,
            textBtn2,
            disabledBtn2,
            btn2Click,
            searchChange,
            placeholder,

            textBtn3,
            disabledBtn3,
            btn3Click, 
            filterbar,
        } = this.props;
        return (
            <div className="d-flex w-0">
                {filterbar ? (
                    filterbar
                ) : (
                    <div className="mb-1 d-flex flex-1 flex-column flex-lg-row col-12 px-0">
                        
                        {filtro1 && (
                            <div className=" d-flex flex-column flex-auto mx-1 px-0 mb-2 mb-lg-0">
                                {filtro1}
                            </div>
                        )}
                        {filtro2 && (
                            <div className=" d-flex flex-column flex-auto mx-1 px-0 mb-2 mb-lg-0">
                                {filtro2}
                            </div>
                            
                        )}   
                       
                        {filtro1 && filtro2 && (
                            <div className="d-flex flex-column flex-1 mx-5 px-0 d-sm-none d-md-none d-lg-inline"></div>
                        )}                  
                        <div
                            className={`d-flex flex-row align-items-center justify-content-start justify-content-lg-start  px-0 ${
                                to !== undefined ||
                                to2 !== undefined ||
                                textBtn2 !== undefined ||
                                header !== undefined
                                    ? ""
                                    : "d-sm-none d-md-none d-lg-inline"
                            }`}
                        >
                            {header !== undefined && (
                                <h6 className="pl-4 m-0 p-0">{header}</h6>
                            )}
                            {to !== undefined && (
                                <Link
                                    to={to}
                                    className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                                >
                                    {textBtn}
                                </Link>
                            )}
                            {to2 !== undefined && (
                                <button
                                  onClick={btn2Click}
                                  disabled={disabledBtn2 ? disabledBtn2 : false}
                                  className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                              >
                              {to2}
                          </button>
                            )}
                             {/* <Link
                                    to={to2}
                                    className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                                >
                                    {textBtn2}
                                </Link> */}
                            {textBtn3 !== undefined && (
                                <button
                                    onClick={btn3Click}
                                    disabled={disabledBtn3 ? disabledBtn3 : false}
                                    className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                                >
                                {textBtn3}
                            </button>
                        )}
                        </div>
                        {!filtro1 && !filtro2 && (
                            <div className="d-flex flex-column flex-1 mx-5 px-0 d-sm-none d-md-none d-lg-inline"></div>
                        )}
                        {search !== undefined && (
                            <div
                                className={`d-flex flex-column justify-content-center flex-2 mx-1 px-0 ${
                                    search !== undefined
                                        ? ""
                                        : "d-sm-none d-md-none d-lg-inline"
                                    } ${
                                        filtro1 && filtro2 ? 
                                        "pt-3"
                                        :""
                                    }`}
                            >
                                <SearchInput
                                    search={search}
                                    searchChange={searchChange}
                                    placeholder={placeholder}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
