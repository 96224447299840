import React, { Fragment } from "react";
import moment from "moment";
import { Prev, Current } from "./index";

const DetalleUsuario = ({ item }) => {
    let detail = item.detail ? JSON.parse(item.detail) : null;
    let previous = item.previous_detail
        ? JSON.parse(item.previous_detail)
        : null;

    return (
        <Fragment>
            {/* estado anterior */}
            {previous && (
                <div className="w-100 mb-5 mb-lg-0">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.created).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0">Anterior</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #3B3B41" }}
                    />

                    <div className="col-12">
                        <Prev title="Nombre" value={previous.first_name} />
                        <Prev title="Apellido" value={previous.last_name} />
                        <Prev title="Username" value={previous.username} />
                        <Prev title="Email" value={previous.email} />
                        <Prev
                            title="Institución"
                            value={previous.institution_name}
                        />
                    </div>
                </div>
            )}
            {previous && <div className="mx-5" />}
            {/* estado actual */}
            {detail && (
                <div className="w-100">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.modified).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0 text-primary">Actual</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #008FF1" }}
                    />

                    <div className="col-12">
                        <Current
                            title="Nombre"
                            value={detail.first_name}
                            prev={previous ? previous.first_name : null}
                        />
                        <Current
                            title="Apellido"
                            value={detail.last_name}
                            prev={previous ? previous.last_name : null}
                        />
                        <Current
                            title="Username"
                            value={detail.username}
                            prev={previous ? previous.username : null}
                        />
                        <Current
                            title="Email"
                            value={detail.email}
                            prev={previous ? previous.email : null}
                        />
                        <Current
                            title="Institución"
                            value={detail.institution_name}
                            prev={previous ? previous.institution_name : null}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default DetalleUsuario;
