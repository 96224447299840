import React, { Component,Fragment } from "react";
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { SelectField, renderDatePicker, AsyncSelectField } from "Utils/renderField/renderField";
import { api } from "../../../../../utility/api";
import Header from "../../../Utils/Grid/Header";
import HeaderExcel from "../../../Utils/Grid/HeaderExcel";
import HeaderCarnet from "../../../Utils/Grid/HeaderCarnet";
import SubirExcel from "../subir-excel"
import ReactToPrint, { PrintContextConsumer} from "react-to-print";
import CarnetsMasivos from "../Carnet/carnets_masivos";
import { DEPARTAMENTOS_SELECT,  MUNICIPIOS} from "../../../Utils/constant";

const tipobeneficiario = [
    { label: "Ninguno", value:-1 },
    { label: "Mujer", value: 10 },
    { label: "Hombre", value: 20 },
    { label: "Niño apadrinado", value: 30 },
    { label: "Joven Beneficiario", value: 40 },
    { label: "Joven voluntario", value: 50 },
];

const getInstitution = ( /* {institution_name} = this.props.me */) =>{
    const institutions = [];
    let params = {};
    //if (institution_name) params = { search: institution_name };
    return api.get("/institution/raw"/* , params */).then((response) => {
        if (response) {
            //console.log("Este es el response",response);
            institutions.push({
                value: -1, //institution.id,
                label: "Ninguno",
            });
            response.forEach((institution) => {
                institutions.push({
                    value: institution.id, //institution.id,
                    label: institution.name,
                });
            });
            return institutions
        }
    })
    .catch((error) => {
        return [];
    });
}

const Filtro1 = ({ onChange }) => {
    return (
        <Field
        // disabled={disabled}
        onChange={(e, value) => onChange(value.value)}
        placeholder="Institución"
        name="institution"
        component={AsyncSelectField}
        loadOptions = {getInstitution}
        clearable={true}
    />
    );
};
const Filtro2 = ({ onChange }) => {
    return (
        <Field
        // disabled={disabled}
        onChange={(e, value) => onChange(value)}
        placeholder="Tipo beneficiario"
        name="tipos"
        options={tipobeneficiario}
        component={SelectField}
        clearable={true}
    />
    );
};
const Filtro3 = ({ setDatestart, setDateEnd }) => {
    return (
        <div className="d-flex flex-col">
            <div className="w-50 mr-1">
                <label className="font-weight-bold mb-0">
                    Desde:

                </label>
                <Field
                    onChange={(e, value) => setDatestart(value)}
                    name="fecha_inicio"
                    placeholder="Desde"
                    component={renderDatePicker}
                />
            </div>
            <div className="w-50 ml-1">
                <label className="font-weight-bold mb-0">
                    Hasta:
                </label>
                <Field
                    onChange={(e, value) => setDateEnd(value)}
                    name="fecha_fin"
                    placeholder="hasta"
                    component={renderDatePicker}
                />
            </div>
        </div>
    );
};

const Filtro4 = ({ onChange }) => {
    return (
        <Field
        name="departament"
        placeholder="Departamento"
        options={DEPARTAMENTOS_SELECT}
        component={SelectField}
        className="form-control"
        onChange={(e, value) => onChange(value)}
    />

    );
};
const Filtro5 = ({municipios, onChange }) => {
    return (
        <Field
            name="municipio"
            placeholder="Municipio"
            options={municipios}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}
        />

    );
};
const Filtro6 = ({comunidades,onChange }) => {
    return (
        <Field
            name="community"
            placeholder="comunidad"
            options={comunidades}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}

        />

    );
};
class ListarBeneficiarios extends Component {
    state = {
        modal_open: false,
        print:false,
    };
    abrirModal=()=>{
        this.toggleModal();
    }
    toggleModal = () => {
        if (this.state.modal_open) {
            this.setState({ modal_open: this.state.modal_open });
        }
        this.setState({ modal_open: !this.state.modal_open });
    };
    componentDidMount() {
        const { listar, page } = this.props;
        this.props.limpiar_filtros()
        listar(page);
        this.props.getCommunities();
        this.props.filtrarComunidades("")
        this.props.filtrarMunicipio("")
    }
//----------------------- FILTROS -------------------------------
    _filtro1 = (value) => {
        this.props.filtro_institucion(value);
        //console.log("START 1", value);
    };
    _filtro2 = (value) => {
        this.props.filtro_tipo_benefi(value);
        console.log("START 2", value);
    };
    _filtro4 = (value) => {
        //this.props.filtro_institucion(value);
        this.props.filtrarMunicipio(value, true);
        this.props.filtro_departamento(value);
        //console.log("START 4", value);
    };
    _filtro5 = (value) => {
        //this.props.filtro_institucion(value);
        this.props.filtrarComunidades(value, true);
        this.props.filtro_municipio(value)
        //console.log("START 5", value);
    };
    _filtro6 = (value) => {
        this.props.filtro_comunidad(value);
        //console.log("START 6", value);
    };
    setDatestart = (value) => {
        this.props.filtro_desde(value);
        //console.log("START 3", value);
    };

    setDateEnd = (value) => {
        this.props.filtro_hasta(value);
        //console.log("START 4", value);
    };
    llenar_carnets=()=>{
        this.props.get_beneficiarios_carnets(this.props.data.results);
        //this.props.open_modal(true);
    }

    render() {
        const {
            data,
            loader,
            page,
            listar: onPageChange,
            onSortChange,
            remove,
            descarga_excel,
            is_open,
            open_modal,
            data_para_carnets,
            municipios,
            comunidades_filtradas,
            vaciar_data_carnets,
            finalizar_loader

        } = this.props;
        const tipo_bene = tipo => {
            if (tipo == 10) {
                return "Mujer";
            } else if (tipo == 20) {
                return "Hombre";
            } else if (tipo == 30) {
                return "Niño apadrinado";
            } else if (tipo == 40) {
                return "Joven Beneficiario";
            } else if (tipo == 50) {
                return "Joven voluntario";
            }else if (tipo == 60) {
                return "Sin asignar";
            }
        };
        return (
            <React.Fragment>
                <div className="mt-4">

                    <Header
                        to="/beneficiario/nuevo"
                        textBtn="Nuevo"
                        to2="Subir excel"
                        btn2Click={this.toggleModal}
                       /*  textBtn3="Carnets"
                        btn3Click={this.llenar_carnets} */
                        {...this.props}

                    />
                    <HeaderCarnet
                        placeFiltro1="Departamento:"
                        filtro1={<Filtro4 onChange={this._filtro4} />}
                        placeFiltro2="Municipio:"
                        filtro2={<Filtro5 municipios={municipios} onChange={this._filtro5} />}
                        placeFiltro3="Comunidad:"
                        filtro3={ <Filtro6 comunidades={comunidades_filtradas} onChange={this._filtro6}/>}
                        textBtn2="Generar carnets"
                        carnets={true}
                        btn2Click={this.llenar_carnets}

                    />
                    <HeaderExcel
                        placeFiltro1="Institución:"
                        filtro1={<Filtro1 onChange={this._filtro1} />}
                        placeFiltro2="Tipo beneficiario:"
                        filtro2={<Filtro2 onChange={this._filtro2} />}
                        filtro3={
                            <Filtro3
                                setDateEnd={this.setDateEnd}
                                setDatestart={this.setDatestart}
                            />
                        }
                        textBtn2="Descargar excel"
                        btn2Click={descarga_excel}
                    />

                </div>
                {is_open &&(
                    <div className="d-none">
                        <CarnetsMasivos
                            data_para_carnets={data_para_carnets}
                            open_modal={ open_modal}
                            is_open={is_open}
                            vaciar_data_carnets={vaciar_data_carnets}
                            finalizar_loader={finalizar_loader}
                        />
                    </div>
               )}
                <div className="">
                    <div className="mb-4 card card-small">
                        <div className="p-0">
                            <Grid
                                hover
                                striped
                                data={data}
                                loading={loader}
                                onPageChange={onPageChange}
                                onSortChange={onSortChange}
                                page={page}
                            >
                                <TableHeaderColumn
                                    width="12%"
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({
                                        editar: "beneficiario",
                                        ver: "beneficiario",
                                        eliminar: remove,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="18%"
                                    dataField="institution"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return `${cell.name}`;
                                    }}
                                >
                                    Institución
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="type_beneficiary"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return tipo_bene(cell);
                                    }}
                                >
                                    Tipo Beneficiario
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="name"
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="birth_date"
                                    dataSort
                                >
                                    Fecha De Nacimiento
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </div>
                </div>

                <Modal
                        isOpen={this.state.modal_open}
                        className="mx-lg-auto mx-md-3 mx-2 pt-4"
                    >
                        <ModalHeader className="card p-0 px-4">
                            Carga de excel
                        </ModalHeader>
                        <ModalBody className="car mt-3">
                           <SubirExcel
                            cerrarModal={this.toggleModal}

                           />

                        </ModalBody>
                    </Modal>
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: "beneficiario_reporte_form", // a unique identifier for this form
})(ListarBeneficiarios);
