import { connect } from "react-redux";
import { actions as activitiesActions } from "../../../../../redux/modules/activities/activities";
import ListActivities from "./ListActivities";

const ms2p = (state) => {
    return {
        ...state.activities,
        me: state.login.me.profile
    };
};

const md2p = {
    ...activitiesActions,
};

export default connect(ms2p, md2p)(ListActivities);
