import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/reportes/Actividades";
import Reporte from "./Reporte";

const ms2p = (state) => {
    return {
        ...state.reportesActividad,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Reporte);
