import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import './acciones.css';
import Swal from "sweetalert2";
import "./searchInput.css";
import { Field } from "redux-form";
import { renderFieldCheck } from "../../Utils/renderField/renderField";

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: props.id ? props.id.is_assigned : false,
            isFinished: props.id ? props.id.is_finished : false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(value) {
        this.setState({
            isChecked: value,
        });
    }

    handleInputChange2(value) {
        this.setState({
            isFinished: value,
        });
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: "¿Eliminar?",
                text: "¡No podrá revertir esta acción!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, eliminar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };

    render() {
        let { id, ver, editar, editar2, eliminar, asignar, seleccionar, expand, detalle, seleccionar2 } = this.props;
        return (
            <Fragment>
                <div className="d-flex justify-content-center">
                    {expand !== undefined && (
                        <a
                            className="m-2 text-dark icon-bord icon-container bg-dark rounded-circle"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                        >
                            <i id="expandIcon">expand</i>
                        </a>
                    )}
                    {editar !== undefined && (
                        <Link
                            className="p-2 icon-borde icon-container"
                            to={`${editar}/${id}/editar`}
                        >
                            <i id="editIcon">edit</i>
                        </Link>
                    )}
                    {editar2 !== undefined && (
                        <a
                            className="p-2 text-dark icon-borde icon-container"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                            onClick={() => editar2(id)}
                        >
                            <i id="editIcon">edit</i>
                        </a>
                    )}
                    {ver !== undefined && (
                        <Link
                            to={`${ver}/${id}/`}
                            className="px-1 icon-borde-see py-1 my-1 icon-container"
                        >
                            <i id="eyeIcon">see</i>
                        </Link>
                    )}
                    {eliminar !== undefined && (
                        <a
                            className="p-2 text-dark icon-borde-remove icon-container"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                            onClick={this.eliminar(id)}
                        >
                            <i id="deleteIcon">edit</i>
                        </a>
                    )}
                    {asignar !== undefined && (
                        <a
                            className="p-2 text-dark icon-borde icon-container"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                            onClick={() => asignar(id)}
                        >
                            <i id="asignIcon">asignar</i>
                        </a>
                    )}
                    {detalle !== undefined && (
                        <a
                            className="p-2 text-dark icon-borde icon-container"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                            onClick={() => detalle(id)}
                        >
                            <i id="editIcon">edit</i>
                        </a>
                    )}
                    {seleccionar2 !== undefined && (
                        <a
                            className="text-dar icon-bord icon-container big-check"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                        >
                            <Field
                                name="checked"
                                component={renderFieldCheck}
                                onChange={(e, value) => {
                                    this.handleInputChange2(value);
                                    seleccionar2(id.id, value);
                                }}
                                checked={this.state.isFinished}
                            />
                        </a>
                    )}
                </div>
                <div className="">
                    {seleccionar !== undefined && (
                        <a
                            className="text-dar icon-bord icon-container big-check"
                            style={{ cursor: "pointer", color: "#c4183c" }}
                        >
                            <Field
                                name="checked"
                                component={renderFieldCheck}
                                onChange={(e, value) => {
                                    this.handleInputChange(value);
                                    seleccionar(id.id, value);
                                }}
                                checked={this.state.isChecked}
                            />
                        </a>
                    )}
                </div>
            </Fragment>
        );
    }
}
Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} />;
    };
}

export function customStandardActions(cell, acciones) {
    return <Acciones id={cell} {...acciones} />;
}
