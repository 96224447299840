import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField,  renderSwitch } from "../../Utils/renderField";
import { validate, validators } from "validate-redux-form";

class SettingsForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        return (
            <React.Fragment>
                <form
                    name="settingsForm"
                    className=" "
                    onSubmit={handleSubmit}
                >
                    <div className="px-5">
                        <div className="pb-3">                            
                        <fieldset>
                            <Field
                                name="state"
                                label="Creación nuevos beneficiarios desde app móvil"
                                value="default"
                                component={renderSwitch}
                            />
                            
                        </fieldset>
                        </div>                        
                    </div>
                    <div className="row">
                        <div className="col align-self-center">
                            <div className="buttons-box ">                              
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: "settingsForm", // Identificador de formulario
    //validate: validate
     validate: (data) => {      
        return validate(data, {
            name: validators.exists()("Este campo es requerido"),
        });
    },
})(SettingsForm);
