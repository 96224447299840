import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/comunidades";
// import { getFormMeta, getFormSyncErrors } from 'redux-form';
import CreateCommunity from "./Create";

const ms2p = (state, ownProps) => {
    return {
        ...state.comunidades,
        initialValues: {
            departament: ownProps.departament,
            municipality: ownProps.municipality,
        },
        onSubmitSuccess: () => {
            ownProps.onClose(null)
        },
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(CreateCommunity);
