import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderField } from "../../../Utils/renderField";
import { DEPARTAMENTOS_SELECT, MUNICIPIOS_SELECT } from "../../../Utils/constant";
import { SelectField } from "../../../Utils/renderField/renderField";

class CommunityForm extends Component {
    render() {
        const { handleSubmit, create } = this.props;
        return (
            <React.Fragment>
                <form
                    name="newInstitution"
                    className="form-validate mb-lg "
                    onSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.departament"
                            >
                                Departamento:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled
                                name="departament"
                                placeholder="Departamento"
                                options={DEPARTAMENTOS_SELECT}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.municipaplity"
                            >
                                Municipio:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled
                                name="municipality"
                                placeholder="Municipio"
                                options={MUNICIPIOS_SELECT}
                                component={SelectField}
                                className="form-control" 
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Comunidad:
                            </label>
                            <Field
                                name="name"
                                label="name"
                                maxLength={75}
                                component={renderField}
                                className="form-control"
                                placeholder="Nombre de la institucion"
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="button"
                                onClick={() => this.props.onClose(null)}
                                className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                            >
                                {" "}
                                Cancelar
                            </button>
                        </div>
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2"
                            >
                                {" "}
                                {create ? "Guardar" : "Actualizar"}
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = "Este campo es requerido";
    }
    if (values.name && values.name.length > 75) {
        errors.name = "la comunidad debe tener menos de 75 letras";
    }

    return errors;
};

export default reduxForm({
    form: "comunidadesForm", // Identificador de formulario
    validate,
})(CommunityForm);
