import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import {
    renderField,
    renderDatePicker,
    renderCurrency,
} from "../../../Utils/renderField";
import {
    SelectField,
    MultiSelectField,
} from "../../../Utils/renderField/renderField";

class ProjectForm extends Component {
    render() {
        const { handleSubmit, edit, disabled, institutions } = this.props;
        return (
            <React.Fragment>
                <div className="row justify-content-end mb-1">
                    <p className="col-12 text-primary font-weight-bold">
                        Todos los campos que lleven (*) Son requeridos.
                    </p>
                </div>
                <form
                    name="ProjectCreate"
                    className="form-validate mb-lg "
                    onSubmit={handleSubmit}
                >
                    <div className="row">
                        <div className="col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Nombre del proyecto:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="name"
                                placeholder="Nombre del proyecto"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Codigo:
                            </label>
                            <Field
                                disabled={disabled}
                                name="codigo"
                                placeholder="codigo del proyecto"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="user" className="font-weight-bold">
                                Descripción:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="description"
                                placeholder="descripción del proyecto"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Fecha de inicio:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="date_start"
                                component={renderDatePicker}
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="user" className="font-weight-bold">
                                Fecha de fin:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="date_end"
                                component={renderDatePicker}
                            />
                        </div>
                    </div>

                    {institutions && (
                        <div className="row">
                            <div className="col-12 mb-4 form-group has-feedback">
                                <label
                                    htmlFor="name"
                                    className="font-weight-bold"
                                >
                                    Instituciones:{" "}
                                    <span className="text-primary  font-weight-bold">
                                        *
                                    </span>
                                </label>
                                <Field
                                    disabled={disabled}
                                    name="institutions"
                                    placeholder="Instituciones"
                                    component={MultiSelectField}
                                    options={institutions}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.departament"
                            >
                                Nombre financiera:
                            </label>
                            <Field
                                disabled={disabled}
                                name="finance_name"
                                placeholder="Nombre financiero"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.municipaplity"
                            >
                                Presupuesto:
                            </label>
                            <Field
                                disabled={disabled}
                                name="estimate"
                                placeholder="Presupuesto"
                                component={renderCurrency}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.comunity"
                            >
                                Fondos:
                            </label>
                            <Field
                                disabled={disabled}
                                name="funds"
                                placeholder="Opciones de fondos"
                                options={[
                                    { value: 10, label: "FFRR" },
                                    { value: 20, label: "FFCC" },
                                ]}
                                component={SelectField}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <Link
                                className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                                to="/proyectos"
                            >
                                Cancelar
                            </Link>
                        </div>
                        {!disabled && (
                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2"
                                >
                                    {" "}
                                    {!edit ? "Guardar" : "Actualizar"}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = "Este campo es requerido";
    }
    if (values.name && values.name.length > 75) {
        errors.name = "el nombre del proyecto debe tener menos de 75 letras";
    }

    if (!values.description) {
        errors.description = "Este campo es requerido";
    }
    if (!values.date_start) {
        errors.date_start = "Este campo es requerido";
    }
    if (!values.date_end) {
        errors.date_end = "Este campo es requerido";
    }
    if (!values.institutions || !values.institutions[0]) {
        errors.institutions = "Este campo es requerido";
    }

    return errors;
};

export default reduxForm({
    form: "ProjectForm", // Identificador de formulario
    validate,
})(ProjectForm);
