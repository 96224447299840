import React, { Component, Fragment } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import Form from "./Form";
import { getIntitutions } from "../../../../../utility/functions";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import Carnet from "../Carnet/carnet";

import '../Carnet/carnet_styles.css'

class CrearBenefiario extends Component {
    _isMounted = false;
    state = {
        institutions: [],
        hijos_eliminar: [],
        editar: false,
    };

    componentDidMount() {
        // verifica si existe un id en la ruta y obtiene el usuario correspondiente
        const { id } = this.props.match.params;
        if (id) {
            this.props.getBeneficiario(id);
            this.setState({ editar: true });
        }
        this._getIntitutions();
        this.props.getCommunities();
    }

    _getIntitutions = async () => {
        this._isMounted = true;
        // obtiene todas las instituciones sin paginar y filtradas segun la institucion del usuario logueado
        const { institution_name } = this.props.me;
        const institutions = await getIntitutions(institution_name);

        if (this._isMounted) this.setState({ institutions });
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.props.open_modal(false);
    }

    onSave = () => {
        const { id } = this.props.match.params;
        if (this.state.editar) {
            this.props.update([...this.state.hijos_eliminar]);
        } else {
            this.props.register();
        }
    };

    eliminarhijo = (id) => {
        if (id) {
            this.setState({
                hijos_eliminar: [...this.state.hijos_eliminar, { id }],
            });
        }
    };

    render() {
        const {
            loader,
            tipo_beneficiario,
            filtrarMunicipio,
            municipios,
            comunidades_filtradas,
            filtrarComunidades,
            //las variables is_open y opend modal se usaron para no crear nuevas variebles
            //y se usan para activar los campos obligatorios de un beneficiarios niño
            //apadrinado
            is_open,
            open_modal,
            seleccionarGenero,
            
        } = this.props;      
        
        return (
            <div className="card my-4">
                <div className="py-4 my-2 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={loader} dark blur>
                        {this.state.editar && (
                            <Fragment>
                                <ReactToPrint
                                    content={() => this.componentRef}
                                >
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <button
                                                className="btn btn-primary mb-4 mb-md-2 col-12 col-md-5 col-lg-4"
                                                onClick={handlePrint}
                                            >
                                                Imprimir carnet
                                            </button>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>

                                <div className="d-none">
                                    <Carnet
                                        item={this.props.item}
                                        ref={(el) => (this.componentRef = el)}
                                       
                                    />
                                </div>
                            </Fragment>
                        )}

                        <Form
                            onSubmit={this.onSave}
                            institutions={this.state.institutions}
                            tipo_beneficiario={tipo_beneficiario}
                            eliminarhijo={this.eliminarhijo}
                            editar={this.state.editar}
                            filtrarMunicipio={filtrarMunicipio}
                            municipios={municipios}
                            filtrarComunidades={filtrarComunidades}
                            comunidades={comunidades_filtradas}
                            apadrinado={open_modal}
                            es_apadrinado={is_open}
                            seleccionarGenero={seleccionarGenero}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CrearBenefiario;
