import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    DetalleInstitucion,
    DetalleUsuario,
    DetalleActividad,
    DetalleBeneficiario,
    Detallehijo,
} from "./Detalles";

class BitacoraDetail extends Component {
    componentDidMount() {
        // verifica si existe un id en la ruta y obtiene la bitacora
        const { id } = this.props.match.params;
        if (id) this.props.getBitacora(id);
    }

    render() {
        const { item } = this.props;
        let detalle;

        if (item.action && item.action.includes("institución")) {
            detalle = <DetalleInstitucion item={item} />;
        } else if (item.action && item.action.includes("actividad")) {
            detalle = <DetalleActividad item={item} />;
        } else if (item.action && item.action.includes("hijo")) {
            detalle = <Detallehijo item={item} />;
        } else if (item.action && item.action.includes("beneficiario")) {
            detalle = <DetalleBeneficiario item={item} />;
        } else {
            detalle = <DetalleUsuario item={item} />;
        }

        return (
            <Fragment>
                <div className="card px-4 py-3 mt-4 d-flex flex-row align-items-center justify-content-between">
                    <Link
                        to="/bitacoras"
                        className="btn btn-light px-lg-4 px-3"
                    >
                        Regresar
                    </Link>
                    <div className="px-3">
                        <h6 className="m-0">{item.tipo}</h6>
                        <h4 className="m-0 text-primary">
                            {item.user_name}
                        </h4>
                    </div>
                    <div
                        className="vertical-divider"
                        style={{ height: "2.5rem" }}
                    />
                    <div className="d-flex flex-column text-center flex-lg-row">
                        <label className="font-weight-bold pr-2">
                            Fecha de acción
                        </label>
                        <label className="text-primary">
                            {moment(item.modified).format("DD/MM/YYYY")}
                        </label>
                    </div>
                    <div className="d-flex flex-column text-center flex-lg-row">
                        <label className="font-weight-bold pr-2">
                            Tipo acción
                        </label>
                        <label className="text-primary text-break">
                            {item.action}
                        </label>
                    </div>
                </div>

                <div className="card p-5 mt-4 d-flex flex-lg-row flex-column">
                    {detalle}
                </div>
            </Fragment>
        );
    }
}

export default BitacoraDetail;
