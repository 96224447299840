import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/recoverPass';
import RecoverPassword from './RecoverPassword';


const ms2p = (state) => {
  return {
    ...state.recoverPass,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecoverPassword);
