import React, { Component } from 'react';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import SettingsForm from './settingsForm';

import { Link } from 'react-router-dom';



class settings extends Component { 
    
   
    componentWillMount() {
        const { listar } = this.props;
        listar();
    }
    
    render(){   
        const {loader, update} = this.props;    
        return(
            <React.Fragment>
              
            <div className="card my-4">
                <div className="mx-auto py-4">
                <h4>Configuraciones globales</h4>
                
               
                </div>
                <div className="text-center pb-4">
                    <LoadMask loading={loader} blur>   
                            <SettingsForm  
                                onSubmit={update}                         
                            />                        
                        </LoadMask>
                </div>
                
            </div>
     
            </React.Fragment>
        );
    }
    
}

export default settings;