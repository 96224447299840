import React, { Component, Fragment } from "react";
import LoadMask from "../../../../common/components/Utils/LoadMask/LoadMask";
import { Field } from "redux-form";
import { SelectField } from "Utils/renderField/renderField";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { getIntitutions } from "../../../../utility/functions";
import { Doughnut } from "react-chartjs-2";
import { reduxForm } from "redux-form";
import Filtros from "./filtros/filtros";
import TablaInsumos from "./tablas/tabla_insumos";
import TablaActividades from "./tablas/tabla_actividades";
import TablaProyectos from "./tablas/tabla_proyectos";
import ItemGrafica from "./grafica/grafica_etnia";
import ItemGraficaProyecto from "./grafica/grafica_proyectos";
import "./style.css";

const Institucion = ({ institucion, onChange }) => {
    return (
        <Field
            name="institutions_home"
            placeholder="Filtrar por instituciones"
            options={institucion}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}
        />
    );
};
const InstitucionProyecto = ({ institucion, onChange }) => {
    return (
        <Field
            name="institutions_home_project"
            placeholder="Filtrar por instituciones"
            options={institucion}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}
        />
    );
};
class Home extends Component {
    _isMounted = false;
    state = {
        opciones: [],
        institutions: [],
        modal_open: false,
        type_modal: 0,
        dropdownOpen: false,
    };
    opciones() {
        const opciones = {
            maintainAspectRatio: true,
        };
        this.setState({ opciones: opciones });
    }
    _getIntitutions = async () => {
        this._isMounted = true;
        // obtiene todas las instituciones sin paginar y filtradas segun la institucion del usuario logueado
        const { institution_name } = this.props.me;
        const institutions = await getIntitutions(institution_name);

        if (this._isMounted) {
            institutions.unshift({ value: -1, label: "Ninguna" });
            this.setState({ institutions });
        }
    };
    toggleModal = () => {
        this.setState({ modal_open: !this.state.modal_open });
    };
    toggle = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };
    ver_actividades = () => {
        this.setState({ type_modal: 1 });
        this.toggleModal();
    };
    ver_insumos = () => {
        this.setState({ type_modal: 2 });
        this.toggleModal();
    };
    ver_proyectos = () => {
        this.setState({ type_modal: 3 });
        this.toggleModal();
    };
    _institucion = (value) => {
        this.props.filtro_institucion(value, 40);
    };
    _institucion_project = (value) => {
        this.props.filtro_institucion(value, 50);
        //console.log("START 5", value);
    };

    componentDidMount() {
        const {
            dashboard_beneficiarios,
            dashboard_caracterisiticas,
            dashboard_actividades,
            getCommunities,
            dashboard_insumos,
            dashboard_proyectos,
            page,
            limpiar_filtros,
        } = this.props;
        limpiar_filtros()
        this._getIntitutions();
        dashboard_beneficiarios();
        dashboard_caracterisiticas();
        dashboard_actividades(page);
        dashboard_insumos(page);
        dashboard_proyectos();
        getCommunities();
        this.opciones();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    //----------------------- FILTROS -------------------------------
    render() {
        const {
            loader,
            beneficiarios,
            caracteristicas,
            actividades,
            proyectos,
            insumos,
            municipios,
            filtrarMunicipio,
            filtrarComunidades,
            comunidades_filtradas,
            //filtos
            filtro_departamento,
            filtro_municipio,
            filtro_comunidad,
            filtro_genero,
            filtro_institucion,
            filtro_proyecto,
            filtro_desde,
            filtro_hasta,
            onSortChange,
            page,
        } = this.props;
        //console.log("Esto son las instituciones---",proyectos);
        return (
            <LoadMask loading={loader} dark blur>
                <div className="py-4 ">
                    <div className="row">
                        <div className="col-12  col-sm-12 col-md-12 col-lg-6 col-xl-4 ">
                            <div className="mb-3 card card-small">
                                <div className="container pl-0 pr-0 pt-2">
                                    <div className="align-items-start d-flex w-0">
                                        <div className="justify-content-between align-items-center d-flex flex-1 flex-lg-row ">
                                            <label className=" font-weight-bold pl-2">
                                                Beneficiarios
                                            </label>
                                            <div
                                                className="d-flex align-items-center btn btn-outline-black px-3 font-weight-bold "
                                                onClick={this.toggle}
                                            >
                                                <span>Filtrar</span>
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i
                                                        id="arrow_down"
                                                        className="inline-block align-top ml-4"
                                                    >
                                                        Flecha abajo
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.dropdownOpen ? (
                                        <div
                                            className="card card-small mr-2 p-2 mt-1 border "
                                            style={{
                                                position: "absolute",
                                                zIndex: 99999,
                                            }}
                                        >
                                            <Filtros
                                                filtrarMunicipio={filtrarMunicipio}
                                                filtrarComunidades={filtrarComunidades}
                                                municipios={municipios}
                                                comunidades_filtradas={comunidades_filtradas}
                                                filtro_departamento={filtro_departamento}
                                                filtro_municipio={filtro_municipio}
                                                filtro_comunidad={filtro_comunidad}
                                                filtro_genero={filtro_genero}
                                                filtro_institucion={filtro_institucion}
                                                institucion={this.state.institutions}
                                                filtro_desde={filtro_desde}
                                                filtro_hasta={filtro_hasta}
                                            />
                                        </div>
                                    ) : null}

                                    <div className="d-flex flex-2 flex-column flex-sm-row flex-md-row flex-lg-row align-items-center justify-content-center col-12 col-lg-12 pt-2 pb-3 pl-0 pr-0">
                                        <div className="col-3  col-sm-3 col-md-5 col-lg-5 col-xl-5 p-0 ">
                                            <Doughnut
                                                data={beneficiarios.grafica}
                                                cutout={75}
                                                options={{ responsive: true }}
                                            />
                                        </div>
                                        <div className=" align-items-center align-items-start col-6 col-sm-6 col-lg-6 mb-2">
                                            <div className="row ">
                                                <h1 className="col font-weight-bold text-center">
                                                    {beneficiarios.data.total}
                                                </h1>
                                                <h6 className="col font-weight-bold text-center">
                                                    Beneficiarios totales
                                                </h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="align-items-end mx-1">
                                        <div className="row justify-content-around">
                                            <div className="align-items-center pb-1">
                                                <h4 className="font-weight-bold">
                                                    {beneficiarios.data.adultos}{" "}
                                                    %
                                                </h4>
                                                <label>
                                                    <span className="adulto">
                                                        {" "}*{" "}
                                                    </span>
                                                    { beneficiarios.data.total_adultos}{" "}
                                                    Adultos
                                                </label>
                                            </div>
                                            <div>
                                                <h4 className="font-weight-bold">
                                                    {beneficiarios.data.jovenes}{" "}%
                                                </h4>
                                                <label>
                                                    {" "}<span className="jovenes">{" "}*{" "}</span>
                                                    { beneficiarios.data.total_jovenes}{" "} Jovenes
                                                </label>
                                            </div>
                                            <div className=" justify-content-center">
                                                <h4 className="font-weight-bold">
                                                    {beneficiarios.data.niños} %
                                                </h4>
                                                <label>
                                                    {" "}<span className="apadrinados">{" "}*{" "}</span>
                                                    {beneficiarios.data.total_niños}{" "} Niños A.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12  col-sm-6 col-md-6 col-lg-6 col-xl-4 ">
                            <div className="mb-4 card card-small p-2">
                                <div className="row  justify-content-start align-items-center">
                                    <label className="pl-4 font-weight-bold">
                                        Actividades realizadas
                                    </label>
                                </div>
                                <div className="align-items-center pb-1">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-2 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i
                                                        id="icono_actividades"
                                                        className="inline-block align-top mr-1"
                                                    >
                                                        Actividad
                                                    </i>
                                                </div>
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <h2 className="font-weight-bold">
                                                        {
                                                            actividades.total_actividades
                                                        }
                                                    </h2>
                                                    <span>
                                                        Total de actividades
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center pb-1 pt-1">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-1 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i
                                                        id="icono_asistencia"
                                                        className="inline-block align-top mr-1"
                                                    >
                                                        Asistencia
                                                    </i>
                                                </div>
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <h2 className="font-weight-bold">
                                                        {
                                                            actividades.total_asistencia
                                                        }
                                                    </h2>
                                                    <span>
                                                        Total de asistencias
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-1 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <a
                                                        onClick={ this.ver_actividades}
                                                        className="btn btn-primary w-100 mb-4 mb-md-2 text-white"
                                                    >
                                                        Ver detalle
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12  col-sm-6 col-md-6 col-lg-6 col-xl-4 ">
                            <div className="mb-4 card card-small p-2">
                                <div className="row  justify-content-start align-items-center">
                                    <label className="pl-4 font-weight-bold">
                                        Insumos entregados
                                    </label>
                                </div>
                                <div className="align-items-center pb-1">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-2 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i
                                                        id="icono_actividades"
                                                        className="inline-block align-top mr-1"
                                                    >
                                                        Actividad
                                                    </i>
                                                </div>
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <h2 className="font-weight-bold">
                                                        {insumos.total_insumos}
                                                    </h2>
                                                    <span>
                                                        Total de insumos
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center pb-1 pt-1">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-1 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <i
                                                        id="icono_asistencia"
                                                        className="inline-block align-top mr-1"
                                                    >
                                                        Asistencia
                                                    </i>
                                                </div>
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <h2 className="font-weight-bold">
                                                        {
                                                            insumos.total_beneficiarios
                                                        }
                                                    </h2>
                                                    <span>
                                                        Total beneficiarios
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="d-flex align-items-center pt-1 pb-2">
                                                <div className="d-inline-block item-icon-wrapper">
                                                    <a
                                                        onClick={
                                                            this.ver_insumos
                                                        }
                                                        className="btn btn-primary w-100 mb-4 mb-md-2 text-white"
                                                    >
                                                        Ver detalle
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="mb-4 card card-small p-2">
                                <div className="align-items-center d-flex w-0 ">
                                    <div className="justify-content-between  d-flex flex-1 flex-lg-row pl-2 pr-2">
                                        <label className=" font-weight-bold ">
                                            Características de la población
                                        </label>
                                        <div className="col-8 col-md-7 col-lg-7 col-xl-5 col-sm-7 ">
                                            <div
                                                className="col-12"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 9999,
                                                }}
                                            >
                                                <Institucion
                                                    institucion={ this.state.institutions }
                                                    onChange={this._institucion}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-row flex-wrap mb-2 pl-2 pr-2">
                                    <div className="col-3  col-sm-2 col-md-2 col-lg-1 col-xl-1 p-0 ">
                                        <Doughnut
                                            data={caracteristicas.grafica_discapacidad}
                                            options={{ responsive: true }}
                                        />
                                    </div>
                                    <div className="col-12  col-sm-8  col-md-10 col-lg-8 col-xl-6 justify-content-around">
                                        <label className="font-weight-bold pt-2">
                                            Discapacidad
                                        </label>
                                        <div className="row ">
                                            <div className="col-4 col-lg-5  align-items-center pb-1 row ml-1">
                                                <h6 className="font-weight-bold">
                                                    <span className="adulto">{" "} *{" "} </span>
                                                    {caracteristicas.data.discapacidad_hombre}{" "} %
                                                </h6>
                                                <label className="pl-2">
                                                    Hombres
                                                </label>
                                            </div>
                                            <div className="col-4 col-lg-5 align-items-center pb-1 row ml-1">
                                                <h6 className="font-weight-bold">
                                                    <span className="jovenes">*</span>{" "}
                                                    {caracteristicas.data.discapacidad_mujer}{" "}%{" "}
                                                </h6>
                                                <label className="pl-2">
                                                    Mujeres
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  justify-content-start align-items-center pb-2">
                                    <label className="pl-4 font-weight-bold mb-0">
                                        Grupo étnico
                                    </label>
                                </div>

                                <div className="d-flex flex-row flex-wrap mb-2 pl-2 ">
                                    <ItemGrafica
                                        data={caracteristicas.data_grafica}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <div className="mb-4 card card-small p-2">
                                <div className="align-items-center d-flex w-0 mb-2">
                                    <div className="justify-content-between  row align-items-center d-flex flex-2 flex-lg-row pl-2 pr-2 col-12">
                                        <label className=" font-weight-bold col-12 col-sm-12 col-md-6  col-lg-6 col-xl-6 ">
                                            Avance de proyectos
                                        </label>
                                        <div className="col row justify-content-end pr-0">
                                            <div className="justify-content-end pt-1 col-4 ">
                                                <a
                                                    onClick={this.ver_proyectos}
                                                    className="btn btn-primary w-100 mb-4 mb-md-2 text-white"
                                                >
                                                    Ver detalle
                                                </a>
                                            </div>

                                            <div className="pl-0 col-8 ">
                                                <div
                                                    className="col-12 px-0 "
                                                    style={{
                                                        position: "absolute",
                                                        zIndex: 9999,
                                                    }}
                                                >
                                                    <InstitucionProyecto
                                                        institucion={this.state.institutions}
                                                        onChange={this._institucion_project}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-row flex-wrap mb-2 pl-2 ">
                                    <ItemGraficaProyecto
                                        data={proyectos.data_proyectos}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modal_open}
                    className="mx-lg-auto mx-md-3 mx-2"
                >
                    <ModalHeader
                        toggle={this.toggleModal}
                        style={{ backgroundColor: "white", borderRadius: 8 }}
                    >
                        {this.state.type_modal != 0 && this.state.type_modal == 1
                            ? "Actividades Realizadas"
                            : this.state.type_modal != 0 && this.state.type_modal == 2
                            ? "Insumos entreados"
                            : "Insumos entregados por proyecto"}
                    </ModalHeader>
                    <ModalBody className="car mt-3">
                        {this.state.type_modal != 0 && this.state.type_modal == 1 ? (
                            <TablaActividades
                                actividades={actividades}
                                onSortChange={onSortChange}
                                loading={loader}
                                onPageChange={this.props.dashboard_actividades}
                                page={page}
                                //filtros
                                filtrarMunicipio={filtrarMunicipio}
                                filtrarComunidades={filtrarComunidades}
                                municipios={municipios}
                                comunidades_filtradas={comunidades_filtradas}
                                filtro_departamento={filtro_departamento}
                                filtro_municipio={filtro_municipio}
                                filtro_comunidad={filtro_comunidad}
                                filtro_genero={filtro_genero}
                                filtro_institucion={filtro_institucion}
                                institucion={this.state.institutions}
                                filtro_desde={filtro_desde}
                                filtro_hasta={filtro_hasta}
                            />
                        ) : this.state.type_modal != 0 && this.state.type_modal == 2 ? (
                            <TablaInsumos
                                insumos={insumos}
                                onSortChange={onSortChange}
                                loading={loader}
                                onPageChange={this.props.dashboard_insumos}
                                page={page}
                                //filtros
                                filtrarMunicipio={filtrarMunicipio}
                                filtrarComunidades={filtrarComunidades}
                                municipios={municipios}
                                comunidades_filtradas={comunidades_filtradas}
                                filtro_departamento={filtro_departamento}
                                filtro_municipio={filtro_municipio}
                                filtro_comunidad={filtro_comunidad}
                                filtro_genero={filtro_genero}
                                filtro_institucion={filtro_institucion}
                                institucion={this.state.institutions}
                                filtro_desde={filtro_desde}
                                filtro_hasta={filtro_hasta}
                            />
                        ) : (
                            <TablaProyectos
                                insumos_proyectos={proyectos}
                                onSortChange={onSortChange}
                                loading={loader}
                                onPageChange={this.props.dashboard_proyectos}
                                page={page}
                                //filtros
                                filtrarMunicipio={filtrarMunicipio}
                                filtrarComunidades={filtrarComunidades}
                                municipios={municipios}
                                comunidades_filtradas={comunidades_filtradas}
                                filtro_departamento={filtro_departamento}
                                filtro_municipio={filtro_municipio}
                                filtro_comunidad={filtro_comunidad}
                                filtro_genero={filtro_genero}
                                filtro_institucion={filtro_institucion}
                                institucion={this.state.institutions}
                                filtro_proyecto={filtro_proyecto}
                                filtro_desde={filtro_desde}
                                filtro_hasta={filtro_hasta}
                            />
                        )}
                    </ModalBody>
                </Modal>
            </LoadMask>
        );
    }
}

export default reduxForm({
    form: "dashboard_form", // a unique identifier for this form
})(Home);
