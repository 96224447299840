import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import {
    renderField,
    renderDatePicker,
    renderNumber,
    renderFieldRadio,
    renderFilePicker,
    renderTextArea,
    SelectField,
} from "Utils/renderField/renderField";
import { GENEROS } from "../../../Utils/constant";

const FormNino = ({ disabled, child, apadrinado }) => {
    let date = new Date();
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="d-flex flex-row align-items-center">
                        <label
                            className="font-weight-bold mr-5"
                            htmlFor="photo"
                        >
                            Foto
                        </label>
                        <Field
                            disabled={disabled}
                            name={`${child}.photo`}
                            component={renderFilePicker}
                            accept="image/*"
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label className="font-weight-bold" htmlFor="name">
                        Nombre:{" "}
                        {apadrinado ? (
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        ) : (
                            ""
                        )}
                    </label>
                    <Field
                        disabled={disabled}
                        name={`${child}.name`}
                        placeholder="Nombre"
                        component={renderField}
                        className="form-control"
                    />
                </div>
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label className="font-weight-bold" htmlFor="birth_date">
                        fecha nacimiento:{" "}
                        {apadrinado ? (
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        ) : (
                            ""
                        )}
                    </label>
                    <Field
                        disabled={disabled}
                        name={`${child}.birth_date`}
                        maxDate={date}
                        component={renderDatePicker}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label
                        className="font-weight-bold"
                        htmlFor="address.departament"
                    >
                        Sexo:{" "}
                        <span className="text-primary font-weight-bold">*</span>
                    </label>
                    <Field
                        disabled={disabled}
                        name={`${child}.gender`}
                        placeholder="Sexo"
                        options={GENEROS}
                        component={SelectField}
                        className="form-control"
                    />
                </div>
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label className="font-weight-bold" htmlFor="age">
                        Edad:{" "}
                        {apadrinado ? (
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        ) : (
                            ""
                        )}
                        <span className="text-primary texto-informativo">
                            (En meses)
                        </span>
                    </label>
                    <Field
                        disabled={disabled}
                        name={`${child}.age`}
                        placeholder="edad"
                        component={renderNumber}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label
                        className="font-weight-bold"
                        htmlFor="child.is_sponsored"
                    >
                        Es apadrinado:{" "}
                        {apadrinado ? (
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        ) : (
                            ""
                        )}
                    </label>
                    {apadrinado ? (
                        <fieldset className="flex d-flex">
                            <Field
                                type="radio"
                                disabled={disabled}
                                name={`${child}.is_sponsored`}
                                label="Si"
                                value="si"
                                is_checked={true}
                                component={renderFieldRadio}
                            />
                        </fieldset>
                    ) : (
                        <fieldset className="flex d-flex">
                            <Field
                                disabled={disabled}
                                type="radio"
                                name={`${child}.is_sponsored`}
                                label="Si"
                                value="si"
                                component={renderFieldRadio}
                            />
                            <div className="px-2" />
                            <Field
                                disabled={disabled}
                                type="radio"
                                name={`${child}.is_sponsored`}
                                label="No"
                                value="no"
                                component={renderFieldRadio}
                            />
                        </fieldset>
                    )}
                </div>
                <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                    <label
                        className="font-weight-bold"
                        htmlFor="child.disability"
                    >
                        Discapacidad:{" "}
                        {apadrinado ? (
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        ) : (
                            ""
                        )}
                    </label>
                    <fieldset className="flex d-flex">
                        <Field
                            disabled={disabled}
                            type="radio"
                            name={`${child}.disability`}
                            label="Si"
                            value="si"
                            component={renderFieldRadio}
                        />
                        <div className="px-2" />
                        <Field
                            disabled={disabled}
                            type="radio"
                            name={`${child}.disability`}
                            label="No"
                            value="no"
                            component={renderFieldRadio}
                        />
                    </fieldset>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-4 form-group has-feedback">
                    <label className="font-weight-bold" htmlFor="description">
                        Observaciones:
                    </label>
                    <Field
                        disabled={disabled}
                        name={`${child}.observation`}
                        component={renderTextArea}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default FormNino;
