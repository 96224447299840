import React, { Component } from "react";
import Grid from "Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";

class TablaBeneficiarios extends Component {
    
    render() {
        const {
            data,
            loader,
            page,
            onPageChange,
            onSortChange,            
            detalle,
        } = this.props;
        const tipo_bene = (tipo) => {
            if (tipo == 10) {
                return "Mujer";
            } else if (tipo == 20) {
                return "Hombre";
            } else if (tipo == 30) {
                return "Niño apadrinado";
            } else if (tipo == 40) {
                return "Joven Beneficiario";
            } else if (tipo == 50) {
                return "Joven voluntario";
            } else if (tipo == 60) {
                return "Sin asignar";
            }
        };        
        return (
            <div className="card">
                <React.Fragment>
                    <div className="card card-small">
                        <div className="p-0">
                            <Grid
                                hover
                                striped
                                data={data}
                                loading={loader}
                                onPageChange={onPageChange}
                                onSortChange={onSortChange}
                                page={page}
                            >
                                <TableHeaderColumn
                                    width="8%"
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    dataFormat={standardActions({
                                        detalle: (id) => {
                                            detalle(
                                                data.results.find((e) => e.id == id)
                                            );
                                        },
                                        //eliminar: remove,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    width="18%"
                                    dataField="institution"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return `${cell.name}`;
                                    }}
                                >
                                    Institución
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="type_beneficiary"
                                    dataSort
                                    dataFormat={(cell) => {
                                        return tipo_bene(cell);
                                    }}
                                >
                                    Tipo Beneficiario
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    isKey
                                    dataField="name"
                                    dataSort
                                >
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="birth_date"
                                    dataSort
                                >
                                    Fecha De Nacimiento
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

export default TablaBeneficiarios;
