import React, { Component } from 'react';
import InstitucionForm from './InstitucionForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

class Institution extends Component{
    constructor(props){
        super(props);

    }
    state={
        create: true,
    };

    componentWillMount(){
        //obtenemos esta funcion de los props, está en redux.
        const {getInstitution, match} = this.props;
        const id = match.params.id;
        //si encontramos el id en loa url, entonces se va a editar el registro
        if (id) {
            this.setState({create: false});
            getInstitution(id);
        }
    }
    render(){
        const { create } = this.state;
        const {loader, registerInstitution, updateInstitution} = this.props;
        let action = {}
        let disabled = false
        if (window.location.href.includes('editar'))
            action = updateInstitution
        else if (window.location.href.includes("crear"))   
            action = registerInstitution;
        else
            disabled = true
        
        return(
            <div className="card my-4">
                <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={loader} blur>
                        <InstitucionForm                               
                            create={create}  
                            onSubmit={action} 
                            disabled={disabled}                           
                        />
                    </LoadMask>
                </div>
            </div>
        )
    };


}

export default Institution;