import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/bitacoras";
import ListBitacoras from "./Listar";
import BitacoraDetail from "./Detalle";

const ms2p = (state) => {
    return {
        ...state.bitacora,
    };
};

const md2p = {
    ...actions
};

export const ListarBitacoras = connect(ms2p, md2p)(ListBitacoras);

export const DetalleBitacora = connect(ms2p, md2p)(BitacoraDetail);