import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = "SUBMIT";
const LOADER = "LOADER";

const ITEM_DATA = 'ITEM_SETTINGS';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});


// ------------------------------------
// Actions
// ------------------------------------

export const listar = () => (dispatch,) => {
    dispatch(setLoader(true));
    api.get("/configuracion/raw")
        .then((response) => {            
            dispatch({ type: ITEM_DATA, item: response });
            dispatch(initializeForm("settingsForm", response));
        })
        .catch((error) => {
            let msj =
                "Error al intentar obtener el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const update = () => (dispatch,getStore) => {
    let formData = getStore().form.settingsForm.values;
    const id = formData.id;
    dispatch(setLoader(true));
    api.put(`/configuracion/${id}/update_settings`, formData)
        .then((response) => {
            NotificationManager.success(
                "Configuración guardada",
                "ÉXITO",
                5000
            );
            dispatch(listar());
        })
        .catch((error) => {
            let msj =
                "Error al intentar guardar la configuración, vuelva a intentar ";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const actions = {
    listar,
    update
};

export const reducers = {
   
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    

};

export default handleActions(reducers, initialState);
