import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/beneficiarios";
import ListarBeneficiarios from "./BeneficiariosList";

const ms2p = (state) => {
    return {
        ...state.beneficiarios,
        me: state.login.me,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ListarBeneficiarios);
