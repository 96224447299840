import React, { Component, Fragment } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

class DetalleBeneficiarioInstitucion extends Component {
    state = {
        disabled: false,
    };
   /*  componentDidMount() {
        const { beneficiario, nuevosConIsntitucion } = this.props;
        //si el id del beneficiario ya esta agregado a l alista desabilitamos las acciones
        nuevosConIsntitucion.forEach((value, index) => {
            if (value.id == beneficiario.id) {
                this.setState({ disabled: true });
            }
        });
    } */
    render() {
        const { onClose, beneficiario, loader, nuevaInstitucion } = this.props;
        //console.log("esta es la data del benficiario actual", item);
        return (
            <React.Fragment>
                <LoadMask loading={loader} dark blur>
                    <div className="p-4">
                        <div>
                            <h5>Nuevo</h5>
                            <div className="dropdown-divider"></div>
                            <div className="row justify-content-md-around mx-md-0 ">
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Institución:
                                    </label>
                                    <label className="d-block">
                                        {beneficiario.institution.name}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Nombre:
                                    </label>
                                    <label className="d-block">
                                        {beneficiario.name}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        DPI:
                                    </label>
                                    <label className="d-block">
                                        {beneficiario.dpi}
                                    </label>
                                </div>
                                <div className="p-1">
                                    <label className="font-weight-bold d-block">
                                        Fecha:
                                    </label>
                                    <label className="d-block">
                                        {beneficiario.birth_date}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mx-3 mx-md-0 ">
                        <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-4">
                            <button
                                className="btn btn-outline-black w-100"
                                onClick={onClose}
                            >
                                Cancelar
                            </button>
                        </div>

                        <div className="col-12 col-md-3 col-lg-3 mb-3 mb-md-4">
                            <button
                                className="btn btn-outline-primary w-100"
                                disabled={this.state.disabled}
                                onClick={() => {
                                    nuevaInstitucion(beneficiario.id);
                                    //onClose();
                                }}
                            >
                                Generar nueva institución
                            </button>
                        </div>
                    </div>
                </LoadMask>
            </React.Fragment>
        );
    }
}
export default DetalleBeneficiarioInstitucion;
