import { handleActions } from "redux-actions";
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import { getCommunities } from '../beneficiarios'

const LOADER = "LOADER";
const USERS = "SET_USERS";
const USER = "SET_USER";
const PAGE = "PAGE_INSTITUTION";
const ORDERING = "ORDERING_INSTITUTION";
const SEARCH = "SEARCH_INSTITUTION";

const ROLE = "SET_ROLE";
const INSTITUTION = "SET_INSTITUTION";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setUser = (user) => ({
    type: USER,
    user,
});

const setUsers = (users) => ({
    type: USERS,
    users,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});
const setRole = (role) => ({
    type: ROLE,
    role,
});
const setInstitution = (institution) => ({
    type: INSTITUTION,
    institution,
});

const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().usuariosAdmin;
    const params = { page };

    params.ordering = resource.ordering;
    params.search = resource.search;
    params.role = resource.role;
    params.institution = resource.institution;

    dispatch(setLoader(true));
    // dispatch(cleanData())
    api.get("user", params)
        .then((response) => {
            if (response) {
                dispatch(setUsers(response));
                dispatch(setPage(page));
                dispatch(getCommunities())
            }
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(
                "ocurrio un error al listar los usuarios",
                "ERROR",
                5000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const createUser = (role) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const userData = getStore().form.form_usersAdmin.values;

    if (!userData.profile) {
        NotificationManager.error(
            "Selecciona una institución valida",
            "ERROR",
            0
        );
        dispatch(setLoader(false));
        return 0;
    }

    userData.password = "this_password_is_fake";
    userData.profile.role = role;

    api.post("user", userData)
        .then((res) => {
            console.log("RES", res);
            if (res.error) {
                NotificationManager.warning(res.error, "PROBLEMA", 5000);
            }
            NotificationManager.success(
                "Usuario creado con éxito",
                "Éxito",
                3000
            );
            dispatch(goBack());
        })
        .catch((e) => {
            if (e.username)
                NotificationManager.error(
                    "Ya existe un usario con este username",
                    "ERROR",
                    0
                );
            else if (e.email) NotificationManager.error(e.email, "ERROR", 0);
            else NotificationManager.error(e.detail, "ERROR", 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const updateUser = (id) => (dispatch, getStore) => {
    const userData = getStore().form.form_usersAdmin.values;

    if (userData.passwrd && userData.passwrd_confirm) {
        if (userData.passwrd !== userData.passwrd_confirm) {
            NotificationManager.warning(
                "Las contraseñas no coinciden",
                "ADVERTENCIA",
                5000
            );
            return 0;
        }
    }

    dispatch(setLoader(true));
    api.put(`user/${id}/`, userData)
        .then(() => {
            NotificationManager.success(
                "Usuario modificado con éxito",
                "Éxito",
                3000
            );
            dispatch(goBack());
        })
        .catch((e) => {
            NotificationManager.error(e.detail, "ERROR", 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getUser = (id) => (dispatch) => {
    api.get(`user/${id}/`)
        .then((user) => {
            dispatch(initializeForm("form_usersAdmin", user));
            dispatch(setUser(user));
        })
        .catch((e) => {
            NotificationManager.error(e.detail, "ERROR", 0);
        })
        .finally(() => {});
};

export const deleteUser = (id) => (dispatch) => {
    api.eliminar(`user/${id}`)
        .then((res) => {
            NotificationManager.success(
                "Usuario eliminado con exito",
                "Éxito",
                3000
            );
            dispatch(listar());
        })
        .catch((e) => {
            NotificationManager.error(e.detail, "ERROR", 0);
        })
        .finally(() => {});
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().usuariosAdmin.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const listarUsuarios = (page, role, institution) => (
    dispatch,
    getStore
) => {
    // dispatch(cleanData())
    dispatch(setRole(role));
    if (institution) dispatch(setInstitution(institution));
    dispatch(setSearch(""));
    dispatch(listar(page));
};

export const refreshData = () => (dispatch) => {
    dispatch(
        setUsers({
            results: [],
            count: 0,
        })
    );
    setTimeout(() => {
        dispatch(listar());
    }, 1);
};

export const actions = {
    listar,
    getUser,
    createUser,
    updateUser,
    deleteUser,

    searchChange,
    onSortChange,

    listarUsuarios,
    refreshData,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [USER]: (state, { user }) => {
        return {
            ...state,
            user,
        };
    },
    [USERS]: (state, { users }) => {
        return {
            ...state,
            users,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ROLE]: (state, { role }) => {
        return {
            ...state,
            role,
        };
    },
    [INSTITUTION]: (state, { institution }) => {
        return {
            ...state,
            institution,
        };
    },
};

export const initialState = {
    loader: false,
    users: {
        results: [],
        count: 0,
    },
    user: {},
    page: 1,
    ordering: "",
    search: "",
    role: -1,
    institution: -1,
    roles: {
        SUPERADMIN: 10,
        ADMIN: 20,
        TECHNICAL: 30,
    },
};

export default handleActions(reducers, initialState);
