import React, { Component } from "react";
import "./FileCustomUploader.css";

const defaultProps = {
    baseColor: "gray",
    activeColor: "green",
    overlayColor: "rgba(255,255,255,0.3)",
    opacity: 0,
};

class FileCustomUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: "",
            loaded: false,
        };

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    componentDidUpdate() {
        if (
            this.state.imageSrc === "" &&
            this.props.source !== undefined &&
            this.props.source !== null
        ) {
            this.setState({ imageSrc: this.props.source, loaded: true });
        }
    }

    componentWillMount() {
        if (this.props.img !== null && this.props.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
                isImage: true,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.img !== null && nextProps.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: nextProps.img,
                loaded: true,
            });
        }
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }
    isValid = (file_type) => {
        //console.log("file type: ", file_type);
        const {accept="-*"} = this.props;
        const pattern = accept == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? accept : (accept !== "-*" ? accept.replace('/','-') : accept);
        const accept_type = pattern.split(",")
        let aprobado = false;        
        accept_type.forEach(t=>{            
            const regex = new RegExp(t);            
           // console.log("regex: ", regex);            
            if(!!file_type.match(regex)){                
                aprobado = true;         
            }
        });        
        return aprobado;
    }

    /* onFileChange(e, file) {
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        const pattern = /-*/;
        /*const imagePattern = /image-*/;

      /*  const reader = new FileReader();
        if (file) {
            const isImage = !!file.type.match(imagePattern);
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }

            this.setState({ loaded: false });

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true,
                });
            };
            reader.readAsDataURL(file);
        }
    } */
    onFileChange(e, file) {
        const { accept = '/*' } = this.props;
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        // const pattern = /-*/;
        const pattern = `${accept}`;
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file){            
            //console.log('Tipo:', file.type);
            const isImage = !!file.type.match(imagePattern);
            /* if (!file.type.match(pattern)) { */
            if (!this.isValid(file.type)) {
                alert('Formato inválido');                
                return;
            }
            
            this.setState({ loaded: false });

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true
                });
            };
            reader.readAsDataURL(file);
        }
    }
    

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        const { accept = "/*", disabled } = this.props;
        let state = this.state,
            props = defaultProps,
            labelClass = `uploader ${state.loaded && "loaded"}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor = state.active
                ? props.activeColor
                : state.loaded
                ? props.overlayColor
                : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <label
                id="custom-file-uploader"
                className={labelClass}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
            >
                <img
                    src={
                        state.isImage
                            ? state.imageSrc
                            : require("assets/img/uploaded.png")
                    }
                    className={state.loaded ? "loaded" : undefined}
                />
                <img
                    style={{ color: iconColor, opacity: hideIcon }}
                    className="icon icon-upload"
                    src={require(`assets/img/avatar-placeholder.png`)}
                    alt=""
                />

                {!disabled && (
                    <div className="camera-container">
                        <img
                            style={{ color: iconColor, opacity: 1 }}
                            className="icon cameraIcon"
                            src={require(`assets/img/camera.png`)}
                            alt=""
                        />
                    </div>
                )}

                <input
                    disabled={disabled}
                    type="file"
                    accept={accept}
                    onChange={this.onFileChange}
                    ref="input"
                />
            </label>
        );
    }
}
export default FileCustomUploader;
