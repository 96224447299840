import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from 'moment'

// export const createReducer = (storeId, endpoint, formName=undefined, resourceList=undefined) => {

// ------------------------------------
// Constants
// ------------------------------------

const storeId = 'projects'
const endpoint = 'projects'
const formName = 'ProjectForm'
const resourceList = '/proyectos'


const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
    INSTITUCION: `${storeId.toUpperCase()}_INSTITUCION`,
    DATESTART: `${storeId.toUpperCase()}_DATESTART`,
    DATEEND: `${storeId.toUpperCase()}_DATEEND`,
    FILTERS: `${storeId.toUpperCase()}_FILTERS`,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setData = (data) => ({
    type: constants.DATA,
    data,
});

const setItem = (item) => ({
    type: constants.ITEM,
    item,
});

const setPage = (page) => ({
    type: constants.PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: constants.SEARCH,
    search,
});
const setInstitucion = (institucion) => ({
    type: constants.INSTITUCION,
    institucion,
});
const setDateStart = (date_start) => ({
    type: constants.DATESTART,
    date_start,
});
const setDateEnd = (date_end) => ({
    type: constants.DATEEND,
    date_end,
});
const setFilters = (filters) => ({
    type: constants.FILTERS,
    filters,
});

// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1, extras = undefined) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page, ...extras };
    params.ordering = resource.ordering;
    params.search = resource.search;

    resource.institucion ? params.institutions = resource.institucion : null;
    resource.date_start ? params.date_start = resource.date_start : null;
    resource.date_end ? params.date_end = resource.date_end : null;


    dispatch(setLoader(true));
    api.get(endpoint, params)
        .then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
            dispatch(extractfilters());
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .then((response) => {
            if (response.date_start) response.date_start = new Date(response.date_start)
            if (response.date_end) response.date_end = new Date(response.date_end)

            dispatch(setItem(response));
            if (!!formName) dispatch(initializeForm(formName, response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const crear = (data) => (dispatch) => {
    dispatch(setLoader(true));

    if (data.date_start) data.date_startf = moment(data.date_start).format('YYYY-MM-DD')
    if (data.date_end) data.date_endf = moment(data.date_end).format('YYYY-MM-DD')

    api.post(endpoint, data)
        .then(() => {
            NotificationManager.success("Registro creado", "Éxito", 3000);
            if (!!resourceList) dispatch(push(resourceList));
        })
        .catch(() => {
            NotificationManager.error("Error en la creación", "ERROR");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (data) => (dispatch) => {
    dispatch(setLoader(true));
    if (data.date_start) data.date_startf = moment(data.date_start).format('YYYY-MM-DD')
    if (data.date_end) data.date_endf = moment(data.date_end).format('YYYY-MM-DD')

    api.put(`${endpoint}/${data.id}`, data)
        .then(() => {
            NotificationManager.success("Registro actualizado", "Éxito", 3000);
            if (!!resourceList) dispatch(push(resourceList));
        })
        .catch(() => {
            NotificationManager.error("Error en la edición", "ERROR", 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch(() => {
            NotificationManager.success(
                "Error en la transacción",
                "Éxito",
                3000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const onApplyFilters = (data, type) => (dispatch, getStore) => {
    if(type === 'INSTITUCION') dispatch(setInstitucion(data))
    else if(type === 'DATESTART') dispatch(setDateStart(data? moment(data).format('YYYY-MM-DD'):null))
    else if(type === 'DATEEND') dispatch(setDateEnd(data? moment(data).format('YYYY-MM-DD'):null))

    dispatch(listar())
};

const onDeleteFilters = (page) => (dispatch, getStore) => {
    dispatch(setInstitucion(null));
    dispatch(setDateStart(null));
    dispatch(setDateEnd(null));
    dispatch(listar(page));
};

export const extractfilters = (data) => (dispatch) => {
    api.get("/activities/filtros")
        .then((response) => {
            dispatch(setFilters(response))
        })  
        .catch((error) => {
            console.log(error);
        })
};

export const descarga_excel = () => (dispatch, getStore) => {
    // priemtro sacamos los paramtros para filtrar
    const resource = getStore()[storeId];
    const params = new Object(); 
    
    resource.institucion ? params.institutions = resource.institucion : null;
    resource.date_start ? params.date_start = resource.date_start : null;
    resource.date_end ? params.date_end = resource.date_end : null;

    api.get("projects/download_excel", params)
        .then((response) => {
            if (response && response.id_descarga) {
                console.log(response, "DOWNLOAD");
                dispatch(verifyDownload(response.id_descarga));
            }
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                error.detail ||
                    "Ha ocurrido un error, por favor vuelva a intentar.",
                "ERROR",
                0
            );
        });
};
const verifyDownload = (id_descarga) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("projects/verify_download", { id_descarga })
        .then((response) => {
            const status = response.staus_download;
            console.log("Estado de descarga", status);
            if (status === "waiting") {
                setTimeout(() => {
                    dispatch(verifyDownload(id_descarga));
                }, 1000);
            } else if (status === "finished") {
                console.log("Este es el response", response);
                window.location.replace(response.file_excel.file_excel);
                dispatch(setLoader(false));
                //despues se elimina el archivo
                dispatch(deleteFileExcel(id_descarga));
            }
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                "Ocurrió al intentar descargar el archivo",
                "ERROR",
                0
            );
            dispatch(setLoader(false));
        });
};

const deleteFileExcel = (id) => () => {
    api.eliminar(`projects/${id}/delete_file_excel`)
        .then((response) => {
            console.log("Borro la descarga", response);
        })
        .catch((error) => {
            console.log("Error:", error);
            NotificationManager.error(
                "Ocurrió un error eliminar el archivo de excel",
                "ERROR",
                0
            );
        });
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    onApplyFilters,
    descarga_excel,
    onDeleteFilters
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.FILTERS]: (state, { filters }) => {
        return {
            ...state,
            filters,
        };
    },
    [constants.DATESTART]: (state, { date_start }) => {
        return {
            ...state,
            date_start,
        };
    },
    [constants.DATEEND]: (state, { date_end }) => {
        return {
            ...state,
            date_end,
        };
    },
    [constants.INSTITUCION]: (state, { institucion }) => {
        return {
            ...state,
            institucion,
        };
    },
};

const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: "",
    search: "",
    institucion: "",
    date_start: "",
    date_end: "",
    filters: {
        instituciones: [],
        actividades: [],
        tecnicos: []
    },
};

export default handleActions(reducers, initialState);