import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login';
import recoverPass from './modules/cuenta/recoverPass';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import institucion from './modules/instituciones/institucion';
import usuariosAdmin from './modules/usuarios/usuariosAdmin';
import gl_settings from './modules/global_settings/gl_settings'
import activities from "./modules/activities/activities";
import beneficiarios from './modules/beneficiarios'
import bitacora from './modules/bitacoras'
import asignaciones from './modules/asignaciones'
import comunidades from './modules/comunidades'
import reportesActividad from './modules/reportes/Actividades'
import projects from './modules/projects'
import dashboard from './modules/dashboard';
import asignacionesProyectos from './modules/asignacionesProyecto'

export default combineReducers({
    form: formReducer,
    login,
    recoverPass,
    profile,
    usuarios,
    routing,
    notificaciones,
    institucion,
    usuariosAdmin,
    activities,
    gl_settings,
    beneficiarios,
    bitacora,
    asignaciones,
    comunidades,
    reportesActividad,
    projects,
    dashboard,
    asignacionesProyectos,
});
