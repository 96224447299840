import React, {Component, Fragment} from 'react';
import Carnet2 from "./carnet2";
import ReactToPrint, { PrintContextConsumer} from "react-to-print";
import "./carnet_styles.css";

class CarnetsMasivos extends Component{
    componentDidMount(){
        /* if(this.props.is_open==true){
            document.getElementById('print-button').click();        
        } */
        //console.log("Esta es la data de los carnets", this.props.data_para_carnets);
        if(this.props.data_para_carnets!=undefined && this.props.data_para_carnets.length > 0){
            document.getElementById('print-button').click(); 
            setTimeout(() => {
                this.props.finalizar_loader();
            }, 4000);
        
        }
        this.props.open_modal(false);
        
    } 
    componentWillUnmount(){
        //console.log("vacia la data cuando sale--------")
        this.props.vaciar_data_carnets();
    }
    render(){
        const {data_para_carnets}=this.props;        
        //console.log("Tamño del array",data_para_carnets)
        return(
        <Fragment>   
            <ReactToPrint
                content={() => this.componentRef}
                trigger={() => {
                    return <a className="d-none" id="print-button"  >Print</a>;
                }}
            / >      
            <div id="c-container" className="mx-5"  style={{ margin: "0", padding: "0" }} ref={(el) => (this.componentRef = el)}>                                                      
                <div className="row">                  
                    {data_para_carnets.map((item, index) => (       
                        <Fragment>
                        <div id="page-break" />
                            <Carnet2
                                key={item.id}
                                item={item}                                
                                masivo={true}
                                
                            />
                        </Fragment>                                    

                    ))}
                </div>
            </div>
        </Fragment>
        );
    }
}
export default CarnetsMasivos;