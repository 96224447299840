import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = "INSTITUTION_LOADER";
const SET_INSTITUTION_REGISTER = "SET_INSTITUTION_REGISTER";


const DATA = 'DATA_INSTITUTION';
const ITEM_DATA = 'ITEM_INSTITUTION';
const PAGE = 'PAGE_INSTITUTION';
const ORDERING = 'ORDERING_INSTITUTION';
const SEARCH = 'SEARCH_INSTITUTION';
// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = (item) => ({
    type: ITEM,
    item,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});
// ------------------------------------
// Actions
// ------------------------------------
export const registerInstitution = (data = {}) => (dispatch, getStore) => {
    console.log(data);
    dispatch(setLoader(true));
    api.post("/institution/", data)
        .then((response) => {
            NotificationManager.success(
                "Institución resgistrado exitosamente",
                "Éxito",
                3000
            );
            dispatch(push("/institucion"));
        })
        .catch((error) => {
            let msj =
                "Error al intentar registrar la institución, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const updateInstitution = () => (dispatch, getStore) => {
    let formData = getStore().form.newInstitution.values;
    const id = formData.id;
    dispatch(setLoader(true));
    api.put(`/institution/${id}/update_institution`, formData)
        .then((response) => {
            NotificationManager.success(
                "Intitucion Actualizado",
                "ÉXITO",
                5000
            );
            dispatch(push("/institucion"));
        })
        .catch((error) => {
            let msj =
                "Error al intentar actualizar el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const deleteInstitution = (id) => (dispatch) => {
    api.eliminar(`/institution/${id}/delete_institution`)
        .then(() => {            
            NotificationManager.success(
                "Institución eliminada exitosamente",
                "Éxito",
                3000
            );
            dispatch(listar());
        }).catch((error) => {            
            if (error) {
                console.log("Entro en el cathc", error);
                let msj =
                    "Error al intentar eliminar el registro, vuelva a intentar";
                if (error.detail) {
                    msj = error.detail;
                }
                NotificationManager.error(msj, "ERROR", 5000);
            }
            
        });
};

export const getInstitution = (id) => (dispatch) => {
    api.get(`institution/${id}/retrieve_institution/`)
        .then((response) => {
            dispatch({ type: SET_INSTITUTION_REGISTER, register: response });
            dispatch(initializeForm("newInstitution", response));
        })
        .catch((error) => {
            let msj =
                "Error al intentar obtener el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().institucion;    
    const params = { page };      
    params.ordering = 'name';
    params.search = resource.search;   
    //console.log('paramas: ',params); 

    dispatch(setLoader(true));
    api.get("/institution", params)
        .then((response) => {
            if (response) {               
                dispatch(setData(response));
                dispatch(setPage(page));      
         }
        })
        .catch((error) => {
            let msj = "Ocurrio un error al listar las intituciones";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        }); 
};

const searchChange = (search) => (dispatch) => {
    console.log('data desde el search: ',search);
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().institution.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};


export const actions = {
    registerInstitution,
    updateInstitution,
    deleteInstitution,
    getInstitution,
    listar,
    searchChange,
    onSortChange,
   
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SET_INSTITUTION_REGISTER]: (state, { register }) => {
        return {
            ...state,
            register,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,  
    register: null,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
