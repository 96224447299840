import { api } from "api";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";

export default class HeaderCarnet extends Component {
    render() {
        const { filtro1, filtro2, filtro3 } = this.props;   
        const {
            search,
            to,
            to2,
            header,
            textBtn,
            textBtn2,
            disabledBtn2,
            btn2Click,
            placeFiltro1,
            placeFiltro2,
            placeFiltro3,
            carnets
        } = this.props; 
        return (
            <div className="d-flex w-100 px-0 ">
                <div className="mb-1 d-flex flex-1 flex-column flex-lg-row col-12 px-0  flex-md-row flex-wrap">
                    {filtro1 && (                        
                        <div className=" d-flex flex-column flex-2 mx-1 px-0 mb-2 mb-lg-0  ">
                             <label className="font-weight-bold mb-0">
                                {placeFiltro1 ? placeFiltro1:""}
                                
                            </label>
                            {filtro1}
                        </div>
                    )}
                    {filtro2 && (
                        <div className=" d-flex flex-column flex-2 mx-1 px-0 mb-2 mb-lg-0 ">
                            <label className="font-weight-bold mb-0">
                               {placeFiltro2 ? placeFiltro2:""}
                               
                           </label>
                            {filtro2}
                        </div>
                    )}
                    {filtro3 && (
                        <div className=" d-flex flex-column flex-2 mx-1 px-0 mb-2 mb-lg-0">
                            <label className="font-weight-bold mb-0">
                               {placeFiltro3 ? placeFiltro3:""}                               
                           </label>
                            {filtro3}
                        </div>
                    )}
                    <div
                        className={`d-flex flex-row align-items-center justify-content-start justify-content-lg-start flex-2 px-0 ${
                            to !== undefined ||
                            to2 !== undefined ||
                            textBtn2 !== undefined ||
                            header !== undefined
                                ? ""
                                : "d-sm-none d-md-none d-lg-inline"
                        }`}
                    >
                        {header !== undefined && (
                            <h6 className="pl-4 m-0 p-0">{header}</h6>
                        )}
                         {(to !== undefined) && (
                            <Link to={to} className="btn btn-primary m-1 align-self-center px-5 font-weight-bold">{textBtn}</Link>
                        )}
                        {/* {(to2 !== undefined) && (
                           // <button onClick={btn2Click} disabled={disabledBtn2 ? disabledBtn2 : false} className="btn btn-secondary ml-2 mb-3">{textBtn2}</button> 
                           <Link to={to2} className="btn btn-primary m-1 align-self-center px-5 font-weight-bold">{textBtn2}</Link>
                        )} */}
                        {textBtn2 !== undefined && (
                            <button
                                onClick={btn2Click}
                                disabled={disabledBtn2 ? disabledBtn2 : false}
                                className={`btn ${carnets?"btn-primary":"btn-success"} btn-success m-1 align-self-center px-5 font-weight-bold mt-4`}
                            >
                                {textBtn2}
                            </button>
                        )}
                       
                    </div>
                  
                </div>
            </div>
        );
    }
}
