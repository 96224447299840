import React, { Component, Fragment } from "react";
import "./style.css";

class Politicas extends Component {
    render() {
        return (
            <Fragment>
                <div id="fondo-politicas"></div>
                <div className="container px-0 ">
                    <div className="sticky-top">
                        <nav className="nav-2 mt-4 px-4 flex-md-nowrap navbar-2 ">
                            <nav className="nav">
                                <img
                                    id="main-logo-privacidad"
                                    className="d-inline-block align-top mr-1"
                                    src={require("assets/img/mini-logo-app2.png")}
                                    alt="Logo"
                                />
                            </nav>

                            <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                                <div className="ml-3 input-group input-group-seamless">
                                    <div className="row  ">
                                        <div className="col-12 align-self-center text-white">
                                            Politicas de Privacidad{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="main-content-container">
                        <div className="card my-4">
                            <div className="py-4 my-2 mx-auto px-4">
                                <h1 className="text-align: center;">
                                    Política de Privacidad
                                </h1>
                                <p>
                                    La presente política de privacidad (en
                                    adelante, la "
                                    <strong>Política de Privacidad</strong>")
                                    aplica a todos los datos de carácter
                                    personal que aporte a la Fundación AYUDA EN
                                    ACCIÓN, con domicilio en Calle Serrano
                                    Anguita 13, Impact Hub Barceló 28004, Madrid
                                    (en adelante "
                                    <strong>Ayuda en Acción</strong>"). Se
                                    entienden por datos personales aquella
                                    información sobre una persona física que
                                    permite su identificación, de forma directa
                                    o indirecta. <br />
                                    <br />
                                    Tratamos sus datos personales, en base a la
                                    relación actual que mantienes con nosotros,
                                    y que podrán haber sido aportados a través
                                    de diferentes medios, tales como, entre
                                    otros, un formulario en la Web, la
                                    participación en alguna de nuestras campañas
                                    y la solicitud a recibir información
                                    electrónica sobre nuestra actividad. <br />
                                    En esta política te explicamos cómo Ayuda en
                                    Acción protege y garantiza la seguridad de
                                    sus datos personales, haciendo uso para ello
                                    de medidas de seguridad técnicas y
                                    organizativas apropiadas y adecuadas: <br />
                                    <br />
                                </p>
                                <h3>I- RESPONSABLE DEL TRATAMIENTO</h3>
                                <p>
                                    Ayuda en Acción, como{" "}
                                    <strong>responsable del tratamiento</strong>
                                    , se preocupa por el cumplimiento de las
                                    obligaciones legales en materia de
                                    protección de datos de carácter personal, en
                                    especial, cumpliendo con nuestras
                                    obligaciones de información. Además, estamos
                                    a su disposición para cualquier consulta o
                                    inquietud que le pueda surgir al respecto.{" "}
                                    <br />
                                    <br />
                                    En todo momento, podrá ponerse en contacto
                                    con nosotros o bien a través del correo
                                    electrónico lopd@ayudaenaccion.org o por
                                    correo postal enviando una comunicación
                                    dirigida al Responsable de Datos Personales
                                    de Ayuda en Acción a la Calle Serrano
                                    Anguita 13, Impact Hub Barceló 28004 Madrid,
                                    ante cualquier duda o necesidad que tenga en
                                    materia de protección de datos personales.{" "}
                                    <br />
                                    <br />
                                </p>
                                <h3>
                                    II- FINALIDADES DEL TRATAMIENTO Y
                                    LEGITIMACIÓN
                                </h3>
                                <p>
                                    A los efectos de lo previsto en el
                                    Reglamento 2016/679 del Parlamento Europeo y
                                    del Consejo de 27 de abril de 2016 relativo
                                    a la protección de las personas físicas en
                                    lo que respecta al tratamiento de datos
                                    personales y a la libre circulación de estos
                                    datos y por el que se deroga la Directiva
                                    95/46/CE ('<strong>RGPD</strong>'), le
                                    informamos que Ayuda en Acción tratará los
                                    datos de carácter personal que nos haya
                                    facilitado para las siguientes finalidades y
                                    bajo la base legal que le detallamos a
                                    continuación:
                                </p>
                                <table className="table table-politicas">
                                    <thead>
                                        <tr>
                                            <th>Si usted es...</th>
                                            <th>
                                                Finalidad para la cual tratamos
                                                sus datos personales
                                            </th>
                                            <th>
                                                Base legal para tratar sus datos
                                                personales
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>
                                                Candidato (demandante de empleo)
                                            </td>
                                            <td>
                                                Gestionar el proceso de
                                                selección al que ha optado y así
                                                poder evaluar su candidatura,
                                                así como para otras que pudieran
                                                abrirse en el futuro.
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente, a excepción
                                                de Perú en cuyo caso se requiere
                                                su consentimiento para el
                                                tratamiento de sus datos
                                                personales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Contraparte</td>
                                            <td>
                                                Asegurar el conocimiento de la
                                                contraparte con la que Ayuda en
                                                Acción colabora, puesto que será
                                                la que nos ayudará a implementar
                                                nuestra intervención en el
                                                territorio.
                                            </td>
                                            <td>
                                                Cumplir nuestras obligaciones
                                                legales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Destinatario de nuestra
                                                intervención (beneficiario/a
                                                mayor de edad)
                                            </td>
                                            <td>
                                                Identificar a los destinatarios
                                                de nuestra intervención para
                                                adecuarla a las necesidades y
                                                contexto en el que operamos, así
                                                como llevar a cabo acciones de
                                                sensibilización y rendir cuentas
                                                a nuestros grupos de interés con
                                                fines formativos y/o de
                                                promoción de nuestra actividad.
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                                <br />
                                                Su consentimiento para el
                                                tratamiento de sus datos
                                                personales, en los siguientes
                                                casos excepcionales: i) cuando
                                                se levanten testimonios que
                                                incluyan datos sensibles, y/o
                                                ii) cuando se transmitan los
                                                datos personales de
                                                beneficiarios/as de nuestro
                                                programa de Acción Social en
                                                España a colaboradores,
                                                financieras y/o agencias de
                                                medios basados fuera de España
                                                y/o la Unión Europea y que no
                                                ofrezcan un nivel adecuado de
                                                protección en materia de
                                                privacidad y protección de datos
                                                personales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Destinatario de nuestra
                                                intervención (beneficiario/a
                                                menor de edad)
                                            </td>
                                            <td>
                                                Ídem al caso anterior. En el
                                                caso de niños/as apadrinados/as
                                                también sería gestionar y
                                                administrar su vinculación al
                                                programa de Vínculo Solidario de
                                                Ayuda en Acción.
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                                <br />
                                                Su consentimiento para el
                                                tratamiento de sus datos
                                                personales, otorgado por su
                                                padre/madre/tutor legal, en el
                                                caso de niños/as apadrinados/as.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Embajadores (prescriptores)</td>
                                            <td>
                                                <ol>
                                                    <li>
                                                        Gestionar y administrar
                                                        los embajadores de Ayuda
                                                        en Acción, esto es,
                                                        gestión de viajes y
                                                        colaboración de los
                                                        mismos.
                                                    </li>
                                                    <li>
                                                        Sensibilizar y rendir
                                                        cuentas sobre las
                                                        actividades
                                                        desarrolladas por Ayuda
                                                        en Acción.
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Empleado/a</td>
                                            <td>
                                                <ol>
                                                    <li>
                                                        Gestionar y administrar
                                                        los recursos humanos de
                                                        Ayuda en Acción, esto
                                                        es, pago de salarios y
                                                        beneficios sociales,
                                                        cobertura del seguro
                                                        médico y/o de
                                                        accidentes, gestión de
                                                        vacaciones y ausencias,
                                                        vinculación y promoción
                                                        interna, entre otros.
                                                    </li>
                                                    <li>
                                                        Acreditar tu condición
                                                        como empleado/a en
                                                        términos de idoneidad y
                                                        honorabilidad.
                                                    </li>
                                                    <li>
                                                        Sensibilizar y rendir
                                                        cuentas sobre las
                                                        actividades
                                                        desarrolladas por Ayuda
                                                        en Acción.
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente, así como
                                                aquellas vinculadas al ámbito de
                                                la seguridad y protección
                                                social, al ámbito laboral y
                                                legal, y disponer de medidas
                                                necesarias con fines de medicina
                                                preventiva o laboral y
                                                evaluación de la capacidad
                                                laboral de nuestros
                                                empleados/as.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Empresa colaboradora</td>
                                            <td>
                                                Rendir cuentas sobre su
                                                colaboración.
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Interesado/a</td>
                                            <td>
                                                Compartirle información
                                                institucional, incluso a través
                                                de correo electrónico, para
                                                mantenerte actualizado sobre
                                                nuestras actividades.
                                            </td>
                                            <td>
                                                Su consentimiento para el
                                                tratamiento de sus datos
                                                personales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Informante y/o afectado
                                                (denuncia)
                                            </td>
                                            <td>
                                                Gestionar las denuncias
                                                interpuestas a través del canal
                                                de denuncias.
                                            </td>
                                            <td>
                                                Cumplir nuestras obligaciones
                                                legales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Informante (consulta, sugerencia
                                                y queja){" "}
                                            </td>
                                            <td>
                                                Gestionar las consultas,
                                                sugerencias y quejas recibidas a
                                                través de los canales de
                                                comunicación habilitados.
                                            </td>
                                            <td>
                                                Cumplir nuestras obligaciones
                                                legales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Miembro del Patronato o Consejo
                                                Asesor
                                            </td>
                                            <td>
                                                <ol>
                                                    <li>
                                                        Gestionar su
                                                        nombramiento como
                                                        miembro del órgano al
                                                        que pertenece, así como
                                                        facilitar el buen
                                                        funcionamiento del
                                                        mismo.
                                                    </li>
                                                    <li>
                                                        Acreditar su condición
                                                        como miembro del
                                                        Patronato o Consejo
                                                        Asesor en términos de
                                                        idoneidad y
                                                        honorabilidad.
                                                    </li>
                                                    <li>
                                                        Publicar su trayectoria
                                                        profesional en la Web.
                                                    </li>
                                                    <li>
                                                        Sensibilizar y rendir
                                                        cuentas sobre las
                                                        actividades
                                                        desarrolladas por Ayuda
                                                        en Acción.
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente, así como
                                                cumplir nuestras obligaciones
                                                legales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Proveedor</td>
                                            <td>
                                                Gestionar y administrar la
                                                gestión de proveedores de Ayuda
                                                en Acción, esto es, pago de
                                                facturas.
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Socio/a</td>
                                            <td>
                                                <ol>
                                                    <li>
                                                        Gestionar su vinculación
                                                        como socio/a, esto es,
                                                        tramitar sus donativos,
                                                        enviar las
                                                        certificaciones
                                                        tributarias, conocer su
                                                        grado de satisfacción,
                                                        fidelizar su compromiso
                                                        mediante incrementos de
                                                        donativos o
                                                        participación en
                                                        acciones concretas,
                                                        entre otros.
                                                    </li>
                                                    <li>
                                                        Compartirle información
                                                        institucional, incluso a
                                                        través de correo
                                                        electrónico, para
                                                        mantenerle informado
                                                        sobre nuestras
                                                        actividades.
                                                    </li>
                                                    <li>
                                                        Dar a conocer la labor
                                                        de nuestros socios/as,
                                                        así como sensibilizar y
                                                        rendir cuentas sobre las
                                                        actividades
                                                        desarrolladas por Ayuda
                                                        en Acción.
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tercero que da de alta a un(a)
                                                socio/a
                                            </td>
                                            <td>
                                                Gestionar incidencias que
                                                pudieran surgir con la
                                                vinculación del/de la socio/a.
                                            </td>
                                            <td>
                                                Su consentimiento para el
                                                tratamiento de sus datos
                                                personales.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Voluntario/a</td>
                                            <td>
                                                <ol>
                                                    <li>
                                                        Gestionar y administrar
                                                        el voluntariado de Ayuda
                                                        en Acción.
                                                    </li>
                                                    <li>
                                                        Acreditar su condición
                                                        como voluntario/a en
                                                        términos de idoneidad y
                                                        honorabilidad.
                                                    </li>
                                                    <li>
                                                        Dar a conocer la labor
                                                        de voluntariado, así
                                                        como sensibilizar y
                                                        rendir cuentas sobre las
                                                        actividades
                                                        desarrolladas por Ayuda
                                                        en Acción.
                                                    </li>
                                                </ol>
                                            </td>
                                            <td>
                                                Cumplir las obligaciones
                                                contractuales derivadas de la
                                                relación existente, así como
                                                cumplir las obligaciones
                                                vinculadas al ámbito de la
                                                seguridad y protección social.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    En algunos casos, trataremos datos
                                    personales en relación con su imagen, para
                                    lo que se recabará su consentimiento cuando
                                    así se considere necesario y el tratamiento
                                    no se encuentre amparado en ninguna de las
                                    anteriores finalidades y bases legales.{" "}
                                    <br />
                                    <br />
                                    Ayuda en Acción pone en su conocimiento que
                                    en caso de ser usuario de nuestro website
                                    podremos crear un archivo automatizado con
                                    los datos personales que a través del mismo
                                    nos proporcione, los cuales podrían ser
                                    utilizados con la finalidad de efectuar
                                    estadísticas, la gestión de incidencias o la
                                    realización de estudios de mercado, entre
                                    otras actividades. Dicho tratamiento se
                                    basará en los intereses legítimos de Ayuda
                                    en Acción. <br />
                                    <br />
                                    La no cumplimentación de ciertos datos de
                                    carácter personal nos impedirá prestar todos
                                    aquellos servicios para los que dichos datos
                                    sean necesarios. <br />
                                    <br />
                                </p>
                                <h3>III- PLAZO DE CONSERVACIÓN DE LOS DATOS</h3>
                                <p>
                                    Conservaremos sus datos personales durante
                                    el tiempo necesario para el cumplimiento de
                                    las finalidades descritas anteriormente en
                                    función de las bases jurídicas que habilitan
                                    el tratamiento. Sin perjuicio de lo
                                    anterior, la conservación también se
                                    efectuará por el periodo que resultase
                                    necesario para la formulación, el ejercicio
                                    o la defensa de potenciales reclamaciones
                                    y/o siempre que lo permitiese la legislación
                                    aplicable. En este último supuesto, sus
                                    datos personales sólo serán tratados a
                                    efectos de acreditar el cumplimiento de
                                    obligaciones legales o contractuales a las
                                    que estamos sujetos. <br />
                                    <br />
                                </p>
                                <h3>IV.- DESTINATARIOS DE LOS DATOS</h3>
                                <p>
                                    En ningún caso comunicaremos, explotaremos
                                    tus datos personales o los destinaremos a un
                                    fin distinto del detallado anteriormente.
                                    Cabe mencionar que los datos podrán ser
                                    comunicados a los empleados y colaboradores
                                    de la Fundación AYUDA EN ACCIÓN que
                                    necesiten conocer información sobre usted
                                    para llevar a cabo labores relacionadas con
                                    las finalidades indicadas anteriormente.{" "}
                                    <br />
                                    <br />
                                    Con este fin, le informamos que sus datos
                                    personales podrán ser transferidos a otras
                                    oficinas de la Fundación AYUDA EN ACCIÓN,
                                    las cuales aplicarán las mismas medidas de
                                    seguridad técnicas y organizativa para
                                    garantizar el tratamiento adecuado de sus
                                    datos personales. En el supuesto de que
                                    desee apadrinar a niños/as residentes en
                                    México, le informamos que sus datos serán
                                    comunicados para gestionar su apadrinamiento
                                    a la entidad Ayuda en Acción de México
                                    Asociación Civil, la cual dispone de
                                    garantías suficientes. <br />
                                    <br />
                                    Algunas organizaciones que colaboran con
                                    Ayuda en Acción, tales como agencias de
                                    medios de comunicación, agencias de viajes,
                                    o financieras, entre otras, pueden tener
                                    acceso a los mismos como encargados de su
                                    tratamiento, para que así podamos llevar a
                                    cabo nuestra labor. En ocasiones este
                                    tercero puede encontrarse en países situados
                                    fuera del Espacio Económico Europeo que no
                                    ofrecen un nivel de seguridad adecuado de
                                    acuerdo al RGPD. En estos casos, firmamos un
                                    contrato por escrito entre ambas partes en
                                    el que se incluyen las cláusulas
                                    contractuales tipo según estándares
                                    aprobados por la Comisión Europea para la
                                    transferencia de información personal fuera
                                    del Espacio Económico Europeo. En todo caso,
                                    la transferencia, la conservación y la
                                    gestión que realicemos de su información
                                    personal seguirán rigiéndose por la presente
                                    Política de Privacidad. <br />
                                    <br />
                                    Igualmente le informamos que los datos
                                    relativos a su aportación serán comunicados
                                    a la Agencia Tributaria con la finalidad de
                                    tramitar sus beneficios fiscales, así como a
                                    aquellas otras autoridades de control que
                                    nos soliciten sus datos siempre que se
                                    respeten todos los preceptos previstos
                                    legalmente.
                                    <br />
                                    <br />
                                </p>
                                <h3>V.- TUS DERECHOS</h3>
                                <p>
                                    Ayuda en Acción le informa sobre la
                                    posibilidad que te asiste de ejercitar los
                                    derechos de{" "}
                                    <strong>
                                        acceso, rectificación, supresión,
                                        portabilidad y limitación del
                                        tratamiento
                                    </strong>{" "}
                                    de los datos personales que tenemos
                                    recogidos. <br />
                                    <br />
                                    De manera adicional, tendrá el derecho a{" "}
                                    <strong>oponerse</strong> al tratamiento de
                                    sus datos personales en los supuestos
                                    recogidos en la normativa de protección de
                                    datos aplicable. <br />
                                    <br />
                                    Dichos derechos podrás ejercitarlos, por ti
                                    mismos o por quien te represente, mediante
                                    solicitud escrita y firmada dirigida al
                                    Responsable de Datos Personales de Ayuda en
                                    Acción, a través de:
                                    <ul>
                                        <li>
                                            <span className="float: left">
                                                Correo electrónico:
                                                lopd@ayudaenaccion.org
                                            </span>
                                        </li>
                                        <li>
                                            <span className="float: left">
                                                Correo postal: Calle Serrano
                                                Anguita 13, Impact Hub Barceló
                                                28004 de Madrid.
                                            </span>
                                        </li>
                                    </ul>
                                    Dicha solicitud debe contener los siguientes
                                    datos: su nombre y apellidos, domicilio a
                                    efectos de notificaciones, fotocopia de tu
                                    Documento Nacional de Identidad o Pasaporte,
                                    y petición en que se concreta la solicitud.
                                    En el caso de representación, deberá probar
                                    la misma mediante documento fehaciente. Para
                                    enviar esta solicitud puede hacer uso de los
                                    modelos puestos a disposición por parte de
                                    la{" "}
                                    <u>
                                        Agencia Española de Protección de Datos
                                    </u>
                                    , los cuales pueden ser firmados
                                    electrónicamente. <br />
                                    Además de los anteriores derechos, en caso
                                    de que sea la base jurídica del tratamiento
                                    de sus datos personales, también tiene
                                    derecho a{" "}
                                    <strong>
                                        retirar el consentimiento otorgado
                                    </strong>{" "}
                                    en cualquier momento mediante el
                                    procedimiento más arriba descrito, sin que
                                    dicha retirada de consentimiento afecte a la
                                    licitud del tratamiento anterior a la
                                    retirada del mismo, y sin que en ningún caso
                                    condicione el tratamiento de datos
                                    personales efectuado en virtud de otra base
                                    legal. Ayuda en Acción podrá continuar
                                    tratando sus datos personales en la medida
                                    en que la ley aplicable lo permita. <br />
                                    <br />
                                    En general, respondemos las solicitudes en
                                    el plazo de un mes, aunque según la
                                    complejidad y el número, se podría prorrogar
                                    el plazo otros dos meses más, en cuyo caso
                                    se lo notificaremos. <br />
                                    <br />
                                    Finalmente, te recordamos que tienes derecho
                                    a{" "}
                                    <strong>
                                        presentar una reclamación ante la
                                        autoridad de control
                                    </strong>
                                    pertinente. <br />
                                    <br />
                                </p>
                                <h3>
                                    VI. MODIFICACIONES A LA POLÍTICA DE
                                    PRIVACIDAD
                                </h3>
                                <p>
                                    Ayuda en Acción se reserva el derecho de
                                    modificar su Política de Privacidad de
                                    acuerdo con la legislación aplicable en cada
                                    momento. En todo caso, podrá consultar
                                    cualquier modificación de la Política de
                                    Privacidad en nuestra página Web.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Politicas;
