import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import TablaBeneficiario from "./TablaBeneficiarios";
import PropTypes from "prop-types";
import Detalle from "./detalle";
import Detalleinstitucion from "./detalle_institucion";
import { Button } from "bootstrap";

class ListarBeneficiarios extends Component {
    state = {
        modal_open: false,
        tipo_beneficiario: false,
        /* nuevos: [],
        sobreescribir: [],
        nueva_institucion: [], */
    };
    nuevoBeneficiario=id=> {
        if (id) {
            const data={"id":id , "type_action":10};
            this.props.confirmar_repetidos(data);
            /* console.log("se agrego el id", id)
            this.setState({ nuevos: [...this.state.nuevos, { id }], }); */
        }
    }
    sobreEscribirBeneficiario=(id, idUpdate)=> {
        if (id && idUpdate) {
            const data={"id":id , "idUpdate":idUpdate , "type_action":20};
            this.props.confirmar_repetidos(data);
            /* console.log("se agrego el id", id)
            this.setState({ sobreescribir: [...this.state.sobreescribir, { id, idUpdate}] }); */
        }
    }
    beneficiarioNuevaInstitucion=(id)=>{
        if (id) {
            const data={"id":id , "type_action":30};
            this.props.confirmar_repetidos(data);
            /* console.log("se agrego el id", id)
            this.setState({
                nueva_institucion: [...this.state.nueva_institucion, { id }],
            }); */
        }
    }
    detalle = (beneficiario) => {
        this.setState({ beneficiario });
        this.setState({ tipo_beneficiario: false });
        this.toggleModal();
    };
    detalleInstitucion = (bene_institucion) => {
        this.setState({ bene_institucion });
        this.setState({ tipo_beneficiario: true });
        this.toggleModal();
    };

    toggleModal = () => {
        /* if (this.state.modal_open) {
            this.setState({ modal_open: this.state.modal_open });
        }
        this.setState({ modal_open: !this.state.modal_open }); */
        if(this.props.is_open){
            this.props.open_modal(this.props.is_open);
        }
        this.props.open_modal(!this.props.is_open)

    };
    componentWillMount() {
        const {
            listar_repetidos_institucion,
            listar_repetidos,
            page,
        } = this.props;
        listar_repetidos_institucion(page);
        listar_repetidos(page);
    }
    render() {
        const {
            data_repetidos,
            data_repetidos_institucion,
            loader,
            page,
            listar_repetidos: onPageChange,
            onSortChange,
            getBeneficiarioRepetido,
            item,
            confirmar_repetidos,
            is_open
        } = this.props;

        return (
            <LoadMask loading={loader} dark blur>
                <div className="mt-4">
                    <div className="row">
                        <div className="mb-4 col-lg-12">
                            <div className="mb-4 ">
                                <div className="container-botones">
                                    <div className="botones ">
                                        <button
                                            className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                                            onClick={()=>confirmar_repetidos({"id":-1,"type_action":40})}
                                        >
                                            Eliminar
                                        </button>{" "}
                                    </div>
                                </div>
                                <div className="">
                                    <Tabs
                                        defaultActiveKey="PRINCIPAL_TOP"
                                        tabBarPosition="top"
                                        onChange={this.callback}
                                        renderTabBar={() => (
                                            <ScrollableInkTabBar />
                                        )}
                                        renderTabContent={() => <TabContent />}
                                    >
                                        <TabPane
                                            tab="Beneficiarios repetidos"
                                            key="PRINCIPAL_TOP"
                                        >
                                            <div className="my-2 py-1 title-tabs-nav-wrap d-flex justify-content-center font-weight-bold">
                                                Es necesario realizar alguna
                                                acción por cada usuario, de lo
                                                contrario no se registrara nada
                                            </div>
                                            <TablaBeneficiario
                                                data={data_repetidos}
                                                loader={loader}
                                                page={page}
                                                onPageChange={onPageChange}
                                                onSortChange={onSortChange}
                                                url="beneficiarios"
                                                detalle={this.detalle}
                                            />
                                        </TabPane>
                                        <TabPane
                                            tab="Beneficiarios con institución nueva"
                                            key="SEGUNDO_TOP"
                                        >
                                            <div className="my-2 py-1  title-tabs-nav-wrap d-flex justify-content-center font-weight-bold">
                                                Es necesario realizar alguna
                                                acción por cada usuario, de lo
                                                contrario no se registrara nada
                                            </div>
                                            <TablaBeneficiario
                                                data={
                                                    data_repetidos_institucion
                                                }
                                                loader={loader}
                                                page={page}
                                                onPageChange={onPageChange}
                                                onSortChange={onSortChange}
                                                url="beneficiarios"
                                                detalle={
                                                    this.detalleInstitucion
                                                }
                                            />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={is_open}
                        className="mx-lg-auto mx-md-3 mx-2 pt-4"
                    >
                        <ModalHeader className="card p-0 px-4">
                            Detalle
                        </ModalHeader>
                        <ModalBody className="car mt-3">
                            {/* <Asignacion
                                onClose={this.toggleModal}
                                user={this.state.user}
                            /> */}
                            {this.state.tipo_beneficiario ? (
                                <Detalleinstitucion
                                    onClose={this.toggleModal}
                                    beneficiario={this.state.bene_institucion}
                                    loader={loader}
                                    nuevaInstitucion={this.beneficiarioNuevaInstitucion}
                                    /* nuevosConIsntitucion={this.state.nueva_institucion} */
                                />
                            ) : (
                                <Detalle
                                    onClose={this.toggleModal}
                                    beneficiario={this.state.beneficiario}
                                    getBeneficiarioRepetido={getBeneficiarioRepetido }
                                    item={item}
                                    loader={loader}
                                    generarNuevo={this.nuevoBeneficiario}
                                    sobreEscribir={this.sobreEscribirBeneficiario} 
                                   /*  nuevos={this.state.nuevos}
                                    actualizados={this.state.sobreescribir} */
                                />
                            )}
                        </ModalBody>
                    </Modal>
                </div>
            </LoadMask>
        );
    }
}

export default ListarBeneficiarios;
