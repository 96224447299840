import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from 'moment'
import "./style.css";

function getParameterByName(name, location) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const Item = ({ title, value }) => {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column text-center">
                <label className="font-weight-bold m-0">{title}</label>
                <label className="text-primary">{value}</label>
            </div>
        </div>
    );
};

class Detalle extends Component {
    state = {
        showPhotos: false,
    };

    componentDidMount() {
        const { search } = this.props.history.location;
        const tecnico = getParameterByName("tecnico", search);
        const actividad = getParameterByName("actividad", search);
        const data = { tecnico, actividad };
        this.props.getReporte(data);
    }

    toggePhotos = () => {
        this.setState({ showPhotos: !this.state.showPhotos });
    };

    render() {
        const { item } = this.props;
        const { actividad } = item;
        return (
            <Fragment>
                <div id="reporteheader" className="card py-3 mt-4">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 align-items-center d-flex">
                                <Link
                                    to="/reporte/actividades"
                                    className="btn btn-light w-100"
                                >
                                    Regresar
                                </Link>
                            </div>

                            {item && actividad && (
                                <Fragment>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                        <Item
                                            title="institucion"
                                            value={item.institution_name}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                        <Item
                                            title="fecha"
                                            value={moment(item.finished_in).format('DD/MM/YYYY')}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                                        <Item
                                            title="actividad"
                                            value={actividad.name}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                        <Item
                                            title="tecnico"
                                            value={`${item.first_name} ${item.last_name}`}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card p-5 mt-4 d-flex flex-lg-row flex-column">
                    <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                        <div className="row m-0 p-0">
                            <div className="interlined pr-md-5">
                                <label className="font-weight-bold pt-1 m-0">
                                    fotografias de la actividad
                                </label>
                            </div>
                            <button
                                onClick={() => this.toggePhotos()}
                                className="btn btn-outline-primary mx-md-3 mt-2 mt-lg-0 px-4"
                            >
                                Ver fotos
                            </button>
                        </div>

                        {this.state.showPhotos && (
                            <div className="row m-0 p-0 mt-3">
                                <div className="col-12 col-md-6">
                                    { item.first_photo ? (
                                        <img src={`${item.first_photo}`} />
                                    ):(
                                        <label>No hay foto</label>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    { item.second_photo ? (
                                        <img src={`${item.second_photo}`} />
                                    ):(
                                        <label>No hay foto</label>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    { item.third_photo ? (
                                        <img src={`${item.third_photo}`} />
                                    ):(
                                        <label>No hay foto</label>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="interlined pr-5 mt-3 mb-4">
                            <label className="font-weight-bold pt-1 m-0">
                                Resumen de actividad
                            </label>
                        </div>
                        {item && actividad && (
                            <Fragment>
                                <div className="mt-2">
                                    <h6 className="mb-0">Actividad</h6>
                                    <label>{actividad.name}</label>
                                </div>
                                <div className="mt-2">
                                    <h6>Observaciones</h6>
                                    <label>{actividad.description}</label>
                                </div>
                                {/* insumos */}
                                <div className="interlined pr-5 mt-4 mb-2">
                                    <label className="font-weight-bold pt-1 m-0">
                                        Insumos
                                    </label>
                                </div>
                                <table className="table insumos-table table-striped">
                                    <thead className="table-dark">
                                        <tr>
                                            <th className="center" colSpan="2">
                                                No
                                            </th>
                                            <th>Insumo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {actividad.insumos.map((insumo) => (
                                            <tr key={insumo.id}>
                                                <td
                                                    className="center py-2"
                                                    colSpan="2"
                                                >
                                                    No. {insumo.id}
                                                </td>
                                                <td>{insumo.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Detalle;
