import { api } from "./api";

export const getIntitutions = async () => {
    const institutions = [];
    await api
        .get("/institution/raw")
        .then((response) => {
            if (response) {
                response.forEach((instituion) => {
                    institutions.push({
                        value: instituion.id,
                        label: instituion.name,
                    });
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    return institutions;
};

export const getProjects = async () => {
    const projects = [];
    await api
        .get("/projects/raw")
        .then((response) => {
            if (response) {
                response.forEach((project) => {
                    projects.push({
                        value: project.id,
                        label: project.name,
                    });
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    return projects;
};

export const getCommunities = (filtro) => {
    const communities = [];
    api.get("/comunidades/raw")
        .then((response) => {
            response.forEach((item) => {
                if (filtro) {
                    if (item.municipality == filtro) {
                        communities.push({
                            value: item.id,
                            label: item.name,
                        });
                    }
                } else {
                    communities.push({
                        value: item.id,
                        label: item.name,
                    });
                }
            });
        })
        .catch((error) => {
            console.log(error);
        });
    return communities;
};
