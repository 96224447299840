import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe } from "./redux/modules/cuenta/login";

// maquetado base
import SiderBar from "./common/components/layout/Sidebar/SideBar";
import Footer from "./common/components/layout/Footer/Footer";

import Navbar from "./common/components/layout/Navbar/Navbar";
/* import { VerifyLogin } from "./common/components/layout"; */

import LoadMask from "./common/components/Utils/LoadMask/LoadMask";

class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    render() {        
        if (localStorage.getItem('profile_first_time')) {         
            return (<Redirect to="/primera_vez" />);
        }  
        const { component: Component, logOut, login: { me }, ...rest} = this.props;
        const {title} = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            <div>
                                <SiderBar
                                    toggleOpen={this.state.toggleOpen}
                                    navToggle={this.navToggle}
                                    logOut={logOut}
                                    user={me}
                                />
                                <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                    <div id="border-menu"></div>
                                    <div className="container px-0 px-sm-4">
                                        <div className="sticky-top">
                                            <Navbar
                                                navToggle={this.navToggle}
                                                logOut={logOut}
                                                user={me}
                                                // path={path}
                                                title={title}
                                            />
                                        </div>
                                        <div className="main-content-container">
                                            <Component {...props} />
                                        </div>
                                    </div>
                                    {/* <Footer /> */}
                                </main>
                            </div>
                        ) : (
                            <React.Fragment>
                                {/*  <VerifyLogin />  Por si se quiere regresar al loader al loadin anterior*/}
                                <LoadMask loading={true} dark blur>
                                    <SiderBar
                                        toggleOpen={this.state.toggleOpen}
                                        navToggle={this.navToggle}
                                        logOut={logOut}
                                        user={me}
                                    />
                                    <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                        <div className="sticky-top">
                                            <div className="p-0 container">
                                                <Navbar
                                                    navToggle={this.navToggle}
                                                    logOut={logOut}
                                                    user={me}
                                                />
                                            </div>
                                        </div>
                                        <div className="main-content-container px-4 container-fluid">
                                            <Component {...props} />
                                        </div>
                                        <Footer />
                                    </main>
                                </LoadMask>
                            </React.Fragment>
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
