import React, { Fragment } from "react";
import moment from "moment";
import { Prev, Current } from './index'

const DetalleActividad = ({ item }) => {
    let detail = item.detail ? JSON.parse(item.detail) : null;
    let previous = item.previous_detail
        ? JSON.parse(item.previous_detail)
        : null;

    return (
        <Fragment>
            {/* estado anterior */}
            {previous && (
                <div className="w-100 mb-5 mb-lg-0">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.created).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0">Anterior</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #3B3B41" }}
                    />

                    <div className="col-12">
                        <Prev title="Nombre" value={previous.name} />
                        <Prev
                            title="Descripción"
                            value={previous.description}
                        />
                        <Prev
                            title="Fecha inicio"
                            value={previous.date_start}
                        />
                        <Prev title="Fecha fin" value={previous.date_end} />
                        <Prev
                            title="Institución"
                            value={previous.institution_name}
                        />
                        <h6 className="my-2">Insumos</h6>
                        {previous.insumos.map((item, key) => (
                            <Prev key={key} title={key+1} value={item.name} />
                        ))}
                    </div>
                </div>
            )}
            {previous && <div className="mx-5" />}
            {/* estado actual */}
            {detail !== null && (
                <div className="w-100">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.modified).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0 text-primary">Actual</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #008FF1" }}
                    />

                    <div className="col-12">
                        <Current
                            title="Nombre"
                            value={detail.name}
                            prev={previous ? previous.name : null}
                        />
                        <Current
                            title="Descripción"
                            value={detail.description}
                            prev={previous ? previous.description : null}
                        />
                        <Current
                            title="Fecha inicio"
                            value={detail.date_start}
                            prev={previous ? previous.date_start : null}
                        />
                        <Current
                            title="Fecha fin"
                            value={detail.date_end}
                            prev={previous ? previous.date_end : null}
                        />
                        <Current
                            title="Institución"
                            value={detail.institution_name}
                            prev={previous ? previous.institution_name : null}
                        />
                        <h6 className="my-2">Insumos</h6>
                        {detail.insumos.map((item, key) => (
                            <Current
                                key={key}
                                title={key+1}
                                value={item.name}
                                isPrev={previous && !previous.insumos[key] ? true : undefined}
                                prev={
                                    previous && previous.insumos[key] ? previous.insumos[key].name : null
                                }
                            />
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default DetalleActividad;
