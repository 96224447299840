import React, { Component } from "react";
import Grid from "Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";

class TablaUsuarios extends Component {
    render() {
        const {
            data,
            loader,
            page,
            onPageChange,
            onSortChange,
            deleteUser,
            url,
            asignar,
        } = this.props;

        return (
            <div className="card">
                <React.Fragment>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({
                                editar: url,
                                eliminar: deleteUser,
                                ver: url,
                                asignar: (id) => {
                                    asignar(
                                        data.results.find((e) => e.id == id)
                                    );
                                },
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        <TableHeaderColumn isKey dataField="username" dataSort>
                            Usuario
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="first_name" dataSort>
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="last_name" dataSort>
                            Apellidos
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="institution_name"
                            dataSort
                        >
                            Institucion
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            </div>
        );
    }
}

export default TablaUsuarios;
