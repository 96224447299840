import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/projects';
import ProjectCreate from  './ProjectCreate';

const ms2p = (state)  => {
    return {
        ...state.projects,
    };
};

const md2p = { 
    ...actions 
};

export default connect(ms2p, md2p)(ProjectCreate)