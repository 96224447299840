import { handleActions } from "redux-actions";
import { change } from "redux-form";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { api } from "api";
import {
  ETNIAS_SELECT,
  MUNICIPIOS,
} from "../../../common/components/Utils/constant";

const LOADER = "LOADER_DASHBOARD";
const DATA = "DATA";
const BENEFICIARIOS ="BENEFICIARIOS";
const CARACTERISTICAS ="CARACTERISTICAS_DE_LA_POBLACION";
const ACTIVIDADES ="ACTIVIDADES_REALIZADAS";
const INSUMOS ="INSUMOS_ENTREGADOS";
const PROYECTOS="PROYECTOS_REALIZADOS"
const SET_COMMUNITIES = "SET_COMMUNITIES";
const SET_FILTERED_COMMUNITIES = "SET_FILTERED_COMMUNITIES";
const DEPARTAMENT = "DEPARTAMENT";
const MUNICIPALITY = "MUNICIPALITY";
const COMMUNITY = "COMMUNITY";
const FILTER_MUNICIPALITY = "FILTER_MUNICIPALITY";
const GENERO ="FILTRO_GENERO";
const INSTITUCION = "FILTRO_INSTITUCION";
const DESDE = "DESDE";
const HASTA = "HASTA";
const PAGE = "PAGE_DASHBOARD";
const ORDERING = "ORDERING_DASHBOARD";
const PROJECT = "FILTRO_PROYECTO";


const setLoader = loader =>({
    type: LOADER,
    loader
})
const setData = data =>({
    type: DATA,
    data
})
const setBeneficiarios = beneficiarios =>({
    type: BENEFICIARIOS,
    beneficiarios
})
const setCaracteristicas = caracteristicas =>({
  type: CARACTERISTICAS,
  caracteristicas
})
const setActividades = actividades =>({
  type: ACTIVIDADES,
  actividades
})
const setInsusmos = insumos =>({
  type: INSUMOS,
  insumos
})
const setProject=proyectos=>({
  type:PROYECTOS,
  proyectos
})
const setMunicipality = (municipios) => ({
  type: FILTER_MUNICIPALITY,
  municipios,
});
const setCommunities = (comunidades) => ({
  type: SET_COMMUNITIES,
  comunidades,
});
const setFilteredCommunities = (comunidades_filtradas) => ({
  type: SET_FILTERED_COMMUNITIES,
  comunidades_filtradas,
});
const setFilteredDepartament = (departamento) => ({
  type: DEPARTAMENT,
  departamento,
});
const setFilteredMunicipality = (municipio) => ({
  type: MUNICIPALITY,
  municipio,
});
const setFilteredCommunity = (comunidad) => ({
  type: COMMUNITY,
  comunidad,
});
const setGender = (genero) => ({
  type: GENERO,
  genero,
});
const setInstitucion = (institucion) => ({
  type: INSTITUCION,
  institucion,
});
const setProjectFilter =(proyecto)=>({
  type:PROJECT,
  proyecto
});
const setFrom = (desde) => ({
  type: DESDE,
  desde,
});
const setTo = (hasta) => ({
  type: HASTA,
  hasta,
});
const setPage = (page) => ({
  type: PAGE,
  page,
});
const setOrdering = (ordering) => ({
  type: ORDERING,
  ordering,
});

const filtrarEtnia = (etnia) => {
  let resul="";
  ETNIAS_SELECT.map((item) => {
         if (item.value == etnia) {
          resul=item.label;
      }
  });
  return resul;
}
export const getCommunities = () => dispatch => {
  api.get("/comunidades/raw")
      .then((response) => {
          dispatch(setCommunities(response));
      })
      .catch((error) => {
          console.log(error);
      });
};
export const filtrarMunicipio = (label, para_filtro = false) => (dispatch) => {
   // dashboard
   dispatch(filtrarComunidades(""));
   dispatch(change("dashboard_form", "municipality", "-1"));
   dispatch(change("dashboard_form", "community", -1));
   dispatch(change("dashboard_form", "municipality_actividad", "-1"));
   dispatch(change("dashboard_form", "community_actividad", -1));
   dispatch(change("dashboard_form", "municipality_insumo", "-1"));
   dispatch(change("dashboard_form", "community_insumo", -1));

  /*const municipios = [];
   if(para_filtro == true){
      municipios.push({value: "00", label: "Ninguno"})
  } */
  const municipios = [{ value: "-1", label: "ninguno" }];
  MUNICIPIOS.map((item) => {
      if (item.departamento == label) {
          municipios.push({ value: item.id, label: item.nombre });
      }
  });
  dispatch(setMunicipality(municipios));

}

export const filtrarComunidades = (label, para_filtro = false) => (dispatch, getStore) => {
  const COMUNIDADES = getStore().beneficiarios.comunidades;
  /*const comunidades = [];
   if (para_filtro == true){
      comunidades.push({value: -1, label:"Ninguna"})
  } */

  const comunidades = [{ value: -1, label: "ninguno" }];
  COMUNIDADES.map((item) => {
      if (item.municipality == label) {
          comunidades.push({ value: item.id, label: item.name });
      }
  });

  dispatch(setFilteredCommunities(comunidades));
  dispatch(change("dashboard_form", "community", -1));
  dispatch(change("dashboard_form", "community_actividad",-1));
  dispatch(change("dashboard_form", "community_insumo", -1));
};

export const dashboard_beneficiarios =()=>(dispatch,getStore)=>{
  const resource = getStore().dashboard;
  const params = { };

  if (resource.departamento != "-1") params.departament = resource.departamento;
  if (resource.municipio != "-1") params.municipality = resource.municipio;
  if (resource.comunidad != -1) params.community = resource.comunidad;
  if (resource.genero != 0) params.gender = resource.genero;
  if (resource.institucion != -1) params.institution = resource.institucion;
  if (resource.desde != "") params.date_start = resource.desde;
  if (resource.hasta != "") params.date_end = resource.hasta;

  dispatch(setLoader(true));
    api.get("home/beneficiarios", params)
        .then((response) => {
            const grafica = {
                datasets: [{
                    label: '# of Votes',
                    data: [response.adultos, response.jovenes, response.niños],
                    backgroundColor: [
                      '#008FF1',
                      '#2AD194',
                      '#EF8B1D'
                    ],
                    cutout: "73%",
                  },],}
            dispatch(setBeneficiarios({grafica:grafica, data:response }));
        })
        .catch((error) => {
            console.log(error);
            let msj =
                "Error al intentar obtener los datos";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
}
export const dashboard_actividades =(page=1)=>(dispatch,getStore)=>{
  const resource = getStore().dashboard;
  const params = {page};

  if (resource.departamento != "-1") params.departament_activity = resource.departamento;
  if (resource.municipio != "-1") params.municipality_activity = resource.municipio;
  if (resource.comunidad != -1) params.community_activity = resource.comunidad;
  if (resource.genero != 0) params.gender_activity = resource.genero;
  if (resource.institucion != -1) params.institution_activity = resource.institucion;
  if (resource.desde != "") params.date_start_activity = resource.desde;
  if (resource.hasta != "") params.date_end_activity = resource.hasta;

  dispatch(setLoader(true));
  api.get("home/actividades", params)
      .then((response) => {

            dispatch(setActividades(response));
            dispatch(setPage(page))
      })
      .catch((error) => {
          console.log(error);
          let msj =
              "Error al intentar obtener los datos";
          if (error) {
              if (error.detail) {
                  msj = error.detail;
              }
          }
          NotificationManager.error(msj, "ERROR", 5000);
      })
      .finally(() => {
          dispatch(setLoader(false));
      });
}

export const dashboard_insumos =(page=1)=>(dispatch,getStore)=>{
  const resource = getStore().dashboard;
  const params = {page};

  if (resource.departamento != "-1") params.departament_insumo = resource.departamento;
  if (resource.municipio != "-1") params.municipality_insumo = resource.municipio;
  if (resource.comunidad != -1) params.community_insumo = resource.comunidad;
  if (resource.genero != 0) params.gender_insumo = resource.genero;
  if (resource.institucion != -1) params.institution_insumo = resource.institucion;
  if (resource.desde != "") params.date_start_insumo = resource.desde;
  if (resource.hasta != "") params.date_end_insumo = resource.hasta;
  dispatch(setLoader(true));
  api.get("home/insumos", params)
      .then((response) => {
          //console.log("response de insumos---------",response) ;
            dispatch(setInsusmos(response));
            dispatch(setPage(page))
      })
      .catch((error) => {
          console.log(error);
          let msj =
              "Error al intentar obtener los datos";
          if (error) {
              if (error.detail) {
                  msj = error.detail;
              }
          }
          NotificationManager.error(msj, "ERROR", 5000);
      })
      .finally(() => {
          dispatch(setLoader(false));
      });
}
export const dashboard_caracterisiticas =()=>(dispatch,getStore)=>{
  const resource = getStore().dashboard;
  const params = {};
  if (resource.institucion != -1) params.institution_caracteristicas = resource.institucion;
  dispatch(setLoader(true));
  api.get("home/caracteristicas",params)
      .then((response) => {
        //console.log("Response d ecaracteristicas", response.porcentaje_etnia)
            const grafica_discapacidad = {
                datasets: [
                  {
                    label: '# of Votes',
                    data: [response.discapacidad_hombre, response.discapacidad_mujer],
                    backgroundColor: [
                      '#008FF1',
                      '#2AD194',
                      '#EF8B1D'
                    ],
                    cutout: "73%",
                  },],}

            const data_grafica=[];
            for (const etnia in response.porcentaje_etnia) {
               data_grafica.push({
                total_beneficiarios: response.total,
                etnia:filtrarEtnia(etnia),
                total: response.porcentaje_etnia[etnia].total,
                hombres: response.porcentaje_etnia[etnia].hombres,
                mujeres: response.porcentaje_etnia[etnia].mujeres,
                porcentaje:response.porcentaje_etnia[etnia].porcentaje
              })
              /* data_grafica.push({
                total: response.total,
                etnia:filtrarEtnia(etnia),
                porcentaje: 100.00 - response.porcentaje[etnia]
              }) */

            }
            const data ={
              grafica_discapacidad:grafica_discapacidad,
              data_grafica:data_grafica,
              data:response
            }
            dispatch(setCaracteristicas(data));
      })
      .catch((error) => {
          console.log(error);
          let msj =
              "Error al intentar obtener los datos";
          if (error) {
              if (error.detail) {
                  msj = error.detail;
              }
          }
          NotificationManager.error(msj, "ERROR", 5000);
      })
      .finally(() => {
          dispatch(setLoader(false));
      });
}
export const dashboard_proyectos =(page=1)=>(dispatch,getStore)=>{
  dispatch(setLoader(true));
  const resource = getStore().dashboard;
  const params = {page};
  //if (resource.institucion != -1) params.institution_proyectos = resource.institucion;
  if (resource.proyecto != -1) params.proyecto = resource.proyecto;
  if (resource.departamento != "-1") params.departament_proyecto = resource.departamento;
  if (resource.municipio != "-1") params.municipality_proyecto = resource.municipio;
  if (resource.comunidad != -1) params.community_proyecto = resource.comunidad;
  if (resource.genero != 0) params.gender_proyecto = resource.genero;
  if (resource.institucion != -1) params.institution_proyecto = resource.institucion;
  if (resource.desde != "") params.date_start_proyecto = resource.desde;
  if (resource.hasta != "") params.date_end_proyecto = resource.hasta;

  api.get("home/proyectos",params).then((response) => {
    dispatch(setProject(response));
    dispatch(setPage(page));
  }).catch((error) => {
          console.log(error);
          let msj =
              "Error al intentar obtener los datos";
          if (error) {
              if (error.detail) {
                  msj = error.detail;
              }
          }
          NotificationManager.error(msj, "ERROR", 5000);
      })
      .finally(() => {
          dispatch(setLoader(false));
      });
}
const onSortChange = (ordering) => (dispatch, getStore) => {
  const sort = getStore().dashboard.ordering;
  if (ordering === sort) {
      dispatch(setOrdering(`-${ordering}`));
  } else {
      dispatch(setOrdering(ordering));
  }
  dispatch(listar());
};

//------------------ Filtros para los el dasboard ---------------
const saber_que_listar=(filtro_de,dispatch)=>{
  if (filtro_de==10){
      dispatch(dashboard_beneficiarios());
    }
    if (filtro_de==20){
      dispatch(dashboard_actividades());
    }
    if (filtro_de==30){
      dispatch(dashboard_insumos());
    }
    if (filtro_de==40){
      dispatch(dashboard_caracterisiticas());
    }
    if (filtro_de==50){
      dispatch(dashboard_proyectos());
    }
}
export const filtro_departamento = (departamento, filtro_de) => (dispatch) => {
  if (departamento == "00"){
    dispatch(setFilteredDepartament("-1"));
    dispatch(setFilteredMunicipality("-1"));
    dispatch(setFilteredCommunity("-1"));
    saber_que_listar(filtro_de, dispatch);
  } else{
    dispatch(setFilteredDepartament(departamento));
    saber_que_listar(filtro_de, dispatch);
  }
}
export const filtro_municipio = (municipio,filtro_de) => (dispatch) => {
  if (municipio == "00"){
    dispatch(setFilteredMunicipality("-1"));
    dispatch(setFilteredCommunity(-1));
    saber_que_listar(filtro_de, dispatch);
  } else{
    dispatch(setFilteredMunicipality(municipio));
    saber_que_listar(filtro_de, dispatch);
  }
}
export const filtro_comunidad = (comunidad,filtro_de) => (dispatch) => {
  dispatch(setFilteredCommunity(comunidad));
  saber_que_listar(filtro_de, dispatch);
}
export const filtro_genero = (genero, filtro_de) => (dispatch) => {
  dispatch(setGender(genero));
  saber_que_listar(filtro_de, dispatch);
}
export const filtro_institucion = (institucion,filtro_de) => (dispatch) => {
  dispatch(setInstitucion(institucion));
  saber_que_listar(filtro_de, dispatch);
}
export const filtro_proyecto = (proyecto,filtro_de) => (dispatch) => {
  dispatch(setProjectFilter(proyecto));
  saber_que_listar(filtro_de, dispatch);
}
export const filtro_desde = (from,filtro_de) => (dispatch) => {
  if (from) dispatch(setFrom(moment(from).format("YYYY-MM-DD")));
  else dispatch(setFrom(""));
  saber_que_listar(filtro_de, dispatch);
}
export const filtro_hasta = (to,filtro_de) => (dispatch) => {
  if (to) dispatch(setTo(moment(to).format("YYYY-MM-DD")));
  else dispatch(setTo(""));
  saber_que_listar(filtro_de, dispatch);
}

const limpiar_filtros = () => (dispatch, getStore) => {

    dispatch(setFilteredDepartament("-1"));
    dispatch(setFilteredMunicipality("-1"));
    dispatch(setFilteredCommunity("-1"));

    dispatch(setInstitucion("-1"));
    dispatch(setGender("0"));

    dispatch(setProjectFilter(-1));

    dispatch(setFrom(""));
    dispatch(setTo(""));
  };

export const actions={
  getCommunities,
  dashboard_beneficiarios,
  dashboard_caracterisiticas,
  dashboard_actividades,
  dashboard_insumos,
  dashboard_proyectos,
  filtrarMunicipio,
  filtrarComunidades,
  getCommunities,
  onSortChange,

  filtro_departamento,
  filtro_municipio,
  filtro_comunidad,
  filtro_desde,
  filtro_hasta,
  filtro_genero,
  filtro_institucion,
  filtro_proyecto,
  limpiar_filtros,
}
export const reducers ={
    [LOADER]:(state, {loader})=>{
        return {
            ...state,
            loader,
        }
    },
    [DATA]:(state, {data})=>{
        return {
            ...state,
            data,
        }
    },
    [BENEFICIARIOS]:(state, {beneficiarios})=>{
        return {
            ...state,
            beneficiarios,
        }
    },
    [CARACTERISTICAS]:(state, {caracteristicas})=>{
        return {
            ...state,
           caracteristicas,
        }
    },
    [ACTIVIDADES]:(state, {actividades})=>{
        return {
            ...state,
            actividades,
        }
    },
    [PROYECTOS]:(state, {proyectos})=>{
      return {
          ...state,
          proyectos,
      }
    },
    [PROJECT]: (state, { proyecto }) => {
      return {
          ...state,
          proyecto,
      };
  },
    [INSUMOS]:(state, {insumos})=>{
        return {
            ...state,
            insumos
        }
    },
    [FILTER_MUNICIPALITY]: (state, { municipios }) => {
      return {
          ...state,
          municipios,
      };
  },
    [SET_COMMUNITIES]: (state, { comunidades }) => {
      return {
          ...state,
          comunidades,
      };
    },
    [SET_FILTERED_COMMUNITIES]: (state, { comunidades_filtradas }) => {
        return {
            ...state,
            comunidades_filtradas,
        };
    },

    [DEPARTAMENT]: (state, { departamento }) => {
      return {
          ...state,
          departamento,
      };
    },
    [MUNICIPALITY]: (state, { municipio }) => {
        return {
            ...state,
            municipio,
        };
    },
    [COMMUNITY]: (state, { comunidad }) => {
      return {
          ...state,
          comunidad,
      };
    },
    [GENERO]: (state, { genero }) => {
      return {
          ...state,
          genero,
      };
    },
    [INSTITUCION]: (state, { institucion }) => {
      return {
          ...state,
          institucion,
      };
    },
    [DESDE]: (state, { desde }) => {
      return {
          ...state,
          desde,
      };
    },
    [HASTA]: (state, { hasta }) => {
      return {
          ...state,
          hasta,
      };
    },
    [PAGE]: (state, { page }) => {
      return {
          ...state,
          page,
      };
    },
    [ORDERING]: (state, { ordering }) => {
      return {
          ...state,
          ordering,
      };
  },

}

export const  initialState={
    loader:false,
    data:[],
    beneficiarios:{
      grafica:{},
      data:{}
    },
    caracteristicas:{
      grafica_discapacidad:{},
      data_grafica:[],
      data:{}
    },
    actividades:{},
    proyectos:{},
    insumos:{},
    page: 1,
    ordering: "",
    municipios: [],
    comunidades: [],
    //-----Filtros---
    comunidades_filtradas: [],
    type_beneficiary:-1,

    departamento:"-1",
    municipio:"-1",
    comunidad:-1,
    genero:0,
    institucion:-1,
    proyecto:-1,
    desde:"",
    hasta:"",
}

export default handleActions(reducers,initialState);
