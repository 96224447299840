import React, { Component, Fragment } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import ReactTooltip from 'react-tooltip';
import "../style.css";

const breakPoints = [
    { width: 1, itemsToShow: 20 },
    { width: 550, itemsToShow: 20, itemsToScroll: 2 },
    { width: 768, itemsToShow: 20 },
    { width: 1200, itemsToShow: 20 },
];

const Toltip = ({ dataTip }) => {   
    var arrayDeCadenas = String(dataTip).split(',');
    return (
        <div className="p-0 m-0 ">
            <label className="font-weight-bold"> {arrayDeCadenas[0]}</label><br />
            <label className="p-0 m-0"><strong className="font-weight-bold">{arrayDeCadenas[1]}</strong> Actividades</label><br />
            <label className="p-0 m-0"><strong className="font-weight-bold">{arrayDeCadenas[2]}</strong> Finalizadas</label><br />
        </div> 
    );
};

class ItemGraficaProyecto extends Component {
   
   
    render() {
        const { data } = this.props;
        //console.log("Esta es la data recivida-------", data);
        return (
            <Fragment>
              <div className="carousel-wrapper">
                    <Carousel
                        breakPoints={breakPoints}
                        showArrows={false}
                        itemPosition={consts.START}
                    >
                        <div
                            className="text-right pr-1"
                            style={{
                                borderColor: "#DBDBDB",
                                borderRightWidth:1,
                                borderRighttStyle:"solid",
                                height: 157,
                                borderRightWidth: 1,
                                borderRightStyle: "solid",
                            }}
                        >
                            <div className="container p-0">
                                <div>
                                    <label className="small">100%</label>
                                </div>
                                <div className="pt-1">
                                    <label  className="small">75%</label>
                                </div>
                                <div className="pt-1">
                                    <label  className="small">50%</label>
                                </div>
                                <div className="pt-1">
                                    <label  className="small">25%</label>
                                </div>
                                <div className="pt-1">
                                    <label  className="small">0%</label>
                                </div>
                            </div>
                    </div>
                        {data && data.map((item, index) => (
                               /*  <Item key={index} item={item} /> */
                               <div
                                    className="block justify-content-center"
                                    style={{
                                        width: 50,
                                    }}
                                    key={index}
                                >
                                    <div
                                        style={{
                                            paddingLeft: 17,
                                            paddingBottom: 5,
                                            borderColor: "#DBDBDB",
                                            borderBottomStyle: "solid",
                                            borderBottomWidth: 1,
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "#00487A",
                                                height: 150,
                                                width: 25,
                                                borderRadius: 50,
                                                position: "relative",
                                                zIndex: 3,
                                                boxShadow: 2,
                                                display: "flex",
                                                cursor: "pointer",
                                            }}
                                            data-tip={`${item.nombre},${item.total_actividades},${item.total_actividades_finalizadas}`}
                                            /* data-for={`item-proyecto-${item.id}`} */
                                            data-for="tooltip-proyectos"
                                        >
                                            <div
                                                style={{
                                                    backgroundColor: "#008FF1",
                                                    position: "absolute",
                                                    /*  height: Math.round(item.porcentaje * 1.5), */
                                                    height: Math.round(item.porcentaje_de_avance * 1.5),
                                                    width: 25,
                                                    borderRadius: 50,
                                                    alignItems: "initial",
                                                    justifyContent: "center",
                                                    bottom: 0,
                                                    zIndex: 2,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        zIndex: 1,
                                                    }}
                                                    className="texto-rotado-barra"
                                                >
                                                    {item.porcentaje_de_avance} %
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="texto-rotado-2">
                                        <label>{item.nombre}</label>
                                    </div>
                                </div>
                            ))}

                        <ReactTooltip id='tooltip-proyectos' 
                            className='shadow-tooltip'                      
                            place="top" 
                            type="light" 
                            effect="float"
                            getContent={(dataTip) =>
                                <Toltip dataTip={dataTip}/>                          
                            }
                       /> 
                        </Carousel>
                </div> 
    
            
            </Fragment> 
        );
    }
}
export default ItemGraficaProyecto;
