import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/global_settings/gl_settings';
import settings from  './settings';

const ms2p = (state)  => {
    return {
        ...state.gl_settings,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(settings)