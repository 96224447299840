import React, { Component } from "react";
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { customStandardActions } from "../../../Utils/Grid/StandardActions";
import Header from "../../../Utils/Grid/Header";
import Asignacion from "../AsignarUsuarios";
import { Field, reduxForm } from "redux-form";
import {
    SelectField,
    renderDatePicker,
} from "../../../Utils/renderField/renderField";

const Filterbar = (props) => {
    return (
        <div className="row w-100 mx-0">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">
                    Descargar reporte:
                </label>
                <button
                    className="btn btn-success w-100"
                    onClick={() => {
                        props.descarga_excel();
                    }}
                >
                    Descargar Excel
                </button>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Institución:</label>
                <Field
                    name="institucion"
                    placeholder="Institución"
                    options={props.filters.instituciones}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => props.onPress(value, "INSTITUCION")}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Desde:</label>
                <Field
                    onChange={(e, value) => props.onPress(value, "DATESTART")}
                    name="fecha_inicio"
                    placeholder="desde"
                    component={renderDatePicker}
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-1 mb-1">
                <label className="font-weight-bold mb-0">Hasta:</label>
                <Field
                    onChange={(e, value) => props.onPress(value, "DATEEND")}
                    name="fecha_fin"
                    placeholder="hasta"
                    component={renderDatePicker}
                />
            </div>
        </div>
    );
};

class ListProjects extends Component {
    state = {
        modal_open: false,
        project: undefined,
    };

    componentWillMount() {
        const { onDeleteFilters, page } = this.props;
        onDeleteFilters(page);
    }

    asignar = (project) => {
        this.setState({ project });
        this.toggleModal();
    };

    toggleModal = () => {
        this.setState({ modal_open: !this.state.modal_open });
    };

    comprobar = (id) => {
        const { data, eliminar, me } = this.props;
        let item = undefined;

        if (data && data.results) item = data.results.find((e) => e.id == id);

        if (me && me.role !== 10 && item.institutions.length > 1) {
            return customStandardActions(id, {
                ver: "proyectos",
            });
        } else {
            return customStandardActions(id, {
                editar: "proyectos",
                eliminar: eliminar,
                ver: "proyectos",
                asignar: (id) => {
                    this.asignar(data.results.find((e) => e.id == id));
                },
            });
        }
    };

    render() {
        const {
            data,
            loader,
            page,
            listar: onPageChange,
            onSortChange,
            filters,
            onApplyFilters,
            descarga_excel,
        } = this.props;

        return (
            <React.Fragment>
                <div className="mt-4">
                    <Header
                        to="/proyectos/crear"
                        textBtn="Nuevo"
                        filtro1={
                            <Filterbar
                                filters={filters}
                                onPress={onApplyFilters}
                                descarga_excel={descarga_excel}
                            />
                        }
                    />
                </div>
                <div className="mb-4 card card-small">
                    <div className="p-0">
                        <Grid
                            hover
                            striped
                            data={data}
                            loading={loader}
                            onPageChange={onPageChange}
                            onSortChange={onSortChange}
                            page={page}
                        >
                            <TableHeaderColumn
                                width="15%"
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                dataFormat={this.comprobar}
                            >
                                Acciones
                            </TableHeaderColumn>

                            <TableHeaderColumn isKey dataField="name" dataSort>
                                Proyecto
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="institutions"
                                dataSort
                                dataFormat={(cell) => {
                                    let _institucions = "";
                                    cell.forEach((element) => {
                                        _institucions = `${_institucions} ${element.name},`;
                                    });
                                    return _institucions;
                                }}
                            >
                                Instituciones
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="description" dataSort>
                                Descripción
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </div>
                {/* </div> */}
                <Modal
                    isOpen={this.state.modal_open}
                    className="mx-lg-auto mx-md-3 mx-2"
                >
                    <ModalHeader className="card ">
                        Asignación de técnico
                    </ModalHeader>
                    <ModalBody className="car mt-3">
                        <Asignacion
                            onClose={this.toggleModal}
                            project={this.state.project}
                        />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: "form-listado-proyectos",
})(ListProjects);
