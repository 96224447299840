import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/instituciones/institucion';
import institutionCreate from  './InstitutionCreate';

const ms2p = (state)  => {
    return {
        ...state.institucion,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(institutionCreate)