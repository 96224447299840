import React, { Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderCustomFilePicker,
} from "../../Utils/renderField/renderField";

const ProfileForm = (props) => {
    const {
        handleSubmit,
        me,
        setAvatar,
        editar,
        editModeSwitch,
        collapse,
    } = props;

    return (
        <Fragment>
            {/* header */}
            <div className="profile-header">
                <div className="d-flex align-items-end h-info" >
                    <div className="d-flex h-foto">
                        <Field
                            disabled={!editar}
                            name="avatar"
                            accept="image/*"
                            className="profile-foto"
                            photo={
                                me.profile && me.profile.avatar
                                    ? me.profile.avatar
                                    : null
                            }
                            /* setFile={setAvatar}     */                      
                            component={renderCustomFilePicker}
                           
                        />
                        <h6 className="my-0 mx-3 name-dark font-weight-bold text-center">
                            {me.first_name} {me.last_name}
                        </h6>
                    </div>
                    <div id="editar-btn">
                        {!editar && (
                            <button
                                type="button"
                                className="btn btn-primary px-5"
                                onClick={editModeSwitch}
                            >
                                Editar
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {/* informacion personal */}
            <form action="" onSubmit={handleSubmit} className="form-profile">
                <div className="mb-4 py-5 card card-small mx-1 mx-sm-5">
                    <div className="p-0 d-flex flex-column flex-lg-row">
                        <div className="form-group has-feedback mx-3 flex-1">
                            <label htmlFor="first_name">Nombre</label>
                            <Field
                                disabled={!editar}
                                name="first_name"
                                placeholder="Nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group has-feedback mx-3 flex-1">
                            <label htmlFor="last_name">Apellido</label>
                            <Field
                                disabled={!editar}
                                name="last_name"
                                placeholder="Nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="p-0 pt-3 d-flex flex-column flex-lg-row">
                        <div className="form-group has-feedback mx-3 flex-1">
                            <label htmlFor="username">Usuario</label>
                            <Field
                                disabled={!editar}
                                name="username"
                                placeholder="Nombre de usuario"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mx-3 has-feedback flex-1">
                            <label htmlFor="profile.adress">Correo</label>
                            <Field
                                disabled={!editar}
                                name="email"
                                placeholder="Correo"
                                component={renderField}
                                type="email"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group mx-3 has-feedback flex-1">
                        <label htmlFor="institution_name">Institución</label>
                        <Field
                            disabled={true}
                            name="institution_name"
                            placeholder="institución"
                            component={renderField}
                            className="form-control"
                        />
                    </div>

                    {editar && (
                        <div className="accordion pt-3" id="accordionExample">
                            <div
                                id="headingOne"
                                className="px-2 p-0 mx-3"
                                onClick={props.onCollapse}
                            >
                                <div className="d-flex py-1 justify-content-between">
                                    <label className="m-0 font-weight-bold">
                                        Cambiar contraseña
                                    </label>
                                    <i className="material-icons">
                                        arrow_drop_down_circle
                                    </i>
                                </div>
                            </div>

                            <div
                                id="passForm"
                                style={{ height: collapse ? "0rem" : "10rem", overflow: 'hidden' }}
                            >
                                <div className="p-0 pt-3 d-flex flex-column flex-lg-row">
                                    <div className="form-group has-feedback mx-3 flex-1">
                                        <label htmlFor="username">
                                            Nueva contraseña
                                        </label>
                                        <Field
                                            name="passwrd"
                                            placeholder="*********"
                                            component={renderField}
                                            type="password"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group mx-3 has-feedback flex-1">
                                        <label htmlFor="profile.adress">
                                            Confirmar contraseña
                                        </label>
                                        <Field
                                            name="passwrd_confirm"
                                            placeholder="*********"
                                            component={renderField}
                                            type="password"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {editar && (
                        <div className="mt-5 d-flex justify-content-center flex-column flex-md-row">
                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                <button
                                    type="button"
                                    className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                                    onClick={editModeSwitch}
                                >
                                    Cancelar
                                </button>
                            </div>
                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                <button className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2">
                                    Guardar
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </Fragment>
    );
};

export default reduxForm({
    form: "profile", // a unique identifier for this form
})(ProfileForm);
