import React, { Component } from "react";
import { reduxForm } from "redux-form";
import ProfileForm from "./ProfileForm";
import "./profile.css";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            editar: false,
            collapse: true,
        };
    }

    componentDidMount(){
        this.props.getMe()
    }

    onEditSwitch = () => {
        this.setState({ editar: !this.state.editar });
    };

    setAvatar = (avatar) => {
        this.setState({ avatar });
    };

    update = (data) => {
        const { update } = this.props;
        update({ ...data, avatar: null }, [
            { file: this.state.avatar, name: "avatar" },
        ]);
        window.location.href="#/"
    };

    render() {
        const { me, update_pass } = this.props;
        const { collapse, editar } = this.state;
        return (
            <ProfileForm
                update_pass={update_pass}
                onSubmit={this.update}
                me={me}
                setAvatar={this.setAvatar}
                editar={editar}
                editModeSwitch={this.onEditSwitch}
                collapse={collapse}
                onCollapse={() => this.setState({collapse: !collapse})}
            />
        );
    }
}

export default reduxForm({
    form: "profile", // a unique identifier for this form
})(Profile);
