import React, { Component } from "react";
import Grid from "Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "Utils/Grid/StandardActions";
import { Field } from "redux-form";
import { renderFieldCheck } from "Utils/renderField/renderField";

class TablaAsignacion extends Component {
    render() {
        const {
            data,
            page,
            loader,
            onPageChange,
            onSortChange,
            onAsignar,
            onDesAsignar,
            onAssignAll,
            assigned_all
        } = this.props;

        const tipo_bene = (tipo) => {
            if (tipo == 10) {
                return "Mujer";
            } else if (tipo == 20) {
                return "Hombre";
            } else if (tipo == 30) {
                return "Niño apadrinado";
            } else if (tipo == 40) {
                return "Joven Beneficiario";
            } else if (tipo == 50) {
                return "Joven voluntario";
            } else  if (tipo == 60) {
                return "Sin asignar";
            }
        };

        return (
            <div className="car">
                <React.Fragment>
                    <Grid
                        hover
                        striped
                        data={data? data : dataR}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            className="py-0"
                            dataField="meta"
                            dataAlign="left"
                            width="15%"
                            dataFormat={standardActions({
                                // Seleccionar crea un checkbox para cada elemento de la tabla
                                // retorna el id del elemento y el valor del checkbox (true o false)
                                seleccionar: (id, value) => {
                                    value ? onAsignar(id) : onDesAsignar(id);
                                },
                            })}
                        >
                            {/* boton de asignar a todos */}
                            <div className="d-flex align-items-center big-check">
                                <Field
                                    name="checked"
                                    component={renderFieldCheck}
                                    checked={assigned_all}
                                    onChange={(e, value) => {
                                        onAssignAll(value)
                                    }}
                                />
                                <label className="m-0 font-weight-bold">Todos</label>
                            </div>
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="18%"
                            dataField="institution"
                            // dataSort
                            isKey
                            //cel es un objeto como tal, en este caso saria position
                            dataFormat={(cell) => {
                                return `${cell.name}`;
                            }}
                        >
                            Institucion
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="type_beneficiary"
                            dataSort
                            dataFormat={(cell) => {
                                return tipo_bene(cell);
                            }}
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="name" dataSort>
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="birth_date" dataSort>
                            Fecha de nacimiento
                        </TableHeaderColumn>
                    </Grid>
                </React.Fragment>
            </div>
        );
    }
}

export default TablaAsignacion;
