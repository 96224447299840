import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { Link } from 'react-router-dom';
import Header from '../../../Utils/Grid/Header';



class ListInstitution extends Component { 
    
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    
    render(){
        const{ data, loader, page, listar: onPageChange, onSortChange, deleteInstitution,} = this.props;
        //console.log('data desde el componenete: ',data);
       
        return(
            <React.Fragment>
            <div className="mt-4">
                
            <Header to="/institucion/crear" textBtn="Nuevo" {...this.props} />
            </div>
            {/* <div className="mb-4 col-12 "> */}
            <div className="mb-4 card card-small">
               
                <div className="p-0">
                
                    <Grid
                        hover 
                        striped 
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                        
                    >
                        <TableHeaderColumn
                            width="15%"
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "institucion", 
                                eliminar: deleteInstitution,
                                ver: "institucion",
                            })}
                        >
                            Acciones
                        </TableHeaderColumn>
                        
                        <TableHeaderColumn
                            isKey
                            dataField="name"
                            dataSort
                        >
                            Institución
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="date"
                            dataSort
                        >
                            Fecha
                        </TableHeaderColumn>                        
                        
                    </Grid>
            
                </div>
            </div>
        {/* </div> */}
        </React.Fragment>
        );
    }
    
}

export default ListInstitution;