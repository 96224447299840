import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import TablaUsuarios from "../../../Commons/TablaUsuarios";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Header from "../../../Utils/Grid/Header";

class Usuarios extends Component {
    static propTypes = {
        users: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    componentDidMount() {
        const { listarUsuarios, page, roles, me } = this.props;
        // extrae la instituion a la que pertenece el usuario logueado
        if (me.profile)
            listarUsuarios(page, roles.ADMIN, me.profile.institution);
    }

    onDeleteUser = (id) => {
        // desactiva el usuario seleccionado y refresca la tabla
        this.props.deleteUser(id);
    };

    render() {
        const {
            users,
            loader,
            page,
            listar: onPageChange,
            onSortChange,
        } = this.props;
        return (
            <React.Fragment>
            
                <div className="mt-4">
                    <Header
                        to="/administradores/nuevo"
                        textBtn="Nuevo"
                        {...this.props}
                    />

                    <TablaUsuarios
                        deleteUser={this.onDeleteUser}
                        data={users}
                        loader={loader}
                        url="administradores"
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    />
                </div>
            
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: "search_usersAdmin", // a unique identifier for this form
})(Usuarios);
