import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

////////////////////////////////////////
// Actions
////////////////////////////////////////

export const onSubmit = (data = {}) => (dispatch) => {
    console.log(data);
    dispatch(setLoader(true));
    api.post('user/send_reset_mail/', data).then(() => {
        dispatch(push("/login"));
        NotificationManager.success('Correo enviado exitosamente, por favor revisa tu correo', 'Éxito', 5000);
    }).catch(error => {
        let msj =  'Ocurrio un error al tratar de enviar el correo de recuperación de contraseña, por favor intenta de nuevo.'
        if(error){
            if(error.detail){
                msj = error.detail
            }
        }
        NotificationManager.error(msj, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};

export const sendRecoverMail = (data) => (dispatach)=>{
    console.log(data);


}

export const actions = {
    onSubmit,
    logOut,    
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
