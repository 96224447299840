import React, { Component } from "react";
import Form from "./Form";
import LoadMask from "Utils/LoadMask/LoadMask";
import { getIntitutions, getProjects } from "../../../../../utility/functions";

class NewActivity extends Component {
    _isMounted = false;
    state = {
        editar: false,
        insumos_eliminar: [],
        institutions: [],
        projects: []
    };

    componentDidMount() {
        // verifica si existe un id en la ruta y obtiene el usuario correspondiente
        const { id } = this.props.match.params;
        if (id) {
            this.props.getActivity(id);
            this.setState({ editar: true });
        }
        this._getIntitutions();
        this.props.getCommunities()
    }

    _getIntitutions = async () => {
        this._isMounted = true;
        // obtiene todas las instituciones sin paginar y filtradas segun la institucion del usuario logueado
        const { institution_name } = this.props.me;
        const institutiones = await getIntitutions(institution_name);
        const proyectos = await getProjects();

        let projects = [{value: 0, label: 'ninguno'}, ...proyectos]
        let institutions = [{value: 0, label: 'ninguno'}, ...institutiones]


        if (this._isMounted){
            this.setState({ institutions });
            this.setState({ projects });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSave = () => {
        const { id } = this.props.match.params;
        if (this.state.editar) {
            this.props.updateActividad(id, this.state.insumos_eliminar);
        } else {
            this.props.nuevaActividad();
        }
    };

    eliminarInsumo = (id) => {
        if (id) {
            this.setState({
                insumos_eliminar: [...this.state.insumos_eliminar, { id }],
            });
        }
    };

    render() {
        const { loader, municipios, filtrarComunidades, filtrarMunicipio, comunidades_filtradas, me } = this.props;
        return (
            <div className="card my-4">
                <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={loader} dark blur>
                        <Form
                            onSubmit={this.onSave}
                            institutions={this.state.institutions}
                            eliminarInsumo={this.eliminarInsumo}
                            editar={this.state.editar}
                            municipios={municipios}
                            comunidades={comunidades_filtradas}
                            filtrarComunidad={filtrarComunidades}
                            filtrarMunicipio={filtrarMunicipio}
                            projects={this.state.projects}
                            me={me}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
export default NewActivity
