import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, SelectField } from "Utils/renderField/renderField";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const Form = (props) => {
    const { handleSubmit, url, editar, is_admin, institutions } = props;
    let disabled = true;
    const location = window.location.href;
    if (location.includes("editar") || location.includes("nuevo")) disabled = false;
    let disabled_institution = false;
    if (editar && !is_admin || disabled) disabled_institution = true;

    return (
        <Fragment>
            {/* aviso de campos olbigatorios */}
            <div className="row justify-content-end mb-1">
                <p className="col-12 text-primary font-weight-bold">
                    Todos los campos que lleven (*) Son requeridos.
                </p>
            </div>
            <form
                name="form_usersAdmin"
                className="form-validate mb-lg padding"
                onSubmit={handleSubmit}
            >
                <div className="mb-3 col-12">
                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="first_name"
                                className="font-weight-bold"
                            >
                                Nombre:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="first_name"
                                placeholder="Nombre"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="last_name"
                                className="font-weight-bold"
                            >
                                Apellido:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="last_name"
                                placeholder="Apellido"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="profile.email"
                                className="font-weight-bold"
                            >
                                Correo:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="email"
                                placeholder="Correo"
                                component={renderField}
                                type="email"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="username"
                                className="font-weight-bold"
                            >
                                Usuario:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="username"
                                placeholder="Nombre de usuario"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="profile.institution"
                                className="font-weight-bold"
                            >
                                Institución:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled_institution}
                                name="profile.institution"
                                options={institutions}
                                component={SelectField}
                            />
                        </div>
                    </div>

                    {editar && !disabled && (
                        <div className="row">
                            <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                                <label
                                    htmlFor="username"
                                    className="font-weight-bold"
                                >
                                    Nueva contraseña
                                </label>
                                <Field
                                    disabled={disabled}
                                    name="passwrd"
                                    placeholder="nueva contraseña"
                                    component={renderField}
                                    type="password"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                                <label
                                    htmlFor="profile.institution"
                                    className="font-weight-bold"
                                >
                                    Confirmar contraseña
                                </label>
                                <Field
                                    disabled={disabled}
                                    name="passwrd_confirm"
                                    placeholder="Confirmar contraseña"
                                    component={renderField}
                                    type="password"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="d-flex justify-content-center flex-column flex-md-row">
                    <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                        <Link
                            className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                            to={url}
                        >
                            {disabled? 'Regresar' : 'Cancelar'}
                        </Link>
                    </div>
                    { !disabled && <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                        <button
                            // onClick={onSave}
                            type="submit"
                            className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2"
                        >
                            {" "}
                            {!editar ? "Guardar" : "Actualizar"}
                        </button>
                    </div>}
                </div>
            </form>
        </Fragment>
    );
};

const validate = (values) => {
    const errors = {};

    if (!values.first_name) {
        errors.first_name = "Campo requerido";
    }
    if (!values.last_name) {
        errors.last_name = "Campo requerido";
    }
    if (!values.email) {
        errors.email = "Campo requerido";
    }
    if (!values.username) {
        errors.username = "Campo requerido";
    }
    const profileErrors = {};
    if (!values.profile || !values.profile.institution) {    
        profileErrors.institution = "campo requerido";
    }
    errors.profile = profileErrors;
    return errors;
};

export default reduxForm({
    form: "form_usersAdmin", // a unique identifier for this form
    validate: validate,
    onSubmitFail: () => {
        NotificationManager.warning(
            "Debe llenar todos los campos obligatorios",
            "Advertencia",
            5000
        );
    },
})(Form);
