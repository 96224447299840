import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from "moment";

const LOADER = "INSTITUTION_LOADER";

const DATA = "DATA_BITACORAS";
const ITEM = "ITEM_BITACORA";
const PAGE = "PAGE_BITACORA";
const ORDERING = "ORDERING_BITACORA";
const SEARCH = "SEARCH_BITACORA";
const SEARCH_BY_TYPE = "SEARCH_BY_TYPE";
const DESDE = "DESDE";
const HASTA = "HASTA";
// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setItem = (item) => ({
    type: ITEM,
    item,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});
const setSearchByType = (tipo) => ({
    type: SEARCH_BY_TYPE,
    tipo,
});
const setFrom = (desde) => ({
    type: DESDE,
    desde,
});
const setTo = (hasta) => ({
    type: HASTA,
    hasta,
});
// ------------------------------------
// Actions
// ------------------------------------

export const getBitacora = (id) => (dispatch) => {
    api.get(`bitacora/${id}/`)
        .then((response) => {
            dispatch(setItem(response));
        })
        .catch((e) => {
            console.log("ERROR", e);
            NotificationManager.error(e.detail, "ERROR", 5000);
        });
};

export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().bitacora;
    const params = { page };

    params.ordering = resource.ordering;
    params.search = resource.search;

    resource.tipo? params.tipo = resource.tipo : null;

    if (resource.desde !== "") params.fecha_inicio = resource.desde;

    if (resource.hasta !== "") params.fecha_fin = resource.hasta;

    // console.log("res", resource);

    dispatch(setLoader(true));
    api.get("/bitacora", params)
        .then((response) => {
            if (response) {
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((e) => {
            NotificationManager.error(e.datail, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().bitacora.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const filter_by_type = (type) => (dispatch, getStore) => {
    dispatch(setSearchByType(type));
    dispatch(listar());
};

const set_from = (from) => (dispatch, getStore) => {
    if (from) dispatch(setFrom(moment(from).format("YYYY-MM-DD")));
    else dispatch(setFrom(""));
    dispatch(listar());
};

const set_to = (to) => (dispatch, getStore) => {
    if (to) dispatch(setTo(moment(to).format("YYYY-MM-DD")));
    else dispatch(setTo(""));
    dispatch(listar());
};

export const actions = {
    getBitacora,
    listar,
    searchChange,
    onSortChange,
    filter_by_type,
    set_from,
    set_to,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH_BY_TYPE]: (state, { tipo }) => {
        return {
            ...state,
            tipo,
        };
    },
    [DESDE]: (state, { desde }) => {
        return {
            ...state,
            desde,
        };
    },
    [HASTA]: (state, { hasta }) => {
        return {
            ...state,
            hasta,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    tipo: -1,
    desde: "1990-12-12",
    hasta: "",
    ordering: "",
    search: "",
};

export default handleActions(reducers, initialState);
