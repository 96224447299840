import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ChangeForm from "./ChangeForm"
import LoadMask from "../../Utils/LoadMask/LoadMask";

class ChangePassword extends Component {
    onSubmitReset = (data) => {
        this.props.resetPassword({...data, token: this.props.match.params.token});
    }    

    render() {
        const { loader } = this.props;
        
        if (localStorage.getItem("token")) {
            return <Redirect to="/" />;
        }
        return (
            <div className="bg-login ">
                <div className="card-login-posision col-lg-4 col-md-6 col-sm-7 col-11 px-4 ">
                    <a href="#" className="text-dark">
                        <samp>
                            <i class="fa fa-chevron-left"></i>
                        </samp>
                        Regresar
                    </a>

                    <div className="card card-login mt-1">
                    <div className="d-table ">
                            <img
                                id="main-logo-recuperar"
                                className="d-inline-block align-top mr-1"
                                src={require("assets/img/1-Contraseña@2x.png")}
                                alt="Logo"
                            />
                        </div>
                        <h5 className="pt-2">Nueva Contraseña</h5>
                        <p>
                            Ingresa tu nueva contraseña, que estarás usando de ahora en adelante.
                        </p>
                        <p>
                            También podrás ingresar desde la app móvil con tus nuevas credenciales
                        </p>
                        <LoadMask loading={loader} light>
                            <ChangeForm onSubmit={this.onSubmitReset} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
