import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import moment from "moment";
import { MUNICIPIOS } from "../../../common/components/Utils/constant";

const SUBMIT = "SUBMIT";
const LOADER = "LOADER";
const ACTIVITIES = "SET_ACTIVITIES";
const ACTIVITY = "SET_ACTIVITY";
const PAGE = "PAGE_INSTITUTION";
const ORDERING = "ORDERING_INSTITUTION";
const SEARCH = "SEARCH_INSTITUTION";
const FILTER_MUNICIPALITY = "FILTER_MUNICIPALITY";
const SET_COMMUNITIES = "SET_COMMUNITIES";
const SET_FILTERED_COMMUNITIES = "SET_FILTERED_COMMUNITIES";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setActivities = (activities) => ({
    type: ACTIVITIES,
    activities,
});

export const setActivity = (activity) => ({
    type: ACTIVITY,
    activity,
});
const setPage = (page) => ({
    type: PAGE,
    page,
});
const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});
const setMunicipality = (municipios) => ({
    type: FILTER_MUNICIPALITY,
    municipios,
});
const setCommunities = (comunidades) => ({
    type: SET_COMMUNITIES,
    comunidades,
});
const setFilteredCommunities = (comunidades_filtradas) => ({
    type: SET_FILTERED_COMMUNITIES,
    comunidades_filtradas,
});

// ------------------------------------
// Actions
// ------------------------------------

export const listarActividades = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().activities;   
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;

    dispatch(setLoader(true));
    api.get("activities", params)
        .then((response) => {
            dispatch(setActivities(response));
            dispatch(setPage(page));
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrio un error al cargar las actividades",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getActivity = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`activities/${id}`)
        .then((activity) => {
            if (activity.project) activity.project = activity.project.id;
            dispatch(filtrarComunidades(activity.municipality));
            dispatch(filtrarMunicipio(activity.departament));
            activity.community = activity.community;
            if (activity.institution)
                activity.institution = activity.institution.id;

            dispatch(setActivity(activity));
            dispatch(initializeForm("form_new_acivity", activity));
        })
        .catch((error) => {
            console.log("Este es el error------", error);
            let msj =
                "Error al intentar obtener el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const nuevaActividad = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const formData = getStore().form.form_new_acivity.values;
    formData.description = formData.description ? formData.description : "";

    api.post("activities", formData)
        .then((response) => {
            NotificationManager.success(
                "Actividad creada con éxito",
                "Éxito",
                3000
            );
            dispatch(push("/actividades"));
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(
                "ocurrio un error al guardar la actividad",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const updateActividad = (id, insumos_eliminar) => (
    dispatch,
    getStore
) => {
    dispatch(setLoader(true));
    const formData = getStore().form.form_new_acivity.values;
    formData.insumos_eliminar = insumos_eliminar;

    api.put(`activities/${id}`, formData)
        .then((response) => {
            NotificationManager.success(
                "Actividad actualizada con exito",
                "Éxito",
                3000
            );
            dispatch(push("/actividades"));
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(
                "ocurrio un error al actualizar la actividad",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const deleteActividad = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.eliminar(`activities/${id}`)
        .then((response) => {
            dispatch(listarActividades());
            dispatch(setActivities(response));
            NotificationManager.success(
                "Actividad eliminada con exito",
                "Éxito",
                3000
            );
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(e.detail, "ERROR", 0);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const searchChange = (search) => (dispatch) => {    
    dispatch(setSearch(search));
    dispatch(listarActividades());
};

const onDeleteFilters = () => (dispatch) => {
    dispatch(setSearch(""));
    dispatch(listarActividades());
}

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().usuariosAdmin.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarActividades());
};

export const filtrarComunidades = (label) => (dispatch, getStore) => {
    const COMUNIDADES = getStore().beneficiarios.comunidades;
    const comunidades = [];
    COMUNIDADES.map((item) => {
        if (item.municipality == label) {
            comunidades.push({ value: item.id, label: item.name });
        }
    });
    dispatch(setFilteredCommunities(comunidades));
    dispatch(change("form_new_acivity", "community", "00"));
};

export const filtrarMunicipio = (label) => (dispatch) => {
    const municipios = [{ value: "00", label: "ninguno" }];
    dispatch(change("form_new_acivity", "municipality", "00"));
    dispatch(change("form_new_acivity", "community", "00"));

    MUNICIPIOS.map((item) => {
        if (item.departamento == label) {
            municipios.push({ value: item.id, label: item.nombre });
        }
    });
    dispatch(setMunicipality(municipios));
};

export const getCommunities = () => (dispatch) => {
    api.get("/comunidades/raw")
        .then((response) => {
            dispatch(setCommunities(response));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const finalizar = (data) => (dispatch) => {
    api.post("/activities/finalizar", data)
        .then(() => {
            console.log('exito')
            // dispatch(setCommunities(response));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const actions = {
    listarActividades,
    deleteActividad,
    nuevaActividad,
    updateActividad,
    getActivity,
    searchChange,
    onSortChange,
    filtrarComunidades,
    getCommunities,
    filtrarMunicipio,
    finalizar,
    onDeleteFilters
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ACTIVITIES]: (state, { activities }) => {
        return {
            ...state,
            activities,
        };
    },
    [ACTIVITY]: (state, { activity }) => {
        return {
            ...state,
            activity,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTER_MUNICIPALITY]: (state, { municipios }) => {
        return {
            ...state,
            municipios,
        };
    },
    [SET_COMMUNITIES]: (state, { comunidades }) => {
        return {
            ...state,
            comunidades,
        };
    },
    [SET_FILTERED_COMMUNITIES]: (state, { comunidades_filtradas }) => {
        return {
            ...state,
            comunidades_filtradas,
        };
    },
};

export const initialState = {
    loader: false,
    activities: {
        results: [],
        count: 0,
    },
    activity: {},
    page: 1,
    ordering: "",
    search: "",
    municipios: [],
    comunidades_filtradas: [],
    comunidades: [],
};

export default handleActions(reducers, initialState);
