import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import RecoverPassForm from "./RecoverPasswordForm";
import LoadMask from "../../Utils/LoadMask/LoadMask";

class RecoverPass extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem("token")) {
            return <Redirect to="/" />;
        }
        return (
            <div className="bg-login ">
                <div className="card-login-posision col-lg-4 col-md-6 col-sm-7 col-11 px-4 ">
                    <a href="#" className="text-dark">
                        <samp>
                            <i class="fa fa-chevron-left"></i>
                        </samp>{" "}
                        Regresar
                    </a>

                    <div className="card card-login mt-1">
                        <div className="d-table ">
                            <img
                                id="main-logo-recuperar"
                                className="d-inline-block align-top mr-1"
                                src={require("assets/img/2-Correo@2x.png")}
                                alt="Logo"
                            />
                        </div>
                        <h5 className="pt-2">Recuperar contraseña</h5>
                        <p>
                            Por favor ingresa tú nombre de usuario, se te
                            enviará a tu correo registrado las instrucciones y un enlace temporal para
                            poder cambiar tu contraseña.
                        </p>
                        <LoadMask loading={loader} light>
                            <RecoverPassForm onSubmit={onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecoverPass;
