import React, { Component } from "react";
import ProjectForm from "./ProjectForm";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { getIntitutions } from "../../../../../utility/functions";

class ProjectCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            institutions: null,
        };

        this._getIntitutions = this._getIntitutions.bind(this);
    }

    async _getIntitutions() {
        const data = await getIntitutions();
        this.setState({ institutions: data });
    }

    componentWillMount() {
        //obtenemos esta funcion de los props, está en redux.
        const { leer, match } = this.props;
        this._getIntitutions();
        //
        let id = null;
        if (!window.location.href.includes("crear")) id = match.params.id;
        //si encontramos el id en la url, entonces se va a editar el registro
        if (id) {
            this.setState({ edit: true });
            leer(id);
        }
    }

    _editar = (data) => {
        if (data.institutions)
            data.institutions = data.institutions.map((e) => e.id);
        this.props.editar(data);
    };
    _crear = (data) => {
        if (data.institutions)
            data.institutions = data.institutions.map((e) => e.id);
        this.props.crear(data);
    };

    render() {
        const { loader } = this.props;
        const { edit, institutions } = this.state;
        let action = {};
        let disabled = false;
        if (window.location.href.includes("editar")) action = this._editar;
        else if (window.location.href.includes("crear")) action = this._crear;
        else disabled = true;

        return (
            <div className="card my-4">
                <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={loader} blur>
                        <ProjectForm
                            onSubmit={action}
                            disabled={disabled}
                            edit={edit}
                            institutions={institutions}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ProjectCreate;
