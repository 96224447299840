import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './searchInput.css';
import icono from '../../../../../assets/img/icons/search.svg';

class SearchInput extends Component {
    static propTypes = {
        search: PropTypes.string.isRequired,
        searchChange: PropTypes.func.isRequired,
    };

    
      

    render() {
        const { search, searchChange, placeholder } = this.props;
        
        return (
            <div className="toolbar-search container-icono">
                <input
                    className="form-control search-input pl-4 "
                    /* style={{backgroundImage: `url(${icono})`}}  */
                    id="buscador"
                    type="text"
                    placeholder={placeholder ? placeholder : "Buscar..."}
                    ref={(node) => {
                        this.buscar = node;
                        if (this.buscar) {
                            this.buscar.value = search;
                        }
                    }}
                    onChange={() => {
                        if (this.buscar) {
                            searchChange(this.buscar.value);
                        }
                    }}
                    autoComplete="off"
                />
                <div className="icon-search" >
                    <i className="fa fa-search text-11"></i>
                </div>
            </div>
        );
    }
}

export default SearchInput;
