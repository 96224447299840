import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/comunidades";
// import { getFormMeta, getFormSyncErrors } from 'redux-form';
import ListCommunity from "./List";

const ms2p = (state) => {
    return {
        ...state.comunidades,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ListCommunity);
