import React, { Fragment } from "react";
import moment from "moment";
import { Prev, Current } from "./index";

const DetalleBeneficiario = ({ item }) => {
    let detail = item.detail ? JSON.parse(item.detail) : null;
    let previous = item.previous_detail
        ? JSON.parse(item.previous_detail)
        : null;

    console.log("DETAIL", detail);
    console.log("PREV", previous);

    return (
        <Fragment>
            {/* estado anterior */}
            {previous && (
                <div className="w-100 mb-5 mb-lg-0">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.created).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0">Anterior</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #3B3B41" }}
                    />

                    <div className="col-12">
                        <Prev title="Nombre" value={previous.name} />
                        <Prev title="Nacimiento" value={previous.birth_date} />
                        <Prev title="Edad" value={previous.age} />
                        <Prev title="DPI" value={previous.dpi} />
                        <Prev title="Telefono" value={previous.phone} />
                        <Prev
                            title="Discapacidad"
                            value={previous.disability}
                        />
                        <Prev
                            title="Institución"
                            value={previous.institution.name}
                        />
                        {previous.spouse && (
                            <Fragment>
                                <h6 className="my-3">Conyugue</h6>
                                <Prev
                                    title="Nombre"
                                    value={previous.spouse.name}
                                />
                                <Prev
                                    title="Edad"
                                    value={previous.spouse.age}
                                />
                                <Prev
                                    title="Discapacidad"
                                    value={previous.spouse.disability}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            )}
            {previous && <div className="mx-5" />}
            {/* estado actual */}
            {detail && (
                <div className="w-100">
                    <div className="d-flex flex-column">
                        <label className="font-weight-bold">Fecha y hora</label>
                        <label className="text-primary">
                            {moment(item.modified).format(
                                "DD/MM/YYYY-h:mm:ss a"
                            )}
                        </label>
                    </div>
                    <div className="py-2" />
                    <h5 className="p-0 text-primary">Actual</h5>
                    <div
                        className="mb-4"
                        style={{ borderTop: "5px solid #008FF1" }}
                    />

                    <div className="col-12">
                        <Current
                            title="Nombre"
                            value={detail.name}
                            prev={previous ? previous.name : null}
                        />
                        <Current
                            title="Nacimiento"
                            value={detail.birth_date}
                            prev={previous ? previous.birth_date : null}
                        />
                        <Current
                            title="Edad"
                            value={detail.age}
                            prev={previous ? previous.age : null}
                        />
                        <Current
                            title="DPI"
                            value={detail.dpi}
                            prev={previous ? previous.dpi : null}
                        />
                        <Current
                            title="Telefono"
                            value={detail.phone}
                            prev={previous ? previous.phone : null}
                        />
                        <Current
                            title="Discapacidad"
                            value={detail.disability}
                            prev={previous ? previous.disability : null}
                        />
                        <Current
                            title="Institución"
                            value={detail.institution.name}
                            prev={previous ? previous.institution.name : null}
                        />

                        {detail.spouse && (
                            <Fragment>
                                <h6 className="my-3">Conyugue</h6>
                                <Current
                                    title="Nombre"
                                    value={detail.spouse.name}
                                    prev={previous ? previous.spouse.name : null}
                                />
                                <Current
                                    title="Edad"
                                    value={detail.spouse.age}
                                    prev={previous ? previous.spouse.age : null}
                                />
                                <Current
                                    title="Discapacidad"
                                    value={detail.spouse.disability}
                                    prev={previous ? previous.spouse.disability : null}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default DetalleBeneficiario;
