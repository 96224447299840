import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
                   
                         
                <div className="bg-login">                
                   {/*  <img className="bg-login-form mx-auto" src={require('assets/img/bg-login.png')} alt="Login"/> */}
                    
                    <div className="login-form col-lg-3 col-md-4 col-11  card-login-posision">
                        <a  href="#" className="w-100 mr-0  navbar-brand navbar-brand-login" >
                            <div className="d-table m-auto">
                                <img id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require('assets/img/logo-AEA-guatemala.png')}
                                    alt="Logo" />
                            </div>
                        </a>
                        <LoadMask loading={loader} light>
                            
                            <LoginForm onSubmit={onSubmit} />
                            <div className="container">                                
                                <div className="row justify-content-center pt-2 text-samp">
                                    <span>¿Olvidaste tu contraseña?&nbsp;<Link to="/recuperar">Recuperar</Link></span>                                    
                                </div>
                            </div>
                            
                           
                        </LoadMask>
                    </div>
                </div>
          
        );
    }
}

export default Login;
