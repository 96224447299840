import React, { Component, Fragment } from "react";
import CommunityTable from "./Grid";
import Header from "../../../Utils/Grid/Header";
import { DEPARTAMENTOS_SELECT, MUNICIPIOS } from "../../../Utils/constant";
import { SelectField } from "../../../Utils/renderField/renderField";
import { Field, reduxForm } from "redux-form";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CreateCommunity from "../Create";

const Filtro1 = ({ municipios, onChange, onChangeM, change }) => {
    return (
        <div className="d-flex flex-col" style={{ width: 350 }}>
            <div className="w-50 mr-1">
                <label className="font-weight-bold mb-0">Departamento:</label>
                <Field
                    name="departament"
                    placeholder="Departamento"
                    options={DEPARTAMENTOS_SELECT}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => {
                        change("municipio", "00");
                        onChange(value);
                    }}
                />
            </div>
            <div className="w-50 ml-1">
                <label className="font-weight-bold mb-0">Municipio:</label>
                <Field
                    name="municipio"
                    placeholder="Municipio"
                    options={municipios}
                    component={SelectField}
                    className="form-control"
                    onChange={(e, value) => onChangeM(value)}
                />
            </div>
        </div>
    );
};

class ListCommunity extends Component {
    state = {
        municipios: [],
        departament: "",
        municipality: "",
        new_disabled: true,
        modal_open: false,
        id: null,
    };

    toggleModal = (id) => {
        if (this.state.modal_open) {
            const { listar, page } = this.props;
            const { departament, municipality } = this.state;
            if (departament==="00"){
                listar(page)
            }
            else if (municipality==="00"){
                listar(page, {departament});
            }
            else{
                listar(page, {departament, municipality});
            }
        }
        this.setState({ modal_open: !this.state.modal_open, id });
    };

    componentDidMount() {
        const { onDeleteFilter, listar, page } = this.props;
        onDeleteFilter(page);
    }

    format = (id, type) => {
        if (type === "DEPARTAMENT") {
            return DEPARTAMENTOS_SELECT.find((e) => e.value === id).label;
        } else {
            return MUNICIPIOS.find((e) => e.id === id).nombre;
        }
    };

    onChange = (value) => {
        const { page, listar } = this.props;
        let label = value.departament ? value.departament : value;
        if (label !== "00") {
            const municipios = [{ value: "00", label: "ninguno" }];
            MUNICIPIOS.map((item) => {
                if (item.departamento === label) {
                    municipios.push({ value: item.id, label: item.nombre });
                }
            });
            this.setState({
                municipios,
                departament: label,
                new_disabled: true,
            });
            listar(1, { departament: label });
        } else {
            this.setState({ municipios: [{ value: "00", label: "ninguno" }], new_disabled: true });
            this.setState({departament : label})
            listar(1, {});
        }
    };

    onChangeM = (value) => {
        const { page, listar } = this.props;
        const { departament } = this.state;
        this.setState({
            new_disabled: value == "00" ? true : false,
            departament,
            municipality: value,
        });
        if (value==="00"){
            listar(1, { departament });
        }
        else{
            listar(1, { departament, municipality: value });
        }
    };

    render() {
        const { municipios, id, modal_open } = this.state;
        const {listar, page} = this.props
        return (
            <Fragment>
                <div className="mt-4">
                    <Header
                        filtro1={
                            <label className="text-primary font-weight-bold">
                                <span className="material-icons align-middle">
                                    info
                                </span>{" "}
                                Para crear una comunidad selecciona un
                                Departamento, un Municipio y presiona 'Nuevo'
                            </label>
                        }
                    />
                    <Header
                        filtro1={
                            <Filtro1
                                municipios={municipios}
                                onChange={this.onChange}
                                onChangeM={this.onChangeM}
                                change={this.props.change}
                            />
                        }
                        filtro2={
                            <button
                                disabled={this.state.new_disabled}
                                onClick={() => this.toggleModal(null)}
                                className="btn btn-primary m-1 align-self-start px-5 font-weight-bold mt-4"
                            >
                                Nuevo
                            </button>
                        }
                        {...this.props}
                    />
                </div>
                <div className="mb-4 card card-small">
                    <div className="p-0">
                        <CommunityTable
                            {...this.props}
                            toggleModal={this.toggleModal}
                            onDelete={this.props.eliminar}
                            municipality={this.state.municipality}
                            departament={this.state.departament}
                        />
                    </div>
                </div>

                <Modal isOpen={modal_open} className="mx-lg-auto mx-md-3 mx-2">
                    <ModalHeader className="card ">Crear comunidad</ModalHeader>
                    <ModalBody className="car mt-3">
                        <CreateCommunity
                            municipality={this.state.municipality}
                            departament={this.state.departament}
                            municipios={municipios}
                            id={id}
                            onClose={this.toggleModal}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

// export default ListCommunity;

export default reduxForm({
    form: "listar_form", // a unique identifier for this form
})(ListCommunity);
