import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import ExcelForm from './ExcelForm';


class BeneficiarioExcel extends Component{
        render(){  
        const {loader, registerExcel, cerrarModal} = this.props;
        return(
            <div className="card my-4">
            <div className="py-4 my-2 col-12 col-sm-10 col-xl-9 mx-auto " style={{border: "2px solid"}}>
                <LoadMask loading={loader} blur >
                    <ExcelForm
                        onSubmit={registerExcel}
                        create={true}
                        cerrarModal={cerrarModal}
                    />
                   
                </LoadMask>
            </div>
        </div>
        )
    };
}

export default BeneficiarioExcel;