import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/token', data).then((response) => {

        if(response.user.profile.role == 30){
            NotificationManager.error('No tienes permiso para acceder', 'ERROR', 0);
            dispatch(logOut())
            return 0
        }

        localStorage.setItem('token', response.token);
        //guardamos en el local storage la bandera para saber si es la primera vez que se loguea       
        if(response.user.profile.first_time) {
            localStorage.setItem('profile_first_time', true);
        }
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch(() => {
        NotificationManager.error('Credenciales incorrectas, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
    })
        .catch(() => {
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    console.log("Entra en primera vez");
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    localStorage.removeItem('token');
    localStorage.removeItem('profile_first_time');
};

export const resetPassword = (data={}) =>(dispatch)=>{  
    dispatch(setLoader(true));
    api.post('user/reset_password/',data).then((response)=>{
        NotificationManager.success('Tu contraseña se reestablecio correctamente.')
        dispatch(setMe(response))
        dispatch(push('/'))
    }).catch(()=>{
        NotificationManager.error('Error al restablecer la contraseña, vuelva a intentar.', 'ERROR', 5000)
    }).finally(()=>{
        dispatch(setLoader(false));
    });
}

export const changePassword = (data={}) =>(dispatch)=>{   
     dispatch(setLoader(true));
    api.post('user/change_password',data).then((response)=>{
        NotificationManager.success('Tu contraseña se reestablecio correctamente.')
        dispatch(setMe(response));      
        dispatch(logOut());
        dispatch(push('/'))
    }).catch(()=>{
        NotificationManager.error('Error al restablecer la contraseña, vuelva a intentar.', 'ERROR', 5000)
    }).finally(()=>{
        dispatch(setLoader(false));
    }); 
}
export const actions = {
    onSubmit,
    logOut,
    resetPassword,
    changePassword
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
