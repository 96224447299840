import React, { Component } from "react";
import Form from "../../../Commons/FormUsuarios";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import { getIntitutions } from "../../../../../utility/functions";

class FormUsers extends Component {
    _isMounted = false;
    state = {
        editar: false,
        institutions: [],
        is_superadmin: false
    };

    componentDidMount() {
        // verifica si existe un id en la ruta y obtiene el usuario correspondiente
        const { id } = this.props.match.params;
        if (id) {
            this.props.getUser(id);
            this.setState({ editar: true });
        }
        if (this.props.me && this.props.me.profile.role === 10) this.setState({ is_superadmin: true });
        this._getIntitutions();
    }

    _getIntitutions = async () => {
        this._isMounted = true;
        // obtiene todas las instituciones sin paginar y filtradas segun la institucion del usuario logueado
        const { institution_name } = this.props.me;
        const institutions = await getIntitutions(institution_name);

        if (this._isMounted) this.setState({ institutions });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    onSave = () => {
        // segun el state 'editar' llama la funcion update o create
        const { id } = this.props.match.params;
        if (this.state.editar) {
            // a editar solo se le indica el id del usuario
            this.props.updateUser(id);
        } else {
            // para crear se debe indicar con que rol se creara el usaurio
            this.props.createUser(this.props.roles.ADMIN);
        }
    };

    render() {
        return (
            <div className="card my-4">
                <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={this.props.loader} dark blur>
                        <Form
                            editar={this.state.editar}
                            url="/administradores"
                            onSubmit={this.onSave}
                            institutions={this.state.institutions}
                            is_admin={this.state.is_superadmin}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default FormUsers;
