import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, RecoverPassword, ChangePassword, ChangePassFT} from './common/components/LoginRecover'
import Home from './common/components/App/Home/container';
import ProtectedRoute from './ProtectedRoute';
import ProtectedRoute2 from './common/components/LoginRecover/Primera_vez/ProtectedRoute2';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
import Modals from './common/components/Examples/Modal/Modal';

require('../style/index.css');

// Instituciones
import {CreateInstitution, ListInstitution} from './common/components/App/Institutions';
// administracion
import { UsuariosAdmin, FormUsersAdmin} from './common/components/App/Administradores'
// tecnicos
import { UsuariosTecnicos, FormUsersTecnicos} from './common/components/App/Tecnicos'
// actividades
import { ListActivities, NewActivity } from './common/components/App/Activities'

import settingsContainer from './common/components/App/Global_settings/settingsContainer';
// bitacoras
import { ListarBitacoras, DetalleBitacora } from './common/components/App/Bitacora'
// beneficiarios2
import { ListarBeneficiarios,CrearBenefiario, SubirBeneficiario, ListarBeneficiariosRepetidos} from './common/components/App/Beneficiarios' 
// comunidades
import { ListCommunity } from './common/components/App/Comunidades'
// reportes de actividades
import { ListReporteActividades, DetalleReporteActividad } from './common/components/App/ReporteActividades'
// proyectos
import { CreateProjet, ListProjects } from './common/components/App/Proyectos'
//politicas
 import Politicas from './common/components/politicas/politicas'; 

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />                
                <Route exact path="/recuperar" component={RecoverPassword} />
                <Route exact path="/cambiar_password/:token" component={ChangePassword} />
                <Route exact path="/primera_vez" component={ChangePassFT} />
                <Route exact path="/politicas-de-privacidad" component={Politicas} /> 
                <ProtectedRoute exact path="/" title="Inicio" component={Home} />
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
                <ProtectedRoute exact path="/modal" component={ Modals } />
                {/* --------------- componentes de la app  ----------------------------*/}                
                <ProtectedRoute exact path="/institucion" title="Administración / instituciones"component={ListInstitution}/>
                <ProtectedRoute exact path="/institucion/crear" title="Administración / instituciones / nuevo" component={CreateInstitution}/>
                <ProtectedRoute exact path="/institucion/:id/editar" title="Administración / instituciones / actualizar" component={CreateInstitution}/>
                <ProtectedRoute exact path="/institucion/:id" title="Administración / instituciones / ver" component={CreateInstitution}/>

                <ProtectedRoute exact path="/administradores" title="Administración / usuarios admin" component={ UsuariosAdmin } />
                <ProtectedRoute exact path="/administradores/nuevo" title="Administración / usuarios admin / nuevo" component={ FormUsersAdmin } />
                <ProtectedRoute exact path="/administradores/:id/editar" title="Administración / usuarios admin / actualizar" component={ FormUsersAdmin } />
                <ProtectedRoute exact path="/administradores/:id" title="Administración / usuarios admin / ver" component={ FormUsersAdmin } />
              
                <ProtectedRoute exact path="/tecnicos" title="Administración / usuarios técnicos"  component={ UsuariosTecnicos } />
                <ProtectedRoute exact path="/tecnicos/nuevo" title="Administración / usuarios técnicos / nuevo"  component={ FormUsersTecnicos } />
                <ProtectedRoute exact path="/tecnicos/:id/editar" title="Administración / usuarios técnicos / actualizar" component={ FormUsersTecnicos } />
                <ProtectedRoute exact path="/tecnicos/:id" title="Administración / usuarios técnicos / ver" component={ FormUsersTecnicos } />
               
                <ProtectedRoute exact path="/actividades" title="Administración / actividades"  component={ ListActivities } />
                <ProtectedRoute exact path="/actividades/nuevo"  title="Administración / actividades / nuevo" component={ NewActivity } />
                <ProtectedRoute exact path="/actividades/:id/editar"  title="Administración / actividades / actualizar" component={ NewActivity } />
                <ProtectedRoute exact path="/actividades/:id"  title="Administración / actividades / ver" component={ NewActivity } />
                
                <ProtectedRoute exact path="/reporte/actividades" title="Reporte actividades" component={ListReporteActividades} />
                <ProtectedRoute exact path="/reporte/actividades/:id/" title="Detalle actividad" component={DetalleReporteActividad} />

                <ProtectedRoute exact path="/configuracion"  title="Administración / configuración" component={settingsContainer}/>

                <ProtectedRoute exact path="/beneficiario" title="Beneficiarios" component={ ListarBeneficiarios } /> 
                <ProtectedRoute exact path="/beneficiario/nuevo" title="Beneficiarios / nuevo" component={ CrearBenefiario } />
                <ProtectedRoute exact path="/beneficiario/:id" title="Beneficiarios / ver" component={ CrearBenefiario } />
                <ProtectedRoute exact path="/beneficiario/:id/editar" title="Beneficiarios / actualizar" component={ CrearBenefiario } />
                <ProtectedRoute exact path="/beneficiario/subir-excel" title="Beneficiarios / suibir excel" component={ SubirBeneficiario } />
                <ProtectedRoute exact path="/repetidos/beneficiario" title="Beneficiarios / Listado de beneficiarios" component={ ListarBeneficiariosRepetidos } />
              

                <ProtectedRoute exact path="/bitacoras" title="Reporte bitácora" component={ ListarBitacoras } />
                <ProtectedRoute exact path="/bitacoras/:id" title="Reporte bitácora / detalle" component={ DetalleBitacora } />

                <ProtectedRoute exact path="/comunidades" title="Comunidades" component={ ListCommunity } />

                <ProtectedRoute exact path="/proyectos" title="Administración / proyectos"  component={ ListProjects } />
                <ProtectedRoute exact path="/proyectos/crear"  title="Administración / proyectos / nuevo" component={ CreateProjet } />
                <ProtectedRoute exact path="/proyectos/:id/editar"  title="Administración / proyectos / actualizar" component={ CreateProjet } />
                <ProtectedRoute exact path="/proyectos/:id"  title="Administración / proyectos / ver" component={ CreateProjet } />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
