import React, { Component } from "react";
import { Field, reduxForm, change, formValueSelector} from "redux-form";
import { Link } from "react-router-dom";
import { SelectField, AsyncSelectField } from "Utils/renderField/renderField";
import { NotificationManager } from "react-notifications";
import { api } from "../../../../../utility/api";
import { connect } from 'react-redux'
// formularios
import FormMujerHombre from "./FormMujerHombre";
import FormJoven from "./FormJoven";
import FormNino from "./FormNino";
import FormNinoApadrinado from "./FormNinoApadrinado";
import moment from "moment";

let tipobeneficiario = [
   /*  { label: "Mujer", value: 10 },
    { label: "Hombre", value: 20 },
    { label: "Niño apadrinado", value: 30 },
    { label: "Joven Beneficiario", value: 40 },
    { label: "Joven voluntario", value: 50 }, */
];

const getInstitution = ({ institution_name } = this.props.me) => {
    const institutions = [];
    let params = {};
    if (institution_name) params = { search: institution_name };
    return api
        .get("/institution/raw", params)
        .then((response) => {
            if (response) {
                response.forEach((institution) => {
                    institutions.push({
                        value: institution.id,
                        label: institution.name,
                    });
                });
                return institutions;
            }
        })
        .catch((error) => {
            return [];
        });
};
class Form extends Component {
    state = {
        formSelect: 0,
        genero_value:0,
        genero_label:""
        // comunidades: [],
    };

    componentDidMount() {
        setTimeout(() => {
            if (this.props.editar) {
                tipobeneficiario = [
                    { label: "Mujer", value: 10 },
                    { label: "Hombre", value: 20 },
                    { label: "Niño apadrinado", value: 30 },
                    { label: "Joven Beneficiario", value: 40 },
                    { label: "Joven voluntario", value: 50 },
                ];
            } else {
                tipobeneficiario = [
                    { label: "Mujer", value: 10 },
                    { label: "Hombre", value: 20 },
                    { label: "Joven Beneficiario", value: 40 },
                    { label: "Joven voluntario", value: 50 },
                ];
            }
        }, 10);

        this.props.filtrarComunidades("");
        this.props.filtrarMunicipio("");
    }
    _onChange(label){        
        this.props.seleccionarGenero(label);
        this.setState({ formSelect: label });
    }
    render() {
        const { handleSubmit, editar, tipo_beneficiario } = this.props;
        const { formSelect,genero_select } = this.state;
        let disabled = true;
        const url = window.location.href;
        if (url.includes("editar") || url.includes("nuevo")) disabled = false;
        return (
            <React.Fragment>
                {/* aviso de campos olbigatorios */}
                <div className="row justify-content-end mb-1">
                    <p className="col-12 text-primary font-weight-bold">
                        Todos los campos que lleven (*) Son requeridos.
                    </p>
                </div>

                <form
                    name="form_beneficiario"
                    className="form-validate mb-lg padding "
                    onSubmit={handleSubmit}
                >
                    <div className=" mb-3 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12 mb-4">
                                <div className="form-group has-feedback">
                                    <label
                                        htmlFor="institucion"
                                        className="font-weight-bold"
                                    >
                                        Institucion:{" "}
                                        <span className="text-primary font-weight-bold">
                                            *
                                        </span>
                                    </label>
                                    <Field
                                        name="institution"
                                        label="institucion"
                                        placeholder="Instituciones."
                                        component={AsyncSelectField}
                                        loadOptions={getInstitution}
                                        className="form-control"
                                        disabled={editar}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-12 mb-4">
                                <div className="form-group has-feedback">
                                    <label
                                        className="font-weight-bold"
                                        htmlFor="user"
                                    >
                                        Tipo:{" "}
                                        <span className="text-primary font-weight-bold">
                                            *
                                        </span>
                                    </label>
                                    <Field
                                        disabled={editar}
                                        name="type_beneficiary"
                                        options={tipobeneficiario}
                                        component={SelectField}
                                        onChange={(value, label) =>
                                           this._onChange(label)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12 ">
                            {!editar
                                ? (formSelect && formSelect <= 20 && ( 
                                    <FormMujerHombre {...this.props} formSelect={formSelect} />
                                   
                                  )) ||
                                  (formSelect && formSelect == 30 && (
                                      <FormNinoApadrinado {...this.props}  />
                                  )) ||
                                  (formSelect && formSelect >= 40 && (
                                      <FormJoven {...this.props} />
                                  )) ||
                                  (!formSelect && <div></div>)
                                : (tipo_beneficiario &&
                                      tipo_beneficiario <= 20 && (
                                          <FormMujerHombre {...this.props} disabled={disabled} /* formSelect={tipo_beneficiario} */ />
                                      )) ||
                                  (tipo_beneficiario &&
                                      tipo_beneficiario == 30 && (
                                          <FormNinoApadrinado {...this.props} disabled={disabled}/>
                                      )) ||
                                  (tipo_beneficiario &&
                                      tipo_beneficiario >= 40 && (
                                          <FormJoven {...this.props} disabled={disabled}/>
                                      )) ||
                                  (!tipo_beneficiario && <div></div>)}
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <Link
                                className="btn btn-light w-100 mb-4 mb-md-2"
                                to="/beneficiario"
                            >
                                {!disabled ? "Cancelar" : "Regresar"}
                            </Link>
                        </div>
                        {!disabled && (
                            <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 mb-4 mb-md-2"
                                >
                                    {" "}
                                    {!editar ? "Guardar" : "Actualizar"}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const validate = (values, getStore) => {
    const errors = {};

    if (!values.institution) {
        errors.institution = "Campo requerido";
    }
    if (!values.type_beneficiary) {
        errors.type_beneficiary = "Campo requerido";
    }
    if (!values.name) {
        errors.name = "Campo requerido";
    }
    if (
        values.phone &&
        values.phone.length !== 8 &&
        values.type_beneficiary <= 20
    ) {
        errors.phone = "El telefono debe tener 8 digitos";
    }
    if (
        values.has_dpi && 
        values.dpi &&
        values.dpi.length !== 13 &&
        values.type_beneficiary <= 20
    ) {
        errors.dpi = "El DPI debe tener 13 digitos";
    }
    if (values.has_dpi && !values.dpi && values.type_beneficiary <= 20) {
        errors.dpi = "Campo requerido";
    }
    if (!values.age && !values.birth_date) {
        errors.age =
            "La edad es requerido si no proporciono la fecha de nacimiento";
    }
    if (!values.gender && values.type_beneficiary >= 30) {
        errors.gender = "Campo requerido";
    }
    if (!values.etnia) {
        errors.etnia = "Campo requerido";
    }
    //validación de la direccion
    //const addressErrors = {};

    if (!values.departament || values.departament == "00") {
        errors.departament = "campo requerido";
    }
    if (!values.municipality || values.municipality == "00") {
        errors.municipality = "campo requerido";
    }
    if (!values.community || values.community == "00") {
        errors.community = "campo requerido";
    }
    if (!values.genero) {
        errors.genero = "campo requerido";
    }
    // if (!values.address || !values.address.comunity) {
    //     //calcula la edad en meses del niño
    //     addressErrors.comunity = "campo requerido";
    // }
    //errors.address = addressErrors;

    //validar las fechas de nacimiento de los hijos
    if (!values.hijos || !values.hijos.length) {
        errors.hijos = { _error: "Debe de haber al menos un niño" };
    } else {
        const childBenediciaryErrors = [];
        values.hijos.forEach((child, childIndex) => {
            const childErrors = {};
            //el formulario se tendra que validar solo si esta en la opcion de
            //"niño apadrinado" de lo contrario no sera requerido
            if (getStore.es_apadrinado == true) {
                if (!child || !child.name) {
                    childErrors.name = "Campo requerido";
                    childBenediciaryErrors[childIndex] = childErrors;
                }
                if (!child || !child.birth_date) {
                    childErrors.birth_date = "Campo requerido";
                    childBenediciaryErrors[childIndex] = childErrors;
                }
                if (!child || !child.is_sponsored) {
                    childErrors.is_sponsored = "Campo requerido";
                    childBenediciaryErrors[childIndex] = childErrors;
                }
                if (!child || !child.disability) {
                    childErrors.disability = "Campo requerido";
                    childBenediciaryErrors[childIndex] = childErrors;
                }
            }
            if (!child || child.birth_date) {
                //calcula la edad en meses del niño
                console.log("Edad seleccionada", child.birth_date);
                var hoy = moment();
                var birth = moment(child.birth_date);
                var edad = hoy.diff(birth, "months");
                child.age = edad;
            }
        });

        if (childBenediciaryErrors.length) {
            errors.hijos = childBenediciaryErrors;
        }
        //Se elimina el objeto cuando el el tipo de formulario no se
        //el de niño apadrinado, para que dejen de ser campos requeridos
        if (getStore.es_apadrinado == false) {
            delete errors.hijos;
        }
    }

    if(values.spouse && values.type_beneficiary <= 20){
        if(values.spouse.disability || values.spouse.name || values.spouse.age){
            if(!values.spouse.name){
                errors.spouse = {...errors.spouse, name: 'Campo requerido'}
            }
            if(!values.spouse.age){
                errors.spouse = {...errors.spouse, age: 'Campo requerido'}
            }
            if(!values.spouse.disability){
                errors.spouse = {...errors.spouse, disability: 'Campo requerido'}
            }
        }
    }
    return errors;
};

Form = reduxForm({
    form: "form_beneficiario", // Identificador de formulario
    validate: validate,
    onSubmitFail: () => {
        NotificationManager.warning(
            "Debe llenar todos los campos obligatorios",
            "Advertencia",
            5000
        );
    },
})(Form);

const selector = formValueSelector('form_beneficiario')
Form = connect(state => {
    const has_dpi = selector(state, "has_dpi")
    
    return {
        has_dpi
    }
})(Form)

export default Form
