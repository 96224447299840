import React, { Component, Fragment } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import {
    DEPARTAMENTOS_SELECT,
    MUNICIPIOS,
} from "../../../Utils/constant";

class CommunityTable extends Component {
    format = (id, type) => {
        if (type === "DEPARTAMENT") {
            return DEPARTAMENTOS_SELECT.find((e) => e.value === id).label;
        } else {
            return MUNICIPIOS.find((e) => e.id === id).nombre;
        }
    };

    listar = (page) =>{
        let {listar, municipality, departament} = this.props;
        if (municipality==="00"){
            municipality="";
        }
        if (departament==="00"){
            departament=""
        }
        listar(page, {departament, municipality});
    }
    render() {
        const {
            data,
            loader,
            page,
            listar: onPageChange,
            onSortChange,
            onDelete,
            toggleModal,
        } = this.props;

        return (
            <Grid
                hover
                striped
                data={data}
                loading={loader}
                onPageChange={(page)=> this.listar(page)}
                onSortChange={onSortChange}
                page={page}
            >
                <TableHeaderColumn
                    width="15%"
                    dataField="id"
                    dataAlign="center"
                    dataSort
                    dataFormat={standardActions({
                        // editar: "comunidades",
                        editar2: (id) => {
                            toggleModal(id);
                        },
                        eliminar: onDelete,
                    })}
                >
                    Acciones
                </TableHeaderColumn>

                <TableHeaderColumn isKey dataField="name" dataSort>
                    Comunidad
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="departament"
                    dataSort
                    dataFormat={(cell) => {
                        return this.format(cell, "DEPARTAMENT");
                    }}
                >
                    Departamento
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="municipality"
                    dataSort
                    dataFormat={(cell) => {
                        return this.format(cell, "MUNICIPALITY");
                    }}
                >
                    Municipio
                </TableHeaderColumn>
            </Grid>
        );
    }
}

export default CommunityTable;
