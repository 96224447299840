import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

const RecoverPassForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            
            <div className="form-group has-feedback">
                <label htmlFor="username" className="font-weight-bold">Nombre de usuario:</label>
                <Field 
                    name="username" 
                    label="username"
                    requerid
                    component={renderField}
                    type="text"
                    className="form-control"
                    placeholder="Ingrese su username"
                />
            </div>
            
            <div className="buttons-box pt-3">
                <button type="submit" className="btn btn-primary m-1 align-self-center px-5 font-weight-bold">Enviar</button>
            </div>
        </form>
    );
};

export const matchPassword = (pass, confirm) => validatorFromFunction(value => {
    return pass === confirm;
});

export default reduxForm({
    form: 'recover_pass', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
               validators.exists()('Este campo es requerido'),
               matchPassword(data.password, data.confirmPassword)()('Las contraseñas no coinciden')
            ),
            username: validators.exists()('Este campo es requerido'),
            first_name: validators.exists()('Este campo es requerido'),
            last_name: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(RecoverPassForm);
