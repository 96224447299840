import React, { Component } from "react";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CommunityForm from "./Form";

class CreateCommunity extends Component {
    state = {
        create: true,
    };

    componentWillMount() {
        // obtenemos esta funcion de los props, está en redux.
        const { leer, id } = this.props;
        // si encontramos el id en loa url, entonces se va a editar el registro
        if (id) {
            this.setState({ create: false });
            leer(id);
        }
    }

    render() {
        const { create } = this.state;
        const { loader, editar, crear } = this.props;
        const action = !create ? editar : crear;
        return (
            <div className="card my-4">
                <div className="py-3 col-12 col-sm-10 col-xl-9 mx-auto">
                    <LoadMask loading={loader} blur>
                        <CommunityForm
                            onClose={this.props.onClose}
                            create={create}
                            onSubmit={action}
                            {...this.props}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default CreateCommunity;
