import React, { Component } from 'react';

import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import '../Modal/modal.css';

export default class Modals extends Component {
  
  state={
    abierto: false,
  }
  //Para utilizar el modal en algun formualrio o en otra situación, se tiene que utilizar redux
 
  abrirModal=()=>{
    this.setState({abierto: !this.state.abierto});
  }
  render() {
    const modalStyles={
      position: "absolute",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }

    return (
      <React.Fragment>
      <div className="card py-4 mt-4  col-6">
        <div className="mx-auto">
          <h1>Modal ejemplo </h1>
          <button  className="btn btn-primary btn-sm mr-1" onClick={this.abrirModal}>Mostrar Modal</button>
        </div>

       <Modal isOpen={this.state.abierto} style={modalStyles} >
        <ModalHeader className="card ">          
            <div className="titulo-modal">
              <h4 >Titulo</h4>
            </div>
        </ModalHeader>
        <ModalBody className="card mt-3">
          <div className="container">
            <div className="mt-3">
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing 
                elit. Dolores, voluptatem? Commodi nisi magnam in maxime. 
                Illum odit officia debitis voluptas deleniti similique quasi. 
                Suscipit impedit facilis quia nam, qui odio!</p>
            </div>
            <div className="center">
              <button className="btn btn-primary btn-sm mr-1" onClick={this.abrirModal}>Aceptar</button>
              <button className="btn btn-dark btn-sm mr-1" onClick={this.abrirModal}>Cerrar</button>
            </div>
          </div>
        </ModalBody>        
      </Modal>

      </div>
     
     

      </React.Fragment>
      
    
    );
  }
}
