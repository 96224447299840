import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import "./sidebar.css";

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            dropdownOpenR: false,
        };
    }

    toggle = (select) => {
        if (select == 1) {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
        } else if (select == 2) {
            this.setState({ dropdownOpenR: !this.state.dropdownOpenR });
        }
        // this.props.navToggle()
    };

    deactivateSM = () => {
        this.setState({ dropdownOpen: false, dropdownOpenR: false });
        this.props.navToggle();
    };

    render() {
        const { toggleOpen, navToggle, logOut, user } = this.props;
        return (
            <aside
                className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 ${
                    toggleOpen ? "" : "open"
                }`}
            >
                <div className="main-navbar">
                    <nav className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light">
                        <a href="#" className="w-100 mr-0 navbar-brand">
                            <div className="d-table m-auto">
                                <img
                                    id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require("assets/img/logo-AEA-guatemala.png")}
                                    alt="Logo"
                                />
                            </div>
                        </a>
                        <a
                            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                            onClick={navToggle}
                        >
                            <i className="material-icons"></i>
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <ul className="nav--no-borders flex-column nav">
                        <li className="nav-item">
                            <NavLink
                                onClick={this.deactivateSM}
                                to="/"
                                exact
                                className="nav-link font-weight-bold"
                                activeclassname={"active"}
                            >
                                <div className="d-flex align-items-center">
                                    <div className="d-inline-block item-icon-wrapper">
                                        <i
                                            id="IconInicio"
                                            className="inline-block align-top mr-1"
                                        >
                                            Home
                                        </i>
                                    </div>
                                    <span>Inicio</span>
                                </div>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink
                                onClick={this.deactivateSM}
                                to="/beneficiario"
                                exact
                                className="nav-link font-weight-bold"
                                activeclassname={"active"}
                            >
                                <div className="d-flex align-items-center">
                                    <div className="d-inline-block item-icon-wrapper">
                                        <i
                                            id="IconBeneficiario"
                                            className="inline-block align-top mr-1"
                                        >
                                            Beneficiario
                                        </i>
                                    </div>
                                    <span>Beneficiarios</span>
                                </div>
                            </NavLink>
                        </li>

                        {/* menu reportes */}
                        <li className="nav-item">
                            <Dropdown
                                isOpen={this.state.dropdownOpenR}
                                toggle={() => {}}
                            >
                                <DropdownToggle
                                    onClick={() => this.toggle(2)}
                                    color=""
                                    className="nav-link font-weight-bold"
                                    activeclassname={"active"}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="d-inline-block item-icon-wrapper">
                                            <i
                                                id="IconBitacoras"
                                                className="inline-block align-top mr-1"
                                            >
                                                Admin
                                            </i>
                                        </div>
                                        <span>Reportes </span>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/bitacoras"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Bitácora</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/reporte/actividades"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Actividades e insumos</span>
                                        </NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        {/* menu admin */}
                        <li className="nav-item">
                            <Dropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={() => {}}
                            >
                                <DropdownToggle
                                    onClick={() => this.toggle(1)}
                                    color=""
                                    className="nav-link font-weight-bold"
                                    activeclassname={"active"}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="d-inline-block item-icon-wrapper">
                                            <i
                                                id="IconAdmin"
                                                className="inline-block align-top mr-1"
                                            >
                                                Admin
                                            </i>
                                        </div>
                                        <span>Administración </span>
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/administradores"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Administradores</span>
                                        </NavLink>
                                    </DropdownItem>
                                    {user && user.profile && user.profile.role == 10 && (
                                        <DropdownItem>
                                            <NavLink
                                                onClick={navToggle}
                                                to="/institucion"
                                                className="nav-link font-weight-bold sub-menu"
                                                activeclassname={"active"}
                                            >
                                                <span>Instituciones</span>
                                            </NavLink>
                                        </DropdownItem>
                                    )}
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/comunidades"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Comunidades</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/proyectos"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Proyectos</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/actividades"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Actividades</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/configuracion"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Configuración</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/tecnicos"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Técnicos</span>
                                        </NavLink>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavLink
                                            onClick={navToggle}
                                            to="/repetidos/beneficiario"
                                            className="nav-link font-weight-bold sub-menu"
                                            activeclassname={"active"}
                                        >
                                            <span>Duplicados</span>
                                        </NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        {/* logout */}
                        <div className="border-sidebar"></div>
                        <li className="nav-item mb-5">
                            <Link
                                to="/login"
                                onClick={logOut}
                                className="nav-link font-weight-bold"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="d-inline-block item-icon-wrapper">
                                        <i
                                            id="IconLogOut"
                                            className="inline-block align-top mr-1"
                                        >
                                            Admin
                                        </i>
                                    </div>
                                    <span>Salir</span>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
        );
    }
}

export default SideBar;
