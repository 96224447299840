import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/asignacionesProyecto";
import Asignacion from "./Asignacion";

const ms2p = (state) => {
    return {
        ...state.asignacionesProyectos,
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Asignacion);
