import React, { Component, Fragment } from "react";
import { Field } from "redux-form";
import {
    DEPARTAMENTOS_SELECT,
    ETNIAS_SELECT,
    GENEROS,
} from "../../../Utils/constant";
import {
    renderField,
    renderDatePicker,
    renderNumber,
    renderFieldRadio,
    renderFilePicker,
    SelectField,
} from "Utils/renderField/renderField";

class FormJoven extends Component {
    change = (value, label) => {
        this.props.filtrarMunicipio(label);
    };

    render() {
        let date = new Date();
        const { disabled, photo, municipios, filtrarComunidad } = this.props;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-row align-items-center">
                            <label
                                className="font-weight-bold mr-5"
                                htmlFor="photo"
                            >
                                Selec foto
                            </label>
                            <Field
                                disabled={disabled}
                                name="photo"
                                label="photo"
                                photo={photo ? photo : null}
                                accept="image/*"
                                component={renderFilePicker}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="name">
                            Nombre:{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="name"
                            placeholder="Nombre"
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="birth_date"
                        >
                            Fecha nacimiento:{" "}
                            <span className="text-primary  font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="birth_date"
                            maxDate={date}
                            component={renderDatePicker}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="age">
                            Edad:
                        </label>
                        <Field
                            disabled={disabled}
                            name="age"
                            placeholder="Edad"
                            component={renderNumber}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label className="font-weight-bold" htmlFor="phone">
                            Teléfono:
                        </label>
                        <Field
                            disabled={disabled}
                            name="phone"
                            placeholder="Teléfono"
                            component={renderNumber}
                            className="form-control"
                            numberFormat="(502) #### - ####"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.departament"
                        >
                            Sexo:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="gender"
                            placeholder="Sexo"
                            options={GENEROS}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.departament"
                        >
                            Etnia:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="etnia"
                            placeholder="Etnia del beneficiario"
                            options={ETNIAS_SELECT}
                            component={SelectField}
                            className="form-control"
                            /* onChange={this.change} */
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="disability"
                        >
                            Discapacidad:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <fieldset className="flex d-flex">
                            <Field
                                disabled={disabled}
                                type="radio"
                                name="disability"
                                label="Si"
                                value="si"
                                component={renderFieldRadio}
                            />
                            <div className="px-2" />
                            <Field
                                disabled={disabled}
                                type="radio"
                                name="disability"
                                label="No"
                                value="no"
                                component={renderFieldRadio}
                            />
                        </fieldset>
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.departament"
                        >
                            Departamento:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="departament"
                            placeholder="Departamento"
                            options={DEPARTAMENTOS_SELECT}
                            component={SelectField}
                            className="form-control"
                            onChange={this.change}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.municipaplity"
                        >
                            Municipio:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="municipality"                           
                            placeholder="Municipio"
                            options={municipios}
                            component={SelectField}
                            className="form-control"
                            onChange={(v, l) => this.props.filtrarComunidades(l)}
                            
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                        <label
                            className="font-weight-bold"
                            htmlFor="address.comunity"
                        >
                            Comunidad o aldea:{" "}
                            <span className="text-primary font-weight-bold">
                                *
                            </span>
                        </label>
                        <Field
                            disabled={disabled}
                            name="community"
                            placeholder="comunidad"
                            options={this.props.comunidades}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default FormJoven;
