import React, { Component } from "react";
import moment from "moment";
import QRCode from "react-qr-code";
import "./carnet_styles.css";
import {
    DEPARTAMENTOS_SELECT,
    MUNICIPIOS,
} from "../../../Utils/constant";

class Hijo extends Component {
    state = {
        formated_data: "",
    };

    componentDidMount() {
        const { hijo } = this.props;
        let data = {
            id: hijo.id,
            name: hijo.name,
            age: hijo.age,
            birth_date: moment(hijo.birth_date).format("MM/DD/YYYY"),
            disability: hijo.disability,
        };
        this.setState({ formated_data: JSON.stringify(data) });
    }

    render() {
        const { hijo } = this.props;
        return (
            <div className="col-6">
                <div id="hijo" className="w-full row">
                    <div className="col-5 d-flex flex-column align-items-end">
                        <label>{hijo.name}</label>
                        <label>fecha nacimiento</label>
                        <label>
                            {moment(hijo.birth_date).format("DD/MM/YYYY")}
                        </label>
                    </div>
                    <div className="col-6">
                        <QRCode size={225} value={this.state.formated_data} />
                    </div>
                </div>
            </div>
        );
    }
}

class Carnet extends Component {
    state = {
        formated_data: "",
    };

    format = (id, type) => {
        if (type === "DEPARTAMENT") {
            const temp = DEPARTAMENTOS_SELECT.find((e) => e.value === id)
            return temp? temp.label : 'wait...';
        } else {
            const temp = MUNICIPIOS.find((e) => e.id === id)
            return temp? temp.nombre : 'wait...';
        }
    };

    componentDidMount() {       
        setTimeout(() => {
            const { item } = this.props;
            let data = {
                id: item.id,
                name: item.name,
                age: item.age,
                birth_date: moment(item.birth_date).format("MM/DD/YYYY"),
                phone: item.phone,
                dpi: item.dpi,
                type_beneficiary: item.type_beneficiary,
            };
            this.setState({ formated_data: JSON.stringify(data) });
        }, 100);
        
    }

    render() {
        const { item } = this.props;
        
        return (
            <div  id="c-container" >
                <div id="c-header">
                    <div className="row mb-3">
                        <div className="col-3">
                            <img
                                id="main-logo"
                                src={require("assets/img/logo-AEA-blanco.png")}
                                alt="Logo"
                            />
                        </div>
                        <div className="col-9 align-items-center justify-content-center d-flex">
                            <h1 className="text-white text-center">
                                {item.name}
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <QRCode
                                size={225}
                                value={this.state.formated_data}
                            />
                        </div>
                        <div className="col-9  pl-5 d-flex align-items-center">
                            <div className="col-12">
                                <div id="info" className="row">
                                    <div className="col-6 col-info">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>
                                                    fecha de nacimiento:
                                                </label>
                                            </div>
                                            <div className="col-6">
                                                <label>
                                                    {moment(
                                                        item.birth_date
                                                    ).format("DD/MM/YYYY")}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Edad:</label>
                                            </div>
                                            <div className="col-6">
                                                <label>{item.age}</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Departamento:</label>
                                            </div>
                                            <div className="col-6">
                                                <label>
                                                    {this.format(
                                                        item.departament,
                                                        "DEPARTAMENT"
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-info">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Municipio</label>
                                            </div>
                                            <div className="col-6">
                                                <label>
                                                    {this.format(
                                                        item.municipality,
                                                        "MUNICIPALITY"
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Comunidad</label>
                                            </div>
                                            <div className="col-6">
                                                <label>
                                                    {item.community_name}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(item.hijos && item.hijos.length > 0) && (
                    <div id="hijos" className="mx-5">
                        <h6>Hijas/os</h6>
                        <div className="row">
                            {item.hijos.map((item, index) => (
                                <Hijo key={item.id} hijo={item} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Carnet;
