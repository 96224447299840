import React, { Component } from "react";
import { Field } from "redux-form";
import { SelectField,renderDatePicker } from "Utils/renderField/renderField";
import {
    DEPARTAMENTOS_SELECT,
  }  from "../../../Utils/constant";

const Departamento = ({ onChange }) => {   
    return (
        <Field
            name="departament"
            placeholder="Departamento"
            options={DEPARTAMENTOS_SELECT}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};

const Municipio = ({ municipios, onChange }) => {    
    return (
        <Field
            name="municipality"
            placeholder="Municipio"
            options={municipios}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Comunidad = ({comunidades_filtradas, onChange }) => {
    return (
        <Field
            name="community"
            placeholder="Comunidad"
            options={comunidades_filtradas}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Genero = ({onChange }) => {
    const generos=[
        {value:'00', label:'Ninguno'},
        {value:'10', label:'Mujer'},
        {value:'20', label:'Hombre'},]
    return (
        <Field
            name="gender"
            placeholder="Genero:"
            options={generos}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Institucion = ({institucion, onChange }) => {    
    return (
        <Field
            name="institutions"
            placeholder="Institución:"
            options={institucion}
            component={SelectField}
            className="form-control"
            onChange={(e, value) => onChange(value)}   
        />
    );
};
const Desde = ({setDatestart}) => {    
    return (
        <Field
            onChange={(e, value) => setDatestart(value)}
            name="fecha_inicio"
            placeholder="Desde"
            component={renderDatePicker}
        />
    );
};
const Hasta = ({setDateEnd }) => {   
    return (
        <Field
            onChange={(e, value) => setDateEnd(value)}
            name="fecha_fin"
            placeholder="hasta"
            component={renderDatePicker}
        />
    );
};
class Filtros extends Component {
    _departamento = (value) => {   
        this.props.filtrarMunicipio(value, true);
        //10 para saber que se quiere filtrar los beneficiarios
        this.props.filtro_departamento(value,10);
    };
    _municipio = (value) => {   
        this.props.filtrarComunidades(value, true);
        this.props.filtro_municipio(value,10);
    };
    _comunidad = (value) => {
        this.props.filtro_comunidad(value,10);
    };
    _genero = (value) => {
        this.props.filtro_genero(value,10);
    };
    _institucion = (value) => {
        this.props.filtro_institucion(value,10);
    };
    _desde = (value) => {
        this.props.filtro_desde(value);
    };
    _hasta = (value) => {
        this.props.filtro_hasta(value,10);
    };
    render(){
        const {
            municipios,            
            comunidades_filtradas,
            institucion, 
        }= this.props;
        return(
            <div className=" row pl-0 pr-0">
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 "  >
                    <label className="mb-0 pt-2">Departamento:</label>
                    <Departamento  onChange={this._departamento} />
                </div>
                <div className="col-12 col-sm-6  col-lg-12 col-md-6 ">
                    <label className="mb-0 pt-2">Municipio:</label>
                    <Municipio municipios={municipios} onChange={this._municipio} />
                </div>
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 " >
                    <label className="mb-0 pt-2">Comunidad:</label>
                    <Comunidad  comunidades_filtradas={comunidades_filtradas} onChange={this._comunidad} />
                </div>
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 ">
                    <label className="mb-0 pt-2">Sexo:</label>
                    <Genero onChange={this._genero} />
                </div>
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 " >
                    <label className="mb-0 pt-2">Organización:</label>
                    <Institucion institucion={institucion} onChange={this._institucion} />
                </div>
                <div className="row col-12 pt-2 ">
                    <div className="col-12">
                        <h6 className="mb-0">Por fechas</h6>
                        <div className="dropdown-divider m-0" />
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 ">
                    <label className="mb-0 pt-2">Desde:</label>
                    <Desde  setDatestart ={this._desde} />
                </div>
                <div className="col-12 col-sm-6 col-lg-12 col-md-6 " >
                    <label className="mb-0 pt-2">Hasta:</label>
                    <Hasta  setDateEnd={this._hasta} />
                </div>
                    
            </div>
        );
    }
}
export default Filtros
