import React, { Component }  from "react";
import { Field, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import { renderField } from "../../Utils/renderField";
import "./changePassword.css";


class RecoverPassForm extends Component {
    constructor(props) {
        super(props);
        this.state={show_pass: false}
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState(state => ({
            show_pass: !state.show_pass
        }));
    }
    
    render() {
        const { handleSubmit } = this.props;
        //variables para cambiar el estado del icono para ver la contraseña
        let tipo = this.state.show_pass ? 'text' : 'password';
        let tipo_icon = this.state.show_pass ? 'fa fa-eye tx-16' : 'fa fa-eye-slash tx-16';
       
        return (
            <form
                name="loginForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <div className="form-group has-feedback">
                    <label htmlFor="password" className="font-weight-bold">Contraseña:</label>
                    <div className="container-icono">
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type= {`${tipo}`} 
                            className="form-control"
                        />
                        <div className="icon">
                            <i class={`${tipo_icon}`} onClick={this.handleClick} ></i>
                        </div>
                    </div>
                </div>
                <div className="form-group has-feedback ">
                    <label htmlFor="confirmPassword" className="font-weight-bold">
                        Confirmar Contraseña:
                    </label>

                    <div className="container-icono">
                        <Field
                            name="confirmPassword"
                            label="Confirmar Contraseña"
                            component={renderField}
                            type= {`${tipo}`}
                            className="form-control"
                        />
                        <div className="icon">
                            <i class={`${tipo_icon}`} onClick={this.handleClick}></i>
                        </div>
                    </div>
                </div>
                <div className="buttons-box pt-3">
                    <button
                        type="submit"
                        className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                    >
                        Restaurar
                    </button>
                </div>
            </form>
        );
    }
}

export const matchPassword = (pass, confirm) =>
    validatorFromFunction((value) => {
        return pass === confirm;
});


export default reduxForm({
    form: "change_pass", // nombre unico del formulario
    validate: (data) => {
        return validate(data, {
            confirmPassword: combine(
                validators.exists()("Este campo es requerido"),
                matchPassword(data.password, data.confirmPassword)()(
                    "Las contraseñas no coinciden"
                ),
                validators.length({ min: 8 })("8 caracteres como mínimo")
            ),
            password: combine(
                validators.exists()("Este campo es requerido"),
                validators.length({ min: 8 })("8 caracteres como mínimo")
            ),
        });
    },
})(RecoverPassForm);
