
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField } from "../../Utils/renderField";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = { show_pass: false };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState((state) => ({
            show_pass: !state.show_pass,
        }));
    }
    render() {
        const { handleSubmit, pristine, reset, submitting } = this.props;
        //variables para cambiar el estado del icono para ver la contraseña
        let tipo = this.state.show_pass ? "text" : "password";
        let tipo_icon = this.state.show_pass
            ? "fa fa-eye tx-16"
            : "fa fa-eye-slash tx-16";
        return (
            <div className="container ">
                <form
                    name="loginForm"
                    className="form-validate mb-lg margen-form"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group has-feedback">
                        <label htmlFor="username" className="font-weight-bold">
                            Usuario
                        </label>
                        <Field
                            name="username"
                            placeholder="Ingrese su usuario"
                            label="Usuario"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback">
                        <label htmlFor="password" className="font-weight-bold">
                            Contraseña
                        </label>
                        <div className="container-icono">
                            <Field
                                name="password"
                                label="Contraseña"
                                component={renderField}
                                type={`${tipo}`}
                                className="form-control"
                                placeholder="Ingrese contraseña"
                            />
                            <div className="icon">
                                <i
                                    className={`${tipo_icon}`}
                                    onClick={this.handleClick}
                                ></i>
                            </div>
                        </div>
                    </div>

                    <div className="buttons-box">
                        <button
                            type="submit"
                            className="btn btn-primary m-1 align-self-center px-5 font-weight-bold"
                        >
                            Iniciar sesión
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default reduxForm({
    form: "login", // a unique identifier for this form
    validate: (data) => {
        
        return validate(data, {
            
            username: validators.exists()("Este campo es requerido"),
            password: validators.exists()("Este campo es requerido"),
        });
    },
})(LoginForm);
