import React, { Fragment } from "react";

export { default as DetalleInstitucion } from "./DetalleInstitucion";
export { default as DetalleUsuario } from "./DetalleUsuario";
export { default as DetalleActividad } from "./DetalleActividad";
export { default as DetalleBeneficiario } from "./DetalleBeneficiario";
export { default as Detallehijo } from "./DetalleHijo";

export const Prev = ({ title, value }) => {
    if (value) {
        return (
            <div className="row justify-content-between">
                <div className="col-5 col-xl-4">
                    <label className="font-weight-bolder text-right w-100">
                        {title}
                    </label>
                </div>
                <div className="col-7">
                    <label className="">{value}</label>
                </div>
            </div>
        );
    } else {
        return <Fragment></Fragment>;
    }
};

export const Current = ({ title, value, prev, isPrev }) => {
    if (value) {
        return (
            <div
                className={`row justify-content-between ${
                    (prev && prev !== value) || (!prev && isPrev !== undefined)
                        ? "text-warning"
                        : "text-secondary"
                }`}
            >
                <div className="col-5 col-xl-4">
                    <label className="font-weight-bolder text-right w-100">
                        {title}
                    </label>
                </div>
                <div className="col-7">
                    <label className="">{value}</label>
                </div>
            </div>
        );
    } else {
        return <Fragment></Fragment>;
    }
};
