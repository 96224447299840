import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/beneficiarios";
import { getFormMeta, getFormSyncErrors } from 'redux-form';
import CrearBenefiario from "./Crear";

const ms2p = (state) => {
    return {
        ...state.beneficiarios,   
        me: state.login.me,
        metaForm: getFormMeta('form_beneficiario')(state),
        formSyncErrors: getFormSyncErrors('form_beneficiario')(state),
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(CrearBenefiario);

