import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { api } from "api";
import moment from "moment";
import {MUNICIPIOS} from "../../../common/components/Utils/constant";

//onst SUBMIT = "SUBMIT";
const LOADER = "LOADER";
const DATA = "DATA_BENEFICIARY";
const DATA_REPETIDOS = "DATA_BENEFICIARY_REPEAT";
const DATA_REPETIDOS_INSTITUCION = "DATA_BENEFICIARY_REPEAT_INSTITUTION";
const ITEM_DATA = "ITEM_BENEFICIARY";
const PHOTO = "PHOTO_BENEFICIARY";
const PAGE = "PAGE_BENEFICIARY";
const ORDERING = "ORDERING_BENEFICIARY";
const SEARCH = "SEARCH_BENEFICIARY";
const SET_TYPE_BENEFICIARY = "TYPE_BENEFICIARY";
const FILTER_MUNICIPALITY = "FILTER_MUNICIPALITY";
const SET_COMMUNITIES = "SET_COMMUNITIES";
const SET_FILTERED_COMMUNITIES = "SET_FILTERED_COMMUNITIES";
const SET_FILTERED_INSTITUTIONS = "SET_FILTERED_INSTITUTIONS";
const SET_FILTERED_TYPE_BENEFICIARY = "SET_FILTERED_TYPE_BENEFICIARY";
const DESDE = "DESDE";
const HASTA = "HASTA";
const IS_OPEN_MODAL = "IS_OPEN_MODAL";
const DATA_PARA_CARNETS = "DATA_PARA_CARNETS";
const DEPARTAMENT = "DEPARTAMENT";
const MUNICIPALITY = "MUNICIPALITY";
const COMMUNITY = "COMMUNITY";

// ------------------------------------
// Pure Actions
// ------------------------------------
const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data,
});

const setDataRepetidos = (data_repetidos) => ({
    type: DATA_REPETIDOS,
    data_repetidos,
});

const setDataRepetidosInstitucion = (data_repetidos_institucion) => ({
    type: DATA_REPETIDOS_INSTITUCION,
    data_repetidos_institucion,
});
const setDataParaCarnets = (data_para_carnets) => ({
    type: DATA_PARA_CARNETS,
    data_para_carnets,
});
const setItem = (item) => ({
    type: ITEM_DATA,
    item,
});

const setPhoto = (photo) => ({
    type: PHOTO,
    photo,
});

const setTyepeBeneficiary = (tipo_beneficiario) => ({
    type: SET_TYPE_BENEFICIARY,
    tipo_beneficiario,
});
const setPage = (page) => ({
    type: PAGE,
    page,
});
//------------ filtros ----------------
const setOrdering = (ordering) => ({
    type: ORDERING,
    ordering,
});

const setSearch = (search) => ({
    type: SEARCH,
    search,
});
const setMunicipality = (municipios) => ({
    type: FILTER_MUNICIPALITY,
    municipios,
});
const setCommunities = (comunidades) => ({
    type: SET_COMMUNITIES,
    comunidades,
});
const setFilteredCommunities = (comunidades_filtradas) => ({
    type: SET_FILTERED_COMMUNITIES,
    comunidades_filtradas,
});

const setFilteredInstitutions = (institucion) => ({
    type: SET_FILTERED_INSTITUTIONS,
    institucion,
});
const setFilteredTypeBeneficiary = (type_beneficiary) => ({
    type: SET_FILTERED_TYPE_BENEFICIARY,
    type_beneficiary,
});

const setFrom = (desde) => ({
    type: DESDE,
    desde,
});
const setTo = (hasta) => ({
    type: HASTA,
    hasta,
});

const setOpenModal = (is_open) => ({
    type: IS_OPEN_MODAL,
    is_open,
});
const setFilteredDepartament = (departamento) => ({
    type: DEPARTAMENT,
    departamento,
});
const setFilteredMunicipality = (municipio) => ({
    type: MUNICIPALITY,
    municipio,
});
const setFilteredCommunity = (comunidad) => ({
    type: COMMUNITY,
    comunidad,
});

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm("form_beneficiario", {}));
};
export const cleanState = () => (dispatch) => {
    const municipios = [];
    dispatch({ type: PHOTO, photo: "" });
    dispatch(setMunicipality(municipios));
};
export const open_modal = (is_open) => (dispatch) => {
    dispatch(setOpenModal(is_open));
};
export const activate_loader = (is_active) => (dispatch) => {
    dispatch(setLoader(is_active));
};
// ------------------------------------
// Actions
// ------------------------------------
//------------------------- filtrado para departamentos, municipios y comunidades -------------------------------
export const filtrarComunidades = (label, para_filtro = false) => (
    dispatch,
    getStore
) => {
    const COMUNIDADES = getStore().beneficiarios.comunidades;
    const comunidades = [{ value: "00", label: "ninguno" }];

    COMUNIDADES.map((item) => {
        if (item.municipality == label) {
            comunidades.push({ value: item.id, label: item.name });
        }
    });

    dispatch(setFilteredCommunities(comunidades));
    dispatch(change("form_beneficiario", "community", "00"));
    dispatch(change("beneficiario_reporte_form", "community", "00"));

};

export const filtrarMunicipio = (label, para_filtro = false) => (dispatch) => {
    dispatch(filtrarComunidades(""));
    dispatch(change("form_beneficiario", "municipality", "00"));
    dispatch(change("form_beneficiario", "community", "00"));
    dispatch(change("beneficiario_reporte_form", "municipio", "00"));
    dispatch(change("beneficiario_reporte_form", "community", "00"));


    const municipios = [{ value: "00", label: "ninguno" }];
    MUNICIPIOS.map((item) => {
        if (item.departamento == label) {
            municipios.push({ value: item.id, label: item.nombre });
        }
    });
    dispatch(setMunicipality(municipios));
};

export const seleccionarGenero = (label) => (dispatch) => {
    if(label==10){
        dispatch(change("form_beneficiario", "gender", 10));
    }else if(label==20){
        dispatch(change("form_beneficiario", "gender", 20));
    } else{
        dispatch(change("form_beneficiario", "gender", 0));
    }
};

export const getCommunities = () => (dispatch) => {
    api.get("/comunidades/raw")
        .then((response) => {
            dispatch(setCommunities(response));
            //console.log(response);
        })
        .catch((error) => {
            //console.log(error);
        });
};

export const register = () => (dispatch, getStore) => {
    const formData = getStore().form.form_beneficiario.values;
    dispatch(setLoader(true));
    // //Formateo la fecha del beneficiario
    formData.birth_datef = moment(formData.birth_date).format("YYYY-MM-DD");
    // //formateo las fechas de los hijos
    if (
        undefined != formData.hijos &&
        Object.entries(formData.hijos[0]).length != 0
    ) {
        for (let i = 0; i < formData.hijos.length; i++) {
            //creamos otra variable con la fecha formateada
            formData.hijos[i].birth_datef = moment(
                formData.hijos[i].birth_date
            ).format("YYYY-MM-DD");
        }
    }
    api.post("beneficiario", formData)
        .then(() => {
            NotificationManager.success(
                "Beneficiario creado con exito",
                "Éxito",
                3000
            );
            dispatch(cleanForm());
            dispatch(push("/beneficiario"));
        })
        .catch((error) => {
            //console.log(error);
            let msj =
                "Error al intentar registrar un nuevo beneficiario, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().beneficiarios;
    const {me} = getStore().login;

    const params = { page };
    params.ordering = "name";
    params.search = resource.search;
    // params.institucion = resource.institucion;
    params.type_beneficiary = resource.type_beneficiary;

    if (resource.desde != "") params.fecha_inicio = resource.desde;
    if (resource.hasta != "") params.fecha_fin = resource.hasta;
    if (resource.departamento != "-1")
        params.departament = resource.departamento;
    if (resource.municipio != "-1") params.municipality = resource.municipio;
    if (resource.comunidad != -1) params.community = resource.comunidad;
    if (me && me.profile && me.profile.role != 10) params.institucion = me.profile.institution;
    else params.institucion = resource.institucion;

    dispatch(setLoader(true));
    api.get("/beneficiario", params)
        .then((response) => {
            if (response) {
                dispatch(setData(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            let msj =
                "Error al intentar listar los beneficiarios, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const update = (hijos_eliminar) => (dispatch, getStore) => {
    // dispatch(setLoader(true));
    dispatch(setLoader(true));
    const formData = getStore().form.form_beneficiario.values;

    // Formateo la fecha del beneficiario
    formData.birth_datef = moment(formData.birth_date).format("YYYY-MM-DD");
    formData.hijos_eliminar = hijos_eliminar;
    formData.age = Number(formData.age);
    formData.etnia = Number(formData.etnia);
    // formData.address = {
    //     departament: formData.departament,
    //     municipality: formData.municipality,
    // };

    //formateo las fechas de los hijos
    if (
        undefined != formData.hijos &&
        undefined != formData.hijos[0] &&
        Object.entries(formData.hijos[0]).length != 0
    ) {
        for (let i = 0; i < formData.hijos.length; i++) {
            //creamos otra variable con la fecha formateada
            formData.hijos[i].birth_datef = moment(
                formData.hijos[i].birth_date
            ).format("YYYY-MM-DD");
        }
    }

    const id = formData.id;
    api.put(`beneficiario/${id}`, formData)
        .then(() => {
            NotificationManager.success(
                "Beneficiario actualizado con exito",
                "Éxito",
                3000
            );
            dispatch(push("/beneficiario"));
        })
        .catch((error) => {
            let msj =
                "Error al intentar actualizar el beneficiario, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const getBeneficiario = (id) => async (dispatch) => {
    dispatch(setLoader(true));

    function sumarDias(fecha, dias){
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }

    api.get(`beneficiario/${id}`)
        .then((response) => {
            response.institution = {
                value: response.institution.id,
                label: response.institution.name,
            };

            //Se formatea la fecha a tipo fecha para los campos respectivos, tnato para los hijos de  un beneficiario
            // por alguna razon se le resta un dia al hacer la conversion asi que se vuelve a sumar manualmente
            let a = new Date(response.birth_date);
            response.birth_date = sumarDias(a, 1);

            if (undefined !== response.hijos && response.hijos.length) {
                for (let i = 0; i < response.hijos.length; i++) {
                    let formDate = new Date(
                        response.hijos[i].birth_date
                    );
                    response.hijos[i].birth_date = sumarDias(formDate, 1)
                    response.hijos[i].is_sponsored = response.hijos[i]
                        .is_beneficiary
                        ? "si"
                        : "no";
                }
            }
            dispatch(setTyepeBeneficiary(response.type_beneficiary));
            //Primero se filtran losmunicipios, si no, no inicializa el campo de mnunicipio
            dispatch(filtrarMunicipio(response.departament));
            //Si tiene foto inicializamos la variable donde contendra la url de la misma
            if (response.photo) {
                dispatch(setPhoto(response.photo));
            }
            dispatch(filtrarComunidades(response.community.municipality));
            response.community = response.community.id;
            //console.log(response)
            dispatch(setItem(response));
            //se inicializa el formualrio
            dispatch(initializeForm("form_beneficiario", response));
        })
        .catch((error) => {
            console.log("Este es el error------", error);
            let msj =
                "Error al intentar obtener el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const remove = (id) => (dispatch) => {
    api.eliminar(`/beneficiario/${id}/delete_beneficiary`)
        .then(() => {
            NotificationManager.success(
                "Beneficiario eliminado con exito",
                "Éxito",
                3000
            );
            dispatch(listar());
        })
        .catch((error) => {
            if (error) {
                let msj =
                    "Error al intentar elimianr el beneficiario, vuelva a intentar";
                if (error.detail) {
                    msj = error.detail;
                }
                NotificationManager.error(msj, "ERROR", 5000);
            }
        });
};
export const get_beneficiarios_carnets = (page=1) => async (dispatch,getStore) => {
    const resource = getStore().beneficiarios;
    const {me} = getStore().login;

    const params = { page };
    params.ordering = "name";
    params.search = resource.search;
    // params.institucion = resource.institucion;
    params.type_beneficiary = resource.type_beneficiary;

    if (resource.desde != "") params.fecha_inicio = resource.desde;
    if (resource.hasta != "") params.fecha_fin = resource.hasta;
    if (resource.departamento != "-1")
        params.departament = resource.departamento;
    if (resource.municipio != "-1") params.municipality = resource.municipio;
    if (resource.comunidad != -1) params.community = resource.comunidad;
    if (me && me.profile && me.profile.role != 10) params.institucion = me.profile.institution;
    else params.institucion = resource.institucion;

    dispatch(setLoader(true));
    api.get("beneficiario/datos_carnets", params)
        .then((response) => {
            dispatch(setDataParaCarnets(response));
            dispatch(setOpenModal(true));

        })
        .catch((error) => {
            //console.log("Este es el error------", error);
            let msj =
                "Error al intentar obtener los datos para los carnets, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
       /*  .finally(() => {
            dispatch(setLoader(false));
        }); */
};
//------------------------------------ Procesos para registro de beenficiarios bia excel ---------

export const registerExcel = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post("beneficiario/registerExcel", data)
        .then((response) => {
            NotificationManager.success(
                "Empleado registrado correctamente",
                "Éxito",
                3000
            );
            dispatch(push("repetidos/beneficiario"));
        })
        .catch((error) => {
            let msj =
                "Error al intentar leer el archivo excel, verifique que llene todos los campos obligatorios.";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const listar_repetidos = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().beneficiarios;
    const params = { page };
    params.ordering = "name";
    params.search = resource.search;

    dispatch(setLoader(true));
    api.get("/beneficiarios_temporales/repetidos", params)
        .then((response) => {
            if (response) {
                dispatch(setDataRepetidos(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            let msj =
                "Error al intentar listar los beneficiarios, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const listar_repetidos_institucion = (page = 1) => (
    dispatch,
    getStore
) => {
    const resource = getStore().beneficiarios;
    const params = { page };
    params.ordering = "name";
    params.search = resource.search;

    dispatch(setLoader(true));
    api.get("/beneficiarios_temporales/institucionNueva", params)
        .then((response) => {
            if (response) {
                dispatch(setDataRepetidosInstitucion(response));
                dispatch(setPage(page));
            }
        })
        .catch((error) => {
            let msj =
                "Error al intentar listar los beneficiarios, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};
export const getBeneficiarioRepetido = (data) => (dispatch) => {
    api.post("beneficiarios_temporales/retrieve_beneficiario/", data)
        .then((response) => {
            response.institution = response.institution.name; //{value: response.institution.id, label:response.institution.name};
            //Se formatea la fecha a tipo fecha para los campos respectivos, tnato para los hijos de  un beneficiario
            //response.birth_date = new Date(response.birth_date);
            if (
                undefined !== response.child_beneficiary &&
                response.child_beneficiary.length
            ) {
                for (let i = 0; i < response.child_beneficiary.length; i++) {
                    response.child_beneficiary[i].birth_date = new Date(
                        response.child_beneficiary[i].birth_date
                    );
                }
            }
            dispatch(setItem(response));
        })
        .catch((error) => {
            let msj =
                "Error al intentar obtener el registro, vuelva a intentar";
            if (error) {
                if (error.detail) {
                    msj = error.detail;
                }
            }
            NotificationManager.error(msj, "ERROR", 5000);
        });
};
export const confirmar_repetidos = (data) => (dispatch) => {
    let msj = "";
    switch (data.type_action) {
        case 10:
            msj = "¿Está seguro de generar un nuevo beneficiario?";
            break;
        case 20:
            msj = "¿Seguro que desea sobrescribir el beneficiario?";
            break;
        case 30:
            msj = "¿Seguro que desea crear una institución nueva?";
            break;
        case 40:
            msj = "¿Esta seguro de eliminar todos los registros temporales?";
            break;
        default:
            msj = "Lo lamentamos, por el momento no disponemos de esta opción";
    }
    Swal.fire({
        title: msj,
        text: "Si realiza esta acción no se podra revertir!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008FF1",
        cancelButtonColor: "#FF3100",
        confirmButtonText: "Si!",
    }).then((result) => {
        if (result.value) {
            //Swal.fire('Exito!', 'El registro ha sido borrado.', 'success');
            dispatch(setLoader(true));
            api.post("beneficiarios_temporales/confirmar_beneficiarios", data)
                .then((response) => {
                    dispatch(setOpenModal(false));
                    dispatch(listar_repetidos());
                    dispatch(listar_repetidos_institucion());
                    //dispatch(push("repetidos/beneficiario"));
                    NotificationManager.success(
                        "Operación exitosa",
                        "Éxito",
                        3000
                    );
                })
                .catch((error) => {
                    //console.log("Error:", error);
                    NotificationManager.error(
                        "Ocurrió un error al, vuelva a intentar",
                        "ERROR",
                        0
                    );
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    });
};
export const descarga_excel = () => (dispatch, getStore) => {
    // priemtro sacamos los paramtros para filtrar
    const resource = getStore().beneficiarios;
    const params = new Object();
    params.institution = resource.institucion;
    params.tipos = resource.type_beneficiary;
    if (resource.desde != "") params.fecha_inicio = resource.desde;
    if (resource.hasta != "") params.fecha_fin = resource.hasta;
    if (resource.departamento != "-1")
        params.departament = resource.departamento;
    if (resource.municipio != "-1") params.municipality = resource.municipio;
    if (resource.comunidad != -1) params.community = resource.comunidad;

    api.get("beneficiario/download_excel", params)
        .then((response) => {
            if (response && response.id_descarga) {
                dispatch(verifyDownload(response.id_descarga));
            }
            //dispatch(push("/beneficiario"));
        })
        .catch((error) => {
            NotificationManager.error(
                error.detail ||
                    "Ha ocurrido un error, por favor vuelva a intentar.",
                "ERROR",
                0
            );
        });
};
const verifyDownload = (id_descarga) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("beneficiario/verify_download", { id_descarga })
        .then((response) => {
            const status = response.staus_download;
            if (status === "waiting") {
                setTimeout(() => {
                    dispatch(verifyDownload(id_descarga));
                }, 1000);
            } else if (status === "finished") {
                window.location.replace(response.file_excel.file_excel);
                dispatch(setLoader(false));
                //despues se elimina el archivo
                dispatch(deleteFileExcel(id_descarga));
            }
            //dispatch(push("/beneficiario"));
        })
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió al intentar descargar el archivo",
                "ERROR",
                0
            );
            dispatch(setLoader(false));
        });
};
///${id}
const deleteFileExcel = (id) => (dispatch) => {
    api.eliminar(`beneficiario/${id}/delete_file_excel`)
        .then((response) => {})
        .catch((error) => {
            NotificationManager.error(
                "Ocurrió un error eliminar el archivo de excel",
                "ERROR",
                0
            );
        });
};
//------------------------------------ Filtros ---------------------------------
const searchChange = (search) => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = (ordering) => (dispatch, getStore) => {
    const sort = getStore().beneficiario.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const filtro_institucion = (institucion) => (dispatch) => {
    dispatch(setFilteredInstitutions(institucion));
    dispatch(listar());
};
export const filtro_tipo_benefi = (type) => (dispatch) => {
    dispatch(setFilteredTypeBeneficiary(type));
    dispatch(listar());
    //dispatch(setFilteredTypeBeneficiary(""));
};
export const filtro_desde = (from) => (dispatch) => {
    if (from) dispatch(setFrom(moment(from).format("YYYY-MM-DD")));
    else dispatch(setFrom(""));
    dispatch(listar());
};
export const filtro_hasta = (to) => (dispatch) => {
    if (to) dispatch(setTo(moment(to).format("YYYY-MM-DD")));
    else dispatch(setTo(""));
    dispatch(listar());
};
export const filtro_departamento = (departamento) => (dispatch) => {
    if (departamento == "00") {
        dispatch(setFilteredMunicipality("-1"));
        dispatch(setFilteredCommunity("-1"));
    }
    dispatch(setFilteredDepartament(departamento));
    dispatch(listar());
    //dispatch(setFilteredTypeBeneficiary(""));
};
export const filtro_municipio = (municipio) => (dispatch) => {
    if (municipio == "00") {
        dispatch(setFilteredCommunity(-1));
    }
    dispatch(setFilteredMunicipality(municipio));
    dispatch(listar());
    //dispatch(setFilteredTypeBeneficiary(""));
};
export const filtro_comunidad = (comunidad) => (dispatch) => {
    dispatch(setFilteredCommunity(comunidad == "00" ? -1 : comunidad));
    dispatch(listar());
    //dispatch(setFilteredTypeBeneficiary(""));
};
export const vaciar_data_carnets = () => (dispatch) => {
    dispatch(setDataParaCarnets([]));
    dispatch(setOpenModal(false));
};
export const finalizar_loader = () => (dispatch) => {
    dispatch(setLoader(false));
};

const limpiar_filtros = () => (dispatch, getStore) => {

    dispatch(setFilteredDepartament("-1"));
    dispatch(setFilteredMunicipality("-1"));
    dispatch(setFilteredCommunity("-1"));

    dispatch(setFilteredInstitutions("-1"));
    dispatch(setFilteredTypeBeneficiary("-1"));


    dispatch(setFrom(""));
    dispatch(setTo(""));
  };

export const actions = {
    listar,
    remove,
    register,
    update,
    getBeneficiario,
    searchChange,
    onSortChange,
    cleanState,
    filtrarMunicipio,
    registerExcel,
    listar_repetidos_institucion,
    listar_repetidos,
    getBeneficiarioRepetido,
    confirmar_repetidos,
    filtrarComunidades,
    getCommunities,
    filtro_institucion,
    filtro_tipo_benefi,
    filtro_desde,
    filtro_hasta,
    descarga_excel,
    open_modal,
    activate_loader,
    get_beneficiarios_carnets,
    filtro_departamento,
    filtro_municipio,
    filtro_comunidad,
    seleccionarGenero,
    vaciar_data_carnets,
    finalizar_loader,
    limpiar_filtros
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_REPETIDOS]: (state, { data_repetidos }) => {
        return {
            ...state,
            data_repetidos,
        };
    },
    [DATA_REPETIDOS_INSTITUCION]: (state, { data_repetidos_institucion }) => {
        return {
            ...state,
            data_repetidos_institucion,
        };
    },
    [DATA_PARA_CARNETS]: (state, { data_para_carnets }) => {
        return {
            ...state,
            data_para_carnets,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SET_TYPE_BENEFICIARY]: (state, { tipo_beneficiario }) => {
        return {
            ...state,
            tipo_beneficiario,
        };
    },
    [PHOTO]: (state, { photo }) => {
        return {
            ...state,
            photo,
        };
    },
    [FILTER_MUNICIPALITY]: (state, { municipios }) => {
        return {
            ...state,
            municipios,
        };
    },
    [SET_COMMUNITIES]: (state, { comunidades }) => {
        return {
            ...state,
            comunidades,
        };
    },
    [SET_FILTERED_COMMUNITIES]: (state, { comunidades_filtradas }) => {
        return {
            ...state,
            comunidades_filtradas,
        };
    },
    [SET_FILTERED_INSTITUTIONS]: (state, { institucion }) => {
        return {
            ...state,
            institucion,
        };
    },
    [SET_FILTERED_TYPE_BENEFICIARY]: (state, { type_beneficiary }) => {
        return {
            ...state,
            type_beneficiary,
        };
    },
    [DESDE]: (state, { desde }) => {
        return {
            ...state,
            desde,
        };
    },
    [HASTA]: (state, { hasta }) => {
        return {
            ...state,
            hasta,
        };
    },
    [IS_OPEN_MODAL]: (state, { is_open }) => {
        return {
            ...state,
            is_open,
        };
    },
    [DEPARTAMENT]: (state, { departamento }) => {
        return {
            ...state,
            departamento,
        };
    },
    [MUNICIPALITY]: (state, { municipio }) => {
        return {
            ...state,
            municipio,
        };
    },
    [COMMUNITY]: (state, { comunidad }) => {
        return {
            ...state,
            comunidad,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_repetidos: {
        results: [],
        count: 0,
    },
    data_repetidos_institucion: {
        results: [],
        count: 0,
    },
    data_para_carnets: [],
    item: {},
    page: 1,
    ordering: "",
    search: "",
    tipo_beneficiario: "",
    photo: "",
    municipios: [],
    comunidades_filtradas: [],
    comunidades: [],
    institucion: -1,
    type_beneficiary: -1,
    desde: "",
    hasta: "",
    is_open: false,
    departamento: "-1",
    municipio: "-1",
    comunidad: -1,
};

export default handleActions(reducers, initialState);
