import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
    renderField,
    SelectField,
    MultiSelectField,
    renderTextArea,
    renderDatePicker,
} from "Utils/renderField/renderField";
import { DEPARTAMENTOS_SELECT } from "../../../Utils/constant";

import "./form.css";

const renderInsumos = ({ fields, eliminarInsumo, disabled }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="table-responsive">
            <table className="table insumos-table">
                <thead className="table-dark">
                    <tr>
                        <th className="center" colSpan="2">
                            No
                        </th>
                        <th>Insumo</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((insumo, index) => {
                        const insumoActual = fields.get(index);
                        return (
                            <tr key={index}>
                                <td className="center">
                                    {!disabled && (
                                        <button
                                            type="button"
                                            className="btn p-0 my-2"
                                            onClick={() => {
                                                fields.remove(index);
                                                eliminarInsumo(insumoActual.id);
                                            }}
                                        >
                                            <i className="material-icons">
                                                close
                                            </i>
                                        </button>
                                    )}
                                </td>
                                <td>No. {index+1}</td>
                                <td>
                                    <Field
                                        disabled={disabled}
                                        valueKey="id"
                                        name={`${insumo}.name`}
                                        labelKey="nombre"
                                        label="nombre"
                                        placeholder="nombre del insumo"
                                        component={renderField}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!disabled && (
                <div className="d-flex justify-content-center">
                    <button
                        type="button"
                        className="btn btn-outline-info font-weight-bolder btn-block col-12 col-md-6"
                        onClick={() => {
                            fields.push({});
                        }}
                    >
                        Agregar Insumo
                    </button>
                </div>
            )}
        </div>
    );
};

const Form = (props) => {
    const {
        handleSubmit,
        institutions,
        eliminarInsumo,
        editar,
        municipios,
        projects,
        me,
        comunidades
    } = props;

    let disabled = true;
    const url = window.location.href;
    if (url.includes("editar") || url.includes("nuevo")) disabled = false;

    const change = (value, label) => {
        props.filtrarMunicipio(label);
    };

    return (
        <Fragment>
            <div className="row justify-content-end mb-1">
                <p className="col-12 text-primary font-weight-bold">
                    Todos los campos que lleven (*) Son requeridos.
                </p>
                <p className="col-12 text-primary font-weight-bold">
                    La institución es obligatoria solo si no hay un proyecto
                    asignado
                </p>
            </div>

            <form
                name="form_new_acivity"
                onSubmit={handleSubmit}
                className="form-validate mb-lg padding"
            >
                <div className="mb-3 col-12">
                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Actividad:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="name"
                                placeholder="Nombre de la actividad"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                Proyecto:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            {projects && (
                                <Field
                                    disabled={
                                        disabled || me.profile.role !== 10
                                            ? true
                                            : false
                                    }
                                    name="project"
                                    placeholder="Proyecto"
                                    component={SelectField}
                                    options={projects}
                                    className="form-control"
                                />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label htmlFor="name" className="font-weight-bold">
                                institución:
                            </label>
                            {institutions && (
                                <Field
                                    disabled={disabled}
                                    name="institution"
                                    placeholder="Institución"
                                    component={SelectField}
                                    options={institutions}
                                    className="form-control"
                                />
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.departament"
                            >
                                Departamento:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="departament"
                                placeholder="Departamento"
                                options={DEPARTAMENTOS_SELECT}
                                component={SelectField}
                                className="form-control"
                                onChange={change}
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.municipaplity"
                            >
                                Municipio:{" "}
                                <span className="text-primary  font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="municipality"
                                placeholder="Municipio"
                                options={municipios}
                                component={SelectField}
                                className="form-control"
                                onChange={(e, val) =>
                                    props.filtrarComunidad(val)
                                }
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-4 form-group has-feedback">
                            <label
                                className="font-weight-bold"
                                htmlFor="address.comunity"
                            >
                                Comunidad o aldea:{" "}
                                <span className="text-primary font-weight-bold">
                                    *
                                </span>
                            </label>
                            <Field
                                disabled={disabled}
                                name="community"
                                placeholder="Comunidad"
                                options={comunidades}
                                component={MultiSelectField}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-4 form-group has-feedback">
                            <label
                                htmlFor="description"
                                className="font-weight-bold"
                            >
                                Observaciones:
                            </label>
                            <Field
                                disabled={disabled}
                                name="description"
                                placeholder="Escribe aquí las observaciones de la actividad"
                                component={renderTextArea}
                                type="email"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-4 form-group has-feedback">
                            <FieldArray
                                disabled={disabled}
                                name="insumos"
                                component={renderInsumos}
                                eliminarInsumo={eliminarInsumo}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center flex-column flex-md-row">
                    <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                        <Link
                            className="btn btn-light w-100 font-weight-bold mb-4 mb-md-2"
                            to="/actividades"
                            onClick={() => {
                                props.filtrarMunicipio('00')
                                props.filtrarComunidad('00')
                            }}
                        >
                            {disabled ? "Regresar" : "Cancelar"}
                        </Link>
                    </div>
                    {!disabled && (
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 font-weight-bold mb-4 mb-md-2"
                            >
                                {editar ? "Actualizar" : "Guardar"}
                            </button>
                        </div>
                    )}
                </div>
            </form>
        </Fragment>
    );
};

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = "Campo requerido";
    }
    if (!values.date_start) {
        errors.date_start = "Campo requerido";
    }
    if (!values.date_end) {
        errors.date_end = "Campo requerido";
    }
    if (!values.departament || values.departament == "00") {
        errors.departament = "campo requerido";
    }
    if (!values.municipality || values.municipality == "00") {
        errors.municipality = "campo requerido";
    }
    if (!values.community || values.community.length == 0 || values.community == "00") {
        errors.community = "campo requerido";
    }

    if (
        (!values.institution && !values.project) ||
        (values.institution < 1 && !values.project)
    ) {
        errors.project = "Campo requerido";
    }

    if (values.project && values.institution) {
        errors.project =
            "No puedes seleccionar una institución y un proyecto a la vez";
        errors.institution =
            "No puedes seleccionar una institución y un proyecto a la vez";
    }

    if (
        (!values.project && !values.institution) ||
        (values.project < 1 && values.institution < 1)
    ) {
        errors.institution = "Campo requerido";
    }
    if (
        (!values.project && values.institution < 1) ||
        (values.project < 1 && !values.institution)
    ) {
        errors.institution = "Campo requerido";
    }

    return errors;
};

export default reduxForm({
    form: "form_new_acivity", // a unique identifier for this form
    validate: validate,
})(Form);
